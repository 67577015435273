import styled from '@emotion/styled';
import { Breadcrumbs as MuiBreadCrumbs, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Link as RedwoodLink, routes, useLocation } from '@redwoodjs/router';

import { useOrgLocalization } from 'src/providers/OrgLocalizationProvider';

const Link = styled(RedwoodLink)({
  color: 'inherit',
  textDecoration: 'none',
});

type PageDef = {
  /**
   * Title of path that will be displayed in the breadcrumb.
   */
  title?: string;
  /**
   * Children of route, for nested breadcrumbs.
   * The key of the object is the url path.
   */
  children?: PageDefs;
  /**
   * For parameterized routes:
   * - PageDef: nested page def with {param} placeholder
   * - string: title with {param} placeholder
   * - string[]: array of titles with {param} placeholder, for sequential parameterized routes
   */
  param?: PageDef | string | string[];
};
type PageDefs = Record<string, string | PageDef>;

const pageDefs = ({ salesOrderDocumentPrefix }): PageDefs => ({
  landing: 'Home',
  'organization-settings': 'Organization Settings',
  'user-profile': 'User Profile',
  inventory: {
    title: 'Inventory',
    children: {
      'pick-tickets': {
        title: 'Pick Tickets',
        param: 'PT #{param}',
      },
      'receiving-tickets': {
        title: 'Receiving Tickets',
        param: 'RT #{param}',
      },
      'lot-tracking': {
        param: [null, 'Lot #{param}'],
      },
      repack: {
        title: 'Repack',
      },
      'production-runs': {
        title: 'Production Runs',
        param: 'PR #{param}',
      },
      loads: {
        title: 'Freight Tracking',
        param: 'Load #{param}',
      },
      transfers: { title: 'Inventory Transfers', param: 'IT #{param}' },
    },
  },
  'sales-orders': {
    title: 'Sales Orders',
    param: `${salesOrderDocumentPrefix} #{param}`,
    children: {
      pdf: 'PDF',
    },
  },
  'purchase-orders': {
    title: 'Purchase Orders',
    param: 'PO #{param}',
    children: {
      pdf: 'PDF',
    },
  },
  'grower-products': {
    title: 'Grower Products',
    param: 'GP #{param}',
  },
  'buy-sell-orders': {
    param: 'BSO #{param}',
  },
  contacts: {
    title: 'Contacts',
    param: {
      title: 'Contact #{param}',
      children: {
        edit: 'Edit Contact',
      },
    },
    children: {
      new: 'New Contact',
      view: 'View Contacts',
    },
  },
  'system-management': {
    title: 'System Management',
    children: {
      products: {
        param: {
          title: '#{param}',
        },
      },
      'business-entities': {
        param: {
          title: 'Business Entity #{param}',
        },
      },
      'pricing-sheet': {
        title: 'Pricing Sheet',
        param: {},
      },
    },
  },
  accounting: {
    children: {
      reports: 'Accounting Reports',
      invoices: {
        title: 'Invoices',
        param: '#{param}',
      },
      payables: {
        title: 'Payables',
        param: '#{param}',
      },
      payments: {
        title: 'Payments',
        param: '#{param}',
        children: {
          'print-checks': 'Print Checks',
          deposits: 'Deposits',
        },
      },
      accounts: {
        title: 'Accounts',
        children: {
          register: {
            title: 'Register',
          },
        },
      },
      reconciliations: {
        title: 'Account Reconciliations',
        param: '#{param}',
      },
    },
  },
  reports: 'Reports',
  'business-intelligence': {
    title: 'Business Intelligence',
    children: {
      'aging-reports': 'Aging Reports',
    },
  },
  'search-results': 'Search Results',
  'order-board': 'Order Board',
  invoices: {
    title: 'Invoices',
    param: '#{param}',
  },
  products: 'Products',
});

type BreadcrumbType = { title: string; path: string };

/**
 * Handles nested breadcrumbs, parameterized routes, and sequential parameterized routes.
 * - Nested breadcrumbs: `children` property
 * - Parameterized routes: `param` property
 * - Sequential parameterized routes: `param` property as an array.
 *   - These are a special case to handle parameters that are children of one another, but may not have their own page def.
 */
function getBreadcrumbs(paths: string[], pageDefProps) {
  const breadcrumbs: BreadcrumbType[] = [];
  const atPath = [''];
  let lastPageDef: PageDef;
  let currentPageDef = pageDefs(pageDefProps);
  let paramSeqIndex = 0;

  paths.forEach((path) => {
    atPath.push(path);

    const pageDef = (currentPageDef && currentPageDef[path]) ?? lastPageDef;
    const paramDef = lastPageDef?.param;

    // When going to the home screen, there is no pageDef, but the breadcrumb still renders somehow.
    // This prevents it from crashing when that happens.
    if (!pageDef) return;

    // Some page defs don't have children, so just use the title
    if (typeof pageDef === 'string') {
      breadcrumbs.push({ title: pageDef, path: atPath.join('/') });

      return;
    }

    // Use parameterized route, if available
    if (paramDef) {
      const param = Array.isArray(paramDef) ? paramDef[paramSeqIndex] : paramDef;

      paramSeqIndex++;

      if (!param) {
        return;
      }

      const header = typeof param === 'string' ? param : param.title;

      if (header) {
        breadcrumbs.push({
          title: header.replace('{param}', path),
          path: atPath.join('/'),
        });
      }

      if (typeof paramDef !== 'string') {
        const nestedPageDef = paramDef as PageDef;

        if (!nestedPageDef.children) {
          return;
        }

        lastPageDef = nestedPageDef;
        currentPageDef = nestedPageDef?.children;

        return;
      }
    } else if (pageDef.title) {
      breadcrumbs.push({ title: pageDef.title, path: atPath.join('/') });
      paramSeqIndex = 0;
    }

    lastPageDef = pageDef;
    currentPageDef = pageDef?.children;
  });

  return breadcrumbs;
}

export default function Breadcrumbs() {
  const { pathname } = useLocation();
  const orgLocale = useOrgLocalization();
  const [_, ...paths] = pathname.split('/'); // removes leading slash
  const breadcrumbs = getBreadcrumbs(paths, orgLocale);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <MuiBreadCrumbs maxItems={smallScreen ? 2 : 4}>
        <Link to={routes.landing()}>Home</Link>
        {breadcrumbs.map(({ title, path }, i) =>
          i === breadcrumbs.length - 1 ? (
            <Typography key={path} color="text.primary">
              {title}
            </Typography>
          ) : (
            <Link key={path} to={path}>
              {title}
            </Link>
          )
        )}
      </MuiBreadCrumbs>
    </>
  );
}
