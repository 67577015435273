import { useMemo } from 'react';

import { Grid2, Paper, Stack, Typography } from '@mui/material';
import { SalesTermsMapper } from 'shared/mappers';

import Section from 'src/components/atoms/Section';
import SkeletonEntryForm from 'src/components/containers/skeleton/SkeletonEntryForm';
import { ProductsGridSkeleton } from 'src/modules/grids/productLineItemsGrid/components/ProductsGridSkeleton';

import { CustomerPortalPurchaseOrderData } from '../purchase-order/useCustomerPortalPurchaseOrder';
import useCustomerPortalLocations from '../useCustomerPortalLocations';

type Props = {
  purchaseOrder: CustomerPortalPurchaseOrderData;
};

export default function OrderDetails({ purchaseOrder }: Props) {
  const locations = useCustomerPortalLocations();

  const shipTo = useMemo(
    () => locations?.shipping?.find((l) => l.id === purchaseOrder?.shipment?.destinationId)?.label ?? '--',
    [locations, purchaseOrder]
  );

  const billTo = useMemo(
    () => locations?.billing?.find((l) => l.id === purchaseOrder?.billToId)?.label ?? '--',
    [locations, purchaseOrder]
  );

  if (!purchaseOrder) {
    return (
      <Paper>
        <SkeletonEntryForm fields={5} />
        <ProductsGridSkeleton />
      </Paper>
    );
  }

  return (
    <Section title="Order Details">
      <Grid2 container spacing={2}>
        <Field label="Customer" displayValue={purchaseOrder.businessEntity?.name} />
        <Field label="Order date" displayValue={purchaseOrder.orderDate} />
        <Field label="Ship to" displayValue={shipTo} />
        <Field label="Bill to" displayValue={billTo} />
        <Field label="Sales terms" displayValue={SalesTermsMapper.getLabel(purchaseOrder.salesTerms)} />
      </Grid2>
    </Section>
  );
}

const Field = ({ label, displayValue }) => (
  <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
    <Stack>
      <Typography variant="overline" sx={{ color: 'grey.600' }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: '17px' }}>{displayValue}</Typography>
    </Stack>
  </Grid2>
);
