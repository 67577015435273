// Clerk roles are defined in Clerk's dashboard.
// Pulled into currentUser in api/src/lib/auth.ts
// Accessed in the API via context.currentUser
// Accessed in the web via useAuth().currentUser

export enum ClerkRole {
  Admin = 'org:admin',
  Member = 'org:member',
  Customer = 'org:customer',
  Grower = 'org:grower',
}
