import { useQuery } from '@redwoodjs/web';

export const GET_CUSTOMER_PORTAL_PRODUCTS = gql`
  query GetCustomerPortalPurchaseOrderProducts($orderId: Int!) {
    customerPortalPurchaseOrderProducts(orderId: $orderId) {
      id
      code
      label
      price
    }
  }
`;

export type CustomerPortalProduct = {
  id: number;
  code?: string;
  label: string;
  price: number;
};

const empty = [];

export default function useCustomerPortalProducts(orderId?: number): CustomerPortalProduct[] {
  const { data } = useQuery(GET_CUSTOMER_PORTAL_PRODUCTS, { variables: { orderId }, skip: !orderId });

  return data?.customerPortalPurchaseOrderProducts ?? empty;
}
