import { DateTime } from 'luxon';

import { ListTemplate, Payment } from '../../types';
import { Payment as PaymentUtils } from '../../utils';
import { parseDate } from '../../utils/DateTime';
import Money from '../../utils/Money';
import { AddressComponent } from '../components/address.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import { applyListMeta, formatCurrency, formatOrganizationName, getPrimaryBusiness } from './formatters';

export type CustomerStatementInputType = {
  organization: {
    label: string;
    logoUrl: string;
    primaryLocation: {
      streetAddress1?: string;
      streetAddress2?: string;
      city?: string;
      state?: string;
      postalCode?: string;
      country?: string;
      phone?: string;
    };
  };
  invoices: Array<{
    slug: string;
    sentAt: string;
    payments: Array<{
      paymentAmount: number;
    }>;
    totalAmountDue: number;
    referenceNumber?: string;
    salesOrder?: {
      salesTerms: string;
    };
  }>;
  standardInvoices: Array<{
    slug: string;
    invoiceDate: string;
    payments: Array<{
      paymentAmount: number;
    }>;
    totalAmountDue: number;
    referenceNumber?: string;
    paymentTerms: string;
  }>;
  buySellOrderInvoices: Array<{
    slug: string;
    sentAt: string;
    payments: Array<{
      paymentAmount: number;
    }>;
    totalAmountDue: number;
    referencePayableNumber?: string;
    buySellOrder: {
      customerSalesTerms: string;
    };
  }>;
};

type ReceiptAdjustment = {
  type: 'CREDIT' | 'DEBIT' | 'ADJUSTMENT';
  amount: number;
};

export const CustomerStatementTemplate: DocumentTypePopulator<
  CustomerStatementInputType,
  void,
  Partial<{
    invoices: ListTemplate<{
      invoice: string;
      date: string;
      referenceNumber?: string;
      dueDate: string;
      currentDays: number;
      overdueDays: number;
      amount: string;
      payments: string;
      openBalance: string;
    }>;
    phoneNumber: string;
    organization: string;
    businessAddress: Record<string, string | undefined | boolean>;
    totalDue: string;
    totalPaid: string;
  }>
> = {
  populate: (data, _, globalOptions) => {
    if (!data) return {};

    const { organization, invoices: salesOrderInvoices, standardInvoices, buySellOrderInvoices } = data;

    const calculateDays = (startDate?: string | Date | null, endDate?: string | null): number => {
      const start = parseDate(startDate);
      const end = endDate ? parseDate(endDate) : DateTime.local();

      if (!start || !end) return 0;

      return Math.floor(end.diff(start, 'days').days);
    };

    const mapInvoices = (invoices: any, invoiceType: 'salesOrder' | 'standardInvoice' | 'buySellOrderInvoice') =>
      invoices.map((invoice: any) => {
        const {
          slug,
          sentAt,
          payments,
          totalAmountDue,
          referenceNumber,
          receiptAdjustments,
          salesOrder,
          buySellOrder,
          paymentTerms,
          invoiceDate,
        } = invoice;

        let genericReferenceNumber;
        let genericInvoiceDate = sentAt;
        let salesTerms;

        if (invoiceType === 'salesOrder') {
          salesTerms = salesOrder?.salesTerms;
          genericReferenceNumber = referenceNumber;
        } else if (invoiceType === 'standardInvoice') {
          genericInvoiceDate = invoiceDate;
          salesTerms = paymentTerms;
          genericReferenceNumber = invoice.referencePayableNum;
        } else if (invoiceType === 'buySellOrderInvoice') {
          salesTerms = buySellOrder?.customerSalesTerms;
          genericReferenceNumber = referenceNumber;
        }

        const paymentDueDate = PaymentUtils.computePaymentDueDate(genericInvoiceDate, salesTerms);

        const totalPaid = payments.reduce((acc: number, payment: Payment) => acc + payment.paymentAmount || 0, 0);
        const totalAdjustments = receiptAdjustments.reduce(
          (acc: number, adjustment: ReceiptAdjustment) => acc + adjustment.amount,
          0
        );
        const totalPaidWithAdjustments = totalPaid + totalAdjustments;
        const currentDays = calculateDays(genericInvoiceDate);
        const overdueDays = calculateDays(paymentDueDate);

        return {
          invoice: slug,
          date: DateTime.fromISO(genericInvoiceDate).toFormat('MM/dd/yyyy'),
          referenceNumber: genericReferenceNumber,
          dueDate: parseDate(paymentDueDate)?.toFormat('MM/dd/yyyy') ?? '--',
          currentDays,
          overdueDays: overdueDays > 0 ? overdueDays : '--',
          amount: formatCurrency(Money.toDollars(totalAmountDue), 'USD'),
          payments: formatCurrency(Money.toDollars(totalPaidWithAdjustments), 'USD'),
          openBalance: formatCurrency(Money.toDollars(totalAmountDue - totalPaidWithAdjustments), 'USD'),
          openBalanceDollars: Money.toDollars(totalAmountDue - totalPaidWithAdjustments),
          totalPaymentsDollars: Money.toDollars(totalPaidWithAdjustments),
        };
      });

    const allInvoices = [
      ...mapInvoices(salesOrderInvoices, 'salesOrder'),
      ...mapInvoices(standardInvoices, 'standardInvoice'),
      ...mapInvoices(buySellOrderInvoices, 'buySellOrderInvoice'),
    ];

    const totalPaid = allInvoices.reduce((acc, invoice) => acc + invoice.totalPaymentsDollars, 0);
    const totalDue = allInvoices.reduce((acc, invoice) => acc + invoice.openBalanceDollars, 0);

    const primaryBusiness = getPrimaryBusiness(organization);

    return {
      orgLogo: organization?.logoUrl,
      organization: formatOrganizationName({
        organization,
        customOrgName: globalOptions.customOrgName,
      }),
      phoneNumber: primaryBusiness?.phone,
      businessAddress: AddressComponent.templatize(primaryBusiness),
      invoices: applyListMeta(allInvoices),
      totalPaid: formatCurrency(totalPaid, 'USD'),
      totalDue: formatCurrency(totalDue, 'USD'),
    };
  },
  definitionMap: {
    organization: { label: 'Organization', type: 'string' },
    phoneNumber: { label: 'Phone Number', type: 'string' },
    totalDue: { label: 'Total Due', type: 'string' },
    totalPaid: { label: 'Total Paid', type: 'string' },
    businessAddress: {
      label: 'Business Address',
      type: 'address',
      children: AddressComponent.definitionMap,
    },
    invoices: {
      label: 'Invoices',
      type: 'list',
      children: {
        invoice: { label: 'Invoice', type: 'string' },
        date: { label: 'Date', type: 'string' },
        referenceNumber: { label: 'Reference Number', type: 'string' },
        dueDate: { label: 'Due Date', type: 'string' },
        currentDays: { label: 'Current Days', type: 'number' },
        overdueDays: { label: 'Overdue Days', type: 'number' },
        amount: { label: 'Amount', type: 'string' },
        payments: { label: 'Payments', type: 'string' },
        openBalance: { label: 'Open Balance', type: 'string' },
      },
    },
  },
};
