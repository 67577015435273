import { ReactNode, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Box, Collapse, SxProps } from '@mui/material';

type Props = {
  title: string;
  titleContent?: ReactNode;
  children?: ReactNode;
  noPadding?: boolean;
  style?: React.CSSProperties;
  sx?: SxProps;
};

export default function Section({ title, children, titleContent, noPadding, sx, style }: Props) {
  const [expanded, setExpanded] = useState(true);
  const toggleAccordion = () => setExpanded((prev) => !prev);

  return (
    <Box className={noPadding ? '' : 'px-6'} sx={sx} style={style}>
      <Box className="pointer flex items-center gap-2 mb-4 select-none" onClick={toggleAccordion}>
        <ExpandMore sx={{ color: 'grey.600', transform: expanded ? 'rotateX(180deg)' : 'rotateX(0)' }} />
        <p className="text-[17px] font-semibold">{title}</p>
        {titleContent}
      </Box>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
}
