import { useCallback, useEffect, useRef, useState } from 'react';

import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { PageInfo } from 'shared/types';

import { useGridExportState } from 'src/hooks/useGridExport';

import DataGrid, { DataGridProps } from '../../../components/containers/grids/DataGrid';

import NoRowsOverlay from './NoRowsOverlay';
import { ServerEnhancedGridStateWithHelpers } from './useServerEnhancedGridState';

type ServerEnhancedDataGridProps = {
  /**
   * @required The pageInfo object returned from the server, optional for while still loading
   */
  pageInfo?: PageInfo;
  totalCount?: number | null;
  loading: boolean;
  enhancedGridState: ServerEnhancedGridStateWithHelpers;
  enableSelection?: boolean;
} & DataGridProps;

export default function ServerEnhancedDataGrid({
  pageInfo,
  totalCount,
  loading,
  enhancedGridState,
  enableSelection = false,
  autoHeight,
  initialState,
  ...dataGridProps
}: ServerEnhancedDataGridProps) {
  const init = useRef(false);
  const pageEndCursors = useRef<{ [page: number]: number }>({});
  const isExporting = useGridExportState((s) => s.isExporting);
  const { columns, onPaginationModelChange } = dataGridProps;

  // Row count is only fetched on the first page for performance reasons so we store it here
  const [rowCount, setRowCount] = useState<number>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: enhancedGridState.first,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>(initialState?.sorting?.sortModel);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handlePaginationModelChange = useCallback(
    (model: GridPaginationModel, reason) => {
      if (model.page === 0 || pageEndCursors.current[model.page - 1]) {
        if (model.pageSize !== paginationModel.pageSize) {
          model.page = 0;
          pageEndCursors.current = {};
        }
        onPaginationModelChange?.(model, reason);
        setPaginationModel(model);
      }
    },
    [onPaginationModelChange, paginationModel.pageSize]
  );

  // Callback to fetch more data after pagination change
  useEffect(() => {
    const { pageSize, page } = paginationModel;
    const serverSortModel = sortModel;

    enhancedGridState.gridUpdate(serverSortModel, pageSize, page > 0 ? pageEndCursors.current[page - 1] : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, sortModel, columns]);

  // When filters or sorting change reset to first page
  useEffect(() => {
    if (paginationModel.page !== 0) {
      setPaginationModel({ page: 0, pageSize: enhancedGridState.first });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhancedGridState.gridFilters, sortModel, enhancedGridState.searchText, enhancedGridState.quickFilterOption]);

  // Update cursor mapping whenever we get new data
  useEffect(() => {
    if (loading) {
      return;
    }
    pageEndCursors.current[paginationModel.page] = pageInfo?.endCursor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, pageInfo]);

  useEffect(() => {
    init.current = true;
  }, []);

  useEffect(() => {
    if (init.current && !isExporting) {
      enhancedGridState.forceRefetch();
    }
  }, [isExporting]);

  // Cache total count for when it's undefined on later pages
  useEffect(() => {
    if (typeof totalCount === 'number') {
      setRowCount(totalCount);
    }
  }, [totalCount]);

  const NoRows = useCallback(() => <NoRowsOverlay enhancedGridState={enhancedGridState} />, [enhancedGridState]);

  const expectedRowsInPage = !loading ? dataGridProps.rows?.length : enhancedGridState.first;
  const hasNoRows = !loading && !dataGridProps.rows?.length;

  const onSelectionModelChange = useCallback(
    (newRowIds) => {
      setSelectedRowIds(newRowIds);
      if (!enableSelection) return;

      const selectedRows = { ...enhancedGridState.selectedRows };

      // Delete unselected rows
      for (const rowId in selectedRows) {
        if (!newRowIds.includes(rowId)) {
          delete selectedRows[rowId];
        }
      }

      // Add newly selected rows
      newRowIds.forEach((id) => {
        if (!selectedRows[id]) {
          const row = dataGridProps.rows.find((row) => (dataGridProps.getRowId?.(row) ?? row.id) === id);

          selectedRows[id] = row;
        }
      });

      enhancedGridState.updateSelectedRows(selectedRows);
    },
    [dataGridProps, enableSelection, enhancedGridState]
  );

  return (
    <DataGrid
      loading={isExporting || loading}
      rowCount={typeof totalCount === 'number' ? totalCount : (rowCount ?? -1)}
      {...dataGridProps}
      autoHeight={autoHeight}
      sx={{
        ...dataGridProps.sx,
        minHeight: hasNoRows ? '50vh' : autoHeight ? `${expectedRowsInPage * 52}px` : dataGridProps.sx?.minHeight,
      }}
      slots={{
        ...dataGridProps.slots,
        noRowsOverlay: NoRows,
      }}
      slotProps={{
        ...dataGridProps.slotProps,
        loadingOverlay: {
          variant: 'skeleton',
        },
      }}
      initialState={initialState}
      onAfterFirstLoad={(_filterState, sortState) => {
        setSortModel(sortState);
      }}
      // Pagination
      pagination
      paginationMode={isExporting ? 'client' : 'server'}
      paginationMeta={{ hasNextPage: pageInfo?.hasNextPage }}
      paginationModel={isExporting ? { page: 0, pageSize: 5 } : paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      // Filtering
      filterMode="server"
      // Sorting
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      enhancedGridState={enhancedGridState}
      // Selection
      checkboxSelection={enableSelection}
      keepNonExistentRowsSelected
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={onSelectionModelChange}
    />
  );
}
