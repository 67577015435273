import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useDebounce as useDebouncedValue } from 'usehooks-ts';

import { useDebounce } from 'src/hooks/useDebounce';
import { useGridCore } from 'src/providers/GridCoreProvider';

export default function ExpandAllButton({ apiRef }: { apiRef: MutableRefObject<GridApiPremium> }) {
  const setExpandedGroups = useGridCore((store) => store.setExpandedGroups);
  const [allExpanded, setAllExpanded] = useState(false);

  const isAllGroupsExpanded = useCallback(() => {
    const values = Object.keys(apiRef.current.state.filter.filteredRowsLookup);

    if (!values?.length) {
      return false;
    }

    for (const id of Object.keys(apiRef.current.state.filter.filteredRowsLookup)) {
      const node = apiRef.current.getRowNode(id);

      if (node?.type !== 'group') {
        continue;
      }

      if (!node.childrenExpanded) {
        return false;
      }
    }

    return true;
  }, [apiRef]);

  const handleRowExpansionChange = useDebounce(() => {
    const allExpanded = isAllGroupsExpanded();
    setAllExpanded(allExpanded);
  }, 10);

  useEffect(() => {
    const unsubscribe = apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange);

    handleRowExpansionChange();

    return () => {
      unsubscribe();
    };
  }, [apiRef, isAllGroupsExpanded, handleRowExpansionChange]);

  const handleToggleAll = () => {
    const groupRowIds = Object.keys(apiRef.current.state.filter.filteredRowsLookup)
      .map((id) => {
        return apiRef.current.getRowNode(id);
      })
      .filter((node) => {
        return node?.type === 'group';
      });

    const newExpansionState = !allExpanded;

    const allExpandedGroups = groupRowIds.reduce((acc, { id, groupingKey }) => {
      if (typeof groupingKey === 'boolean') {
        console.warn('Grouping key is a boolean, cannot expand/collapse group');

        return acc;
      }

      apiRef.current.setRowChildrenExpansion(id, newExpansionState);

      acc[groupingKey] = newExpansionState;

      return acc;
    }, {});

    setExpandedGroups(allExpandedGroups);
  };

  // debounce the value a little so that if the state is changing a lot, the button doesn't freak out.
  const isAllExpanded = useDebouncedValue(allExpanded, 50);

  return (
    <Button
      startIcon={
        <GridExpandMoreIcon
          sx={{
            width: '18px',
            height: '18px',
            transform: isAllExpanded ? 'rotate(-90deg)' : '',
          }}
        />
      }
      onClick={handleToggleAll}
    >
      {isAllExpanded ? 'Collapse All' : 'Expand All'}
    </Button>
  );
}
