import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, debounce } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridEventListener,
  GridEvents,
  GridFilterModel,
  GridRowId,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Toolbar, { ToolbarProps } from 'src/components/containers/grids/Toolbar';
import { ServerEnhancedGridStateWithHelpers } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import GridCoreProvider, { useGridCore } from 'src/providers/GridCoreProvider';
import { useGridFocus } from 'src/providers/GridFocusProvider';
import mapObject from 'src/utils/assignObject';
import { baseGridStyles } from 'src/utils/grid/gridStyles';

export type ColumnKeyType<Model = unknown> = GridColDef<Model>['field'];
export type GridColValue<Model = unknown> = Omit<GridColDef<Model>, 'field'> & {
  serverFilterValueTransform?: { from: (value: unknown) => unknown; to: (value: unknown) => unknown };
  getFilterSelectOptions?: (client) => Promise<string[]>;
};
export type GridColObj<Model = Record<string, unknown>> = Record<ColumnKeyType<Model>, GridColValue<Model>>;

export type DisabledColumns = Record<string, boolean>;

/*
 * It is possible to make a change to a column definition, like what filter operations it supports, which is no longer compatible with state persisted in browser storage.
 * If this occurs, the page will blow up.  Therefore, do not load persisted state unless it has a matching key.  If you make such a change, just increment this value.
 * */
const DATAGRID_STATE_MIGRATION_KEY = 4;

export type SharedDataGridProps = {
  toolbar?: ToolbarProps;
  /**
   * For some reason, the column re-ordering is indexed from 1,
   * but for tree data, it's indexed from 2. This is a workaround
   */
  offsetIndex?: number;
  /**
   * Whether to add event listeners that track which row the user is hovering over
   *
   * ...overloading to determine which level to track the hover at.
   */
  trackHover?: boolean | 'cell';
  /**
   * Columns that are disabled from being shown
   */
  disabledColumns?: DisabledColumns;

  columnOrderToSync?: string[];

  variant?: 'list' | 'grid';

  height?: number | string;

  /**
   * Determine whether a row should refuse the processRowUpdate changes
   */
  shouldPreventRowProcess?: (
    rowId: GridRowId,
    oldRow: Record<string, unknown>,
    newRow: Record<string, unknown>
  ) => boolean | Promise<boolean>;
};

export type PersistentDataGridProps = DataGridPremiumProps & SharedDataGridProps;

export type DataGridProps = Omit<DataGridPremiumProps, 'columns'> &
  SharedDataGridProps & {
    columns: GridColObj;
    storageKey: string;
    // Add extra width to the grid to account for checkbox
    extraWidth?: number;
    appendToColumns?: Record<string, Partial<GridColValue>>;
    onAfterFirstLoad?: (filterModel: GridFilterModel, sortModel: GridSortModel) => void;
    enhancedGridState?: ServerEnhancedGridStateWithHelpers;
  };

export default function DataGrid(props: DataGridProps) {
  if (!props.apiRef) {
    return <DataGridRefWrapper {...props} />;
  }

  return <DataGridInner {...props} />;
}

function DataGridRefWrapper(props: DataGridProps) {
  const apiRef = useGridApiRef();
  return <DataGridInner {...props} apiRef={apiRef} />;
}

function DataGridInner({
  columns,
  rows,
  storageKey,
  apiRef,
  appendToColumns,
  initialState,
  checkboxSelection,
  rowSelectionModel,
  toolbar,
  pinnedRows,
  columnVisibilityModel,
  disabledColumns,
  columnOrderToSync,
  onPaginationModelChange,
  onSortModelChange,
  onFilterModelChange,
  onAfterFirstLoad,
  height,
  ...props
}: DataGridProps) {
  const [shouldSyncColumnOrder, setShouldSyncColumnOrder] = useState(false);

  /*
   * The only way to control DataGrids column order is by passing in
   * the ordered column array. Since we might want to update the columns,
   * we need to trigger computed columns only once with the new column order.
   * All call of computedColumns after that column has been synced should use
   * the current state of the ordered columns, as the user might change them.
   */
  useEffect(() => {
    if (columnOrderToSync) {
      setShouldSyncColumnOrder(true);
    }
  }, [columnOrderToSync]);

  const computedColumns = useMemo(() => {
    const columnState = apiRef.current?.state?.columns;

    let syncColumnOrder = shouldSyncColumnOrder ? columnOrderToSync : null;
    const defaultOrder = Object.keys(columns);

    if (shouldSyncColumnOrder) {
      // synchronize new columns into the synced column order
      const newColumns = defaultOrder.filter((column) => !syncColumnOrder.includes(column));

      if (newColumns.length) {
        syncColumnOrder = [...syncColumnOrder, ...newColumns];
      }

      /* when setting the column order, we need to wait for the next render
       * to ensure that the columns have been updated
       * otherwise, the column order will be set to the column order in the state
       */
      setTimeout(() => {
        setShouldSyncColumnOrder(false);
      });
    }

    const columnOrder = syncColumnOrder || columnState?.orderedFields || defaultOrder;
    const columnLookup = columnState?.lookup;

    return columnOrder
      .filter((field) => field in columns)
      .map((field) => {
        const currentColumn = columns[field];
        const storedColumn = (columnLookup && columnLookup[field]) ?? {
          width: currentColumn.width,
          flex: currentColumn.flex,
          computedWidth: undefined,
          hasBeenResized: false,
        };

        // data from stored columns to override
        const { width = 100, computedWidth, flex, hasBeenResized } = storedColumn;

        return {
          field,
          ...columns[field], // current column (including renderers, value getters, etc)
          ...appendToColumns?.[field], // extra data
          computedWidth,
          width,
          flex,
          hasBeenResized,
          hideable: disabledColumns?.[field] !== true,
        };
      });
  }, [columns, appendToColumns, disabledColumns, apiRef, shouldSyncColumnOrder, columnOrderToSync]);

  const saveState = debounce((update, type) => {
    if (!apiRef.current) return;

    const state = apiRef.current.exportState();

    if (type === 'columns') {
      if (!state.columns) {
        state.columns = {
          columnVisibilityModel: update,
        };
      } else {
        state.columns.columnVisibilityModel = update;
      }
    }

    const fullState = {
      ...state,
      serverFilters: props.enhancedGridState?.gridFilters,
      quickFilter: props.enhancedGridState?.quickFilterOption?.label,
      migrationKey: DATAGRID_STATE_MIGRATION_KEY,
    };

    localStorage.setItem(`${storageKey}-column-state`, JSON.stringify(fullState));
  }, 500);

  const loadedInitialState = useMemo(() => {
    const stateJSON = localStorage.getItem(`${storageKey}-column-state`);

    const genInitialVisModel = () => {
      const initialVis = initialState?.columns?.columnVisibilityModel;

      return mapObject(columns, (key) => {
        if (disabledColumns?.[key] !== undefined) {
          return { [key]: !disabledColumns?.[key] };
        }

        if (columnVisibilityModel?.[key] !== undefined) {
          return { [key]: columnVisibilityModel?.[key] };
        }

        // use existing column vis model if it exists
        return { [key]: initialVis?.[key] ?? true };
      });
    };

    if (stateJSON) {
      const state = JSON.parse(stateJSON);

      // always load aggregation and page directly from grid
      delete state.aggregation;
      delete state.pagination?.paginationModel?.page;

      //...i'm not sure if this is good, since saved filters are also pulled a similar method. I think instead, we could be more diligent with what we store in the state.
      if (state.migrationKey === DATAGRID_STATE_MIGRATION_KEY) {
        // Added log to debug

        // DataGrid only saves the visibility of columns that are in the columnVisibilityModel,
        // so it neeeds to be initialized with all columns
        // when the user does not have the option to hide columns, the columnVisibilityModel should not be restored
        if (!toolbar && !props.slots?.toolbar) {
          state.columns = {
            ...(state.columns || {}),
            columnVisibilityModel: genInitialVisModel(),
          };
        }

        return state;
      }
    }

    const defaultState = {
      columns: {
        ...(initialState?.columns || {}),
        columnVisibilityModel: genInitialVisModel(),
      },
    };

    return defaultState;
  }, [
    storageKey,
    initialState?.columns,
    columns,
    disabledColumns,
    columnVisibilityModel,
    toolbar,
    props.slots?.toolbar,
  ]);

  const disabledVisibilty = useMemo(
    () => mapObject(disabledColumns ?? {}, (key) => ({ [key]: !disabledColumns[key] })),
    [disabledColumns]
  );

  const [visibilityModel, setColumnVisibility] = useState(
    loadedInitialState.columns?.columnVisibilityModel ?? initialState?.columns?.columnVisibilityModel
  );

  const flags = useFlags();
  const overrideVisibility = flags['disabledColumns']?.[storageKey] ?? {};

  const mergedState = useMemo(() => ({ ...initialState, ...loadedInitialState }), [initialState, loadedInitialState]);

  useEffect(() => {
    onAfterFirstLoad?.(mergedState.filter?.filterModel, mergedState.sorting?.sortModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    saveState({}, 'serverEnhancedGridStateUpdate');
  }, [props.enhancedGridState?.quickFilterOption?.label, props.enhancedGridState?.gridFilters, saveState]);

  const persistedGrid = useMemo(
    () => (
      <PersistentDataGrid
        columns={computedColumns}
        apiRef={apiRef}
        rows={rows}
        checkboxSelection={checkboxSelection}
        {...props}
        onColumnOrderChange={saveState}
        onColumnWidthChange={saveState}
        onRowGroupingModelChange={saveState}
        onSortModelChange={(...event) => {
          saveState(...event);
          onSortModelChange?.(...event);
        }}
        onFilterModelChange={(model, details) => {
          saveState(model, details);
          onFilterModelChange?.(model, details);
        }}
        onColumnVisibilityModelChange={(update) => {
          saveState(update, 'columns');
          setColumnVisibility(update);
        }}
        onPaginationModelChange={(...event) => {
          saveState();
          onPaginationModelChange?.(...event);
        }}
        onPinnedColumnsChange={saveState}
        columnVisibilityModel={{
          ...visibilityModel,
          ...columnVisibilityModel,
          ...disabledVisibilty,
          ...overrideVisibility,
        }}
        initialState={mergedState}
        pinnedRows={pinnedRows}
        toolbar={toolbar}
        height={height}
      />
    ),
    /* Intentially not providing all dependency props
     * To avoid over re-rendering
     * Add any props that require a re-render manually
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rows,
      pinnedRows,
      toolbar,
      rowSelectionModel,
      visibilityModel,
      computedColumns,
      disabledVisibilty,
      columnVisibilityModel,
      height,
    ]
  );

  const { handleGridFocus, focusedGrid } = useGridFocus();

  const handleFocusIn = useCallback(
    (event) => {
      const mainGrid = event.target.closest('.MuiDataGrid-main');

      if (mainGrid) {
        handleGridFocus(storageKey);
      }
    },
    [handleGridFocus, storageKey]
  );

  const handleFocusOut = useCallback(
    (event) => {
      const mainGrid = event.target.closest('.MuiDataGrid-main');

      if (mainGrid && focusedGrid === storageKey) {
        handleGridFocus(undefined);
      }
    },
    [handleGridFocus, focusedGrid, storageKey]
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const curRef = ref.current;

    curRef?.addEventListener('focusin', handleFocusIn);
    curRef?.addEventListener('focusout', handleFocusOut);
    return () => {
      curRef?.removeEventListener('focusin', handleFocusIn);
      curRef?.removeEventListener('focusout', handleFocusOut);
    };
  }, [handleFocusIn, ref, handleFocusOut]);

  return (
    <GridCoreProvider>
      <Box
        ref={ref}
        sx={{
          width: '100%',
        }}
      >
        {persistedGrid}
      </Box>
    </GridCoreProvider>
  );
}

/*
 * DataGrid wrapper to optimize column re-ordering
 */
export function PersistentDataGrid({
  toolbar,
  slots,
  columns,
  checkboxSelection,
  pageSizeOptions = [5, 10, 25, 50, 100],
  trackHover,
  slotProps = {},
  disabledColumns,
  hideFooter,
  apiRef,
  sx,
  initialState,
  defaultGroupingExpansionDepth,
  getRowId = (row) => row.id,
  shouldPreventRowProcess,
  processRowUpdate,
  onProcessRowUpdateError,
  autoHeight = true,
  variant = 'grid',
  height,
  getRowHeight,
  ...props
}: PersistentDataGridProps) {
  const setHoveredRowId = useGridCore((store) => store.setHoveredRowId);
  const setHoveredField = useGridCore((store) => store.setHoveredField);
  const expandedGroups = useGridCore((store) => store.expandedGroups);

  const CustomToolbar = useCallback(() => {
    if (slots?.toolbar) {
      return slots?.toolbar;
    }

    return toolbar && <Toolbar {...toolbar} />;
  }, [toolbar, slots?.toolbar]);

  const isGroupExpandedByDefault = useCallback(
    (group) => expandedGroups[group.groupingKey] ?? group.depth < defaultGroupingExpansionDepth,
    [defaultGroupingExpansionDepth, expandedGroups]
  );

  const styles = useMemo(() => {
    return {
      height,
      ...baseGridStyles(variant),
      ...sx,
    };
  }, [sx, variant, height]);

  useEffect(() => {
    type MultiSubscriber<E extends GridEvents> = Array<{ event: E; handler: GridEventListener<E> }>;

    if (!trackHover === 'cell') {
      return;
    }

    const eventListeners: MultiSubscriber<'cellMouseOver' | 'rowMouseOver' | 'rowMouseLeave'> = [
      { event: 'cellMouseOver', handler: (event) => setHoveredField(event.field) },
      { event: 'rowMouseOver', handler: (event) => setHoveredRowId(event.id) },
      {
        event: 'rowMouseLeave',
        handler: () => {
          setHoveredRowId(null);
          setHoveredField(null);
        },
      },
    ];

    const subscriptions = eventListeners.map(({ event, handler }) => apiRef.current?.subscribeEvent(event, handler));

    return () => subscriptions.forEach((unsubscribe) => unsubscribe());
  });

  const showMoreRowId = useGridCore((store) => store.showMoreRowId);

  return (
    <DataGridPremium
      {...props}
      getRowId={getRowId}
      columns={columns}
      disableColumnMenu
      disableColumnFilter
      checkboxSelection={checkboxSelection}
      apiRef={apiRef}
      initialState={initialState}
      defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
      isGroupExpandedByDefault={isGroupExpandedByDefault}
      getRowHeight={(params) => {
        if (params.id === showMoreRowId) {
          return 'auto';
        }

        return getRowHeight?.(params) ?? 52 * params.densityFactor;
      }}
      slotProps={{
        ...slotProps,
        row: slotProps?.row,
        columnsManagement: {
          getTogglableColumns: (columns) => {
            return columns
              .filter((column) => {
                return !disabledColumns?.[column.field];
              })
              .map((column) => column.field);
          },
        },
      }}
      hideFooter={hideFooter}
      slots={{
        ...slots,
        toolbar: CustomToolbar,
      }}
      autoHeight={autoHeight}
      sx={styles}
      processRowUpdate={async (oldRow, newRow) => {
        const preventProcess = await shouldPreventRowProcess?.(getRowId(oldRow), oldRow, newRow);

        if (preventProcess) {
          throw new Error('Row update prevented', {
            cause: 'preventRowUpdate',
          });
        }

        return processRowUpdate?.(oldRow, newRow);
      }}
      onProcessRowUpdateError={(error) => {
        if (error.cause === 'preventRowUpdate') {
          return;
        }

        onProcessRowUpdateError?.(error);
      }}
      onCellEditStart={useCallback((_params, event) => {
        event.currentTarget?.select?.();
      }, [])}
      pageSizeOptions={pageSizeOptions}
      disableRowSelectionOnClick={props.disableRowSelectionOnClick ?? true}
    />
  );
}
