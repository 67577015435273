import { useMemo } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-premium';
import Money from 'shared/utils/Money';

import { routes } from '@redwoodjs/router';
import { Metadata } from '@redwoodjs/web';

import Link from 'src/components/atoms/Link';
import StatusBadge from 'src/components/atoms/StatusBadge';
import { financialEntityStatuses } from 'src/components/atoms/StatusStepper';
import { GridColObj } from 'src/components/containers/grids/DataGrid';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import useGridExport from 'src/hooks/useGridExport';
import { createViewConfigStore, VIEW_PAGES } from 'src/managers/viewConfig.store';
import ServerEnhancedDataGrid from 'src/modules/grids/serverEnhanced/ServerEnhancedDataGrid';
import { useServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import GridToolbarV2, { GridToolbarQuickFilter } from 'src/modules/grids/toolbar/GridToolbarV2';
import { StoreStateProvider } from 'src/providers/StoreStateProvider';
import { dateCellFormatter, dateGetter, formatCurrency } from 'src/utils/grid/cell/cellFormatters';

import { useCustomerPortalInvoicesGrid } from './useCustomerPortalInvoicesGrid';

const STORAGE_KEY = 'customer-invoices';

const columns: GridColObj = {
  slug: {
    headerName: 'Invoice #',
    flex: 1,
    renderCell: ({ value }) => <Link to={routes.viewCustomerPortalInvoice({ slug: value })} label={`#${value}`} />,
  },
  status: {
    headerName: 'Status',
    flex: 1,
    minWidth: 110,
    getFilterSelectOptions: async () => financialEntityStatuses,
    renderCell: ({ value }) => <StatusBadge status={value} context="invoice" />,
  },
  invoiceDate: {
    headerName: 'Invoice date',
    flex: 1,
    type: 'date',
    valueGetter: (value) => dateGetter(value),
    valueFormatter: dateCellFormatter,
  },
  shipDate: {
    headerName: 'PO ship date',
    flex: 1,
    type: 'date',
    valueGetter: (value) => dateGetter(value),
    valueFormatter: dateCellFormatter,
  },
  paymentDueDate: {
    headerName: 'Due date',
    flex: 1,
    type: 'date',
    valueGetter: (value) => dateGetter(value),
    valueFormatter: dateCellFormatter,
  },
  orderSlug: {
    headerName: 'Order #',
    flex: 1,
    renderCell: ({ value }) => (
      <Link to={routes.editCustomerPortalPurchaseOrder({ slug: value })} label={`PO #${value}`} />
    ),
  },
  totalPaid: {
    headerName: 'Paid amount',
    flex: 1,
    type: 'number',
    serverFilterValueTransform: {
      to: (value) => Money.toCents(value),
      from: (value) => Money.toDollars(value),
    },
    valueFormatter: (value, row) =>
      typeof value === 'number'
        ? formatCurrency({ value: Money.toDollars(value), currency: row.currencyCode || 'USD' })
        : '--',
  },
  amountRemaining: {
    headerName: 'Remaining amount',
    flex: 1,
    type: 'number',
    serverFilterValueTransform: {
      to: (value) => Money.toCents(value),
      from: (value) => Money.toDollars(value),
    },
    valueFormatter: (value, row) =>
      typeof value === 'number'
        ? formatCurrency({ value: Money.toDollars(value), currency: row.currencyCode || 'USD' })
        : '--',
  },
  totalAmountDue: {
    headerName: 'Total',
    flex: 1,
    type: 'number',
    serverFilterValueTransform: {
      to: (value) => Money.toCents(value),
      from: (value) => Money.toDollars(value),
    },
    valueFormatter: (value, row) =>
      typeof value === 'number'
        ? formatCurrency({ value: Money.toDollars(value), currency: row.currencyCode || 'USD' })
        : '--',
  },
};

const gridQuickFilters: GridToolbarQuickFilter[] = [
  {
    label: 'All',
    filters: [
      {
        field: 'status',
        where: [{ not: 'DRAFT' }],
      },
      { field: 'invoiceType', where: [{ equals: 'SO' }] },
    ],
  },
  {
    label: 'Open',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'OPEN' }],
      },
      { field: 'invoiceType', where: [{ equals: 'SO' }] },
    ],
  },
  {
    label: 'Paid partial',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'PAID_PARTIAL' }],
      },
      { field: 'invoiceType', where: [{ equals: 'SO' }] },
    ],
  },
  {
    label: 'Paid full',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'PAID_FULL' }],
      },
      { field: 'invoiceType', where: [{ equals: 'SO' }] },
    ],
  },
  {
    label: 'Cancelled',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'VOIDED' }],
      },
      { field: 'invoiceType', where: [{ equals: 'SO' }] },
    ],
  },
  {
    label: 'Finalized',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'FINALIZED' }],
      },
      { field: 'invoiceType', where: [{ equals: 'SO' }] },
    ],
  },
];

export default function CustomerPortalInvoicesPage() {
  const apiRef = useGridApiRef();
  const enhancedGridState = useServerEnhancedGridState(STORAGE_KEY, gridQuickFilters);
  const { rows, loading, pageInfo, totalCount, fetchAllForExport } = useCustomerPortalInvoicesGrid(enhancedGridState);

  useGridExport(apiRef, 'invoices', undefined, undefined, fetchAllForExport);

  const toolbar = useMemo(
    () => (
      <GridToolbarV2
        apiRef={apiRef}
        enhancedGridState={enhancedGridState}
        quickFilterCountModel="UnifiedInvoice"
        viewPage={VIEW_PAGES.CUSTOMER_PORTAL_PUCHASE_ORDERS}
      />
    ),
    [apiRef, enhancedGridState]
  );

  return (
    <>
      <Metadata title="Invoices" />
      <PageHeader pageType={PageType.CustomerPortalInvoices} />
      <PageBody>
        <StoreStateProvider initializeStore={createViewConfigStore(VIEW_PAGES.CUSTOMER_PORTAL_INVOICES)}>
          <ServerEnhancedDataGrid
            sx={{ p: 3 }}
            enhancedGridState={enhancedGridState}
            totalCount={totalCount}
            pageInfo={pageInfo}
            loading={loading}
            rows={rows}
            storageKey={STORAGE_KEY}
            columns={columns}
            apiRef={apiRef}
            slots={{ toolbar }}
          />
        </StoreStateProvider>
      </PageBody>
    </>
  );
}
