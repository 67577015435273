import type { LineItemAttributes } from 'types/graphql';

export const PRODUCTS_GRID_ID = 'products-grid';

/**
 * Creates a draft row selector for sub groupings, by ensuring the
 * parentLineItemIds match up.
 */
export const subGroupDraftRowSelector = (parentLineItemId) => (rowToCompare) =>
  rowToCompare.isNew && rowToCompare.parentLineItemId === parentLineItemId;

export type ProductLineItemRows = LineItemAttributes[];
