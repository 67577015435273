import { Typography } from '@mui/material';

import Skeleton from 'src/components/atoms/Skeleton';
import SkeletonDataGrid from 'src/components/containers/skeleton/SkeletonDataGrid';
import { PRODUCTS_GRID_ID } from 'src/modules/grids/productLineItemsGrid/utils';

const columns = { a: {}, b: {}, c: {}, d: {} };

export const ProductsGridSkeleton = () => {
  return (
    <>
      <Skeleton>
        <Typography sx={{ pl: 1, pb: 0.5 }} variant="h6">
          Products
        </Typography>
      </Skeleton>
      <SkeletonDataGrid
        rowCount={4}
        columns={columns}
        initialState={{ density: 'compact' }}
        hideFooter
        enterNewText="Add new product"
        storageKey={PRODUCTS_GRID_ID}
      />
    </>
  );
};
