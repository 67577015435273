import { createContext, useContext } from 'react';

const StoreStateLensFocusContext = createContext<Record<string, (state) => any>>(null);

export function StoreStateLensFocusProvider<T, R>({
  children,
  lensSelectors,
}: {
  children: React.ReactNode;
  lensSelectors: Record<string, (state: T) => R>;
}) {
  return <StoreStateLensFocusContext.Provider value={lensSelectors}>{children}</StoreStateLensFocusContext.Provider>;
}

export function useStoreStateLensFocus(lens: string) {
  const selectors = useContext(StoreStateLensFocusContext);

  if (!selectors?.[lens]) {
    return (state) => state[lens];
  }

  return selectors[lens];
}
