import { Preview } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { useSideDrawer } from 'src/components/containers/SideDrawer';

export default function DownloadInvoice() {
  const { openDrawer } = useSideDrawer();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        startIcon={<Preview />}
        onClick={() => {
          openDrawer(null);
        }}
      >
        Invoice Document Preview
      </Button>
    </Box>
  );
}
