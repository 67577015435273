import { Paper } from '@mui/material';

import SkeletonEntryForm from 'src/components/containers/skeleton/SkeletonEntryForm';
import SkeletonStepper from 'src/components/containers/skeleton/SkeletonStepper';
import { ProductsGridSkeleton } from 'src/modules/grids/productLineItemsGrid/components/ProductsGridSkeleton';

type OrderSkeletonProps = {
  statuses: string[];
  numEntryFormFields?: number;
};

export default function OrderSkeleton(props: OrderSkeletonProps) {
  return (
    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <SkeletonStepper allStatuses={props.statuses} />
      <SkeletonEntryForm fields={props.numEntryFormFields ?? 8} />
      <ProductsGridSkeleton />
    </Paper>
  );
}
