import { GridAggregationFunction } from '@mui/x-data-grid-premium';
import { dateSort } from 'shared/utils/DateTime';

export const countAggregation: GridAggregationFunction<string, string | null> = {
  apply: (params) => {
    if (params.values.length === 0) {
      return '(0)';
    }

    const diffs = new Set(params.values);

    if (diffs.size > 1) {
      return `(${diffs.size})`;
    }

    return params.values[0];
  },
  label: 'count',
  columnTypes: ['string'],
};

export const maxDateAggregation: GridAggregationFunction<string, string | null> = {
  apply: ({ values }) => {
    const sorted = values.sort(dateSort);

    return sorted[0];
  },
  label: 'maxDate',
  columnTypes: ['date'],
};

export const hasDiffAggregation: GridAggregationFunction<string, string | null> = {
  apply: (params) => {
    const [first, ...rest] = params.values;

    if (rest.length > 0 && rest.some((value) => value !== first)) {
      return null;
    }

    return first;
  },
  label: 'hasDiff',
  columnTypes: ['string'],
};

export const dashDiffAggregation: GridAggregationFunction<string, string | null> = {
  apply: (params) => {
    const [first, ...rest] = params.values;

    if (rest.length > 0 && rest.some((value) => value !== first)) {
      return '--';
    }

    return first;
  },
  label: 'dashDiff',
  columnTypes: ['string'],
};

export const totalsLabelAggregation: GridAggregationFunction<string, string | null> = {
  apply: () => 'TOTALS:',
  label: 'totals',
};
