import { useEffect } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { Button, Chip, Popover, Stack, Typography } from '@mui/material';

import { GridColValue } from 'src/components/containers/grids/DataGrid';
import useAnchorEl from 'src/hooks/useAnchorEl';
import ScalarTextFilter from 'src/modules/grids/toolbar/filters/ScalarTextFilter';

import { ServerGridFilter } from '../../serverEnhanced/types';

import BooleanFilter from './BooleanFilter';
import DateFilter from './DateFilter';
import NumberFilter from './NumberFilter';
import SelectFilter from './SelectFilter';
import TextFilter from './TextFilter';
import { asUTCDate, fromUTC, parseDate } from 'shared/utils/DateTime';

export type FilterItemProps = {
  filter: ServerGridFilter;
  column: GridColValue;
  removeFilter: () => void;
  updateFilter: (filter: ServerGridFilter) => void;
};

export default function FilterItem(props: FilterItemProps) {
  const { anchorEl, open, handleClick, handleClose } = useAnchorEl();
  const { column, filter } = props;
  const colName = column.headerName ?? column.field;
  const colType = column.type;
  const active = filter.where?.length > 0;

  const getButtonValueDisplay = () => {
    if (props.column.getFilterSelectOptions) {
      const categories = filter.where[0].in ?? [];
      if (categories.length === 1) {
        return categories[0];
      }
      return <Chip label={categories.length} size="small" sx={{ bgcolor: 'primary.200', color: 'primary.800' }} />;
    }
    if (colType === 'date' || colType === 'dateTime') {
      const gte = filter.where.find((cond) => cond.gte)?.gte;
      const lte = filter.where.find((cond) => cond.lte)?.lte;
      if (gte && lte) {
        return `${asUTCDate(gte).toFormat('MM/dd/yyyy')} - ${asUTCDate(lte).toFormat('MM/dd/yyyy')}`;
      }
      if (gte) {
        return `After ${asUTCDate(gte).toFormat('MM/dd/yyyy')}`;
      }
      if (lte) {
        return `Before ${asUTCDate(lte).toFormat('MM/dd/yyyy')}`;
      }
      return '--';
    }
    if (colType === 'number') {
      const gte = filter.where.find((cond) => cond.gte)?.gte;
      const lte = filter.where.find((cond) => cond.lte)?.lte;
      if (gte && lte) {
        return `${gte} - ${lte}`;
      }
      if (gte) {
        return `≥ ${gte}`;
      }
      if (lte) {
        return `≤ ${lte}`;
      }
      return '--';
    }
    if (colType === 'boolean') {
      return props.filter.where[0].equals ? 'Yes' : 'No';
    }
    return props.filter.where[0].contains ?? '--';
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="primary"
        size="small"
        sx={{
          borderRadius: '999px',
          textTransform: 'none',
          fontSize: '14px',
          px: 1.5,
          pt: 0.5,
          pb: 0.25,
          display: 'flex',
          alignItems: 'center',
        }}
        endIcon={<ArrowDropDown />}
      >
        <Typography sx={{ color: 'primary.800' }}>
          {colName}
          {active ? ':' : ''}
        </Typography>
        {active && <Typography sx={{ ml: '4px', fontWeight: 700 }}>{getButtonValueDisplay()}</Typography>}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: 'rounded mt-2' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack sx={{ minWidth: '200px', maxHeight: 'min(40vh, 500px)' }}>
          <Typography variant="overline" sx={{ px: 1, pt: 1 }}>
            FILTER BY {colName}
          </Typography>
          {props.column.getFilterSelectOptions ? (
            <SelectFilter {...props} />
          ) : colType === 'scalar_string' ? (
            <ScalarTextFilter {...props} />
          ) : colType === 'date' || colType === 'dateTime' ? (
            <DateFilter {...props} />
          ) : colType === 'number' ? (
            <NumberFilter {...props} />
          ) : colType === 'boolean' ? (
            <BooleanFilter {...props} />
          ) : (
            <TextFilter {...props} />
          )}
        </Stack>
      </Popover>
    </>
  );
}
