import { Box, Button } from '@mui/material';

import { Metadata } from '@redwoodjs/web';

import { customerPortalPurchaseOrderStatuses } from 'src/components/atoms/StatusStepper';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';

import useCustomerPortalApi from '../useCustomerPortalApi';

import EditCustomerPortalPurchaseOrder from './EditCustomerPortalPurchaseOrder';
import OrderSkeleton from './OrderSkeleton';
import useCustomerPortalPurchaseOrder from './useCustomerPortalPurchaseOrder';

export default function EditCustomerPortalPurchaseOrderPage({ slug }) {
  const { submitPurchaseOrder } = useCustomerPortalApi();
  const purchaseOrder = useCustomerPortalPurchaseOrder(slug);

  return (
    <>
      <Metadata title={`#PO ${slug}`} />
      <PageHeader pageType={PageType.CustomerPortalPurchaseOrder} entitySlug={slug}>
        <Box sx={{ ml: 'auto', my: 'auto', display: 'flex', gap: 1 }}>
          {purchaseOrder && (
            <Button
              variant="contained"
              disabled={purchaseOrder.status !== 'DRAFT'}
              onClick={() => submitPurchaseOrder(purchaseOrder.id)}
            >
              {purchaseOrder.status !== 'DRAFT' ? 'Submitted' : 'Submit Order'}
            </Button>
          )}
        </Box>
      </PageHeader>
      <PageBody sx={{ flexDirection: 'column' }}>
        {purchaseOrder ? (
          <EditCustomerPortalPurchaseOrder purchaseOrder={purchaseOrder} />
        ) : (
          <OrderSkeleton statuses={customerPortalPurchaseOrderStatuses} />
        )}
      </PageBody>
    </>
  );
}
