import { isNil } from 'lodash';

import { ListTemplate, Place } from '../../types';
import { asUTCDate } from '../../utils/DateTime';

const DEFAULT_CURRENCY = 'USD';

export const formatCurrency = (value?: number | null, currency = DEFAULT_CURRENCY) => {
  if (isNil(value)) {
    return '--';
  }

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: currency ?? DEFAULT_CURRENCY,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatDate = (date?: string | Date | null) => {
  const utcDate = asUTCDate(date);

  if (!utcDate) {
    return '';
  }

  return utcDate.toFormat('MM/dd/yyyy');
};

/**
 * Formats a decimal number to a string
 *
 * When the decimal is a whole number, it will not show any decimal places
 * When the decimal is not a whole number, it will show 2 decimal places
 *
 * If the decimal is null, an empty string will be returned.
 * If the decimal is undefined, we assume the value is '0'.
 *
 */
export const formatDecimal = (value: number | undefined | null) => {
  if (value === null) {
    return '';
  }

  if (value === undefined) {
    return '0';
  }

  if (Math.round(value) === value) {
    return value.toLocaleString('en-US');
  }

  return value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const applyListMeta = <T>(list: Array<T>): ListTemplate<T> => {
  return {
    isEmpty: !list?.length,
    items: list,
  };
};

/**
 * Applies meta data to tables used for styling
 */
export const applyTableMetadata = <T>(table: Array<T>): ListTemplate<T> => {
  return {
    isEmpty: !table?.length,
    items: table.map((row, index) => {
      const isFirst = index === 0;
      const isLast = index === table.length - 1;
      const isMiddle = !isFirst && !isLast;

      return { ...row, ...{ isFirst, isLast, isMiddle, index } };
    }),
  };
};

export const formatOrganizationName = ({
  organization,
  subsidiary,
  customOrgName,
}: {
  organization?: { label?: string };
  subsidiary?: { description?: string };
  customOrgName?: string;
}): string => {
  const name = customOrgName ? subsidiary?.description : organization?.label;

  if (!name) {
    return '';
  }

  return name;
};

export const getPrimaryBusiness = (organization: { primaryLocation?: Place; places?: Place[] }) => {
  if (!organization) {
    return undefined;
  }

  if (organization.primaryLocation) {
    return organization.primaryLocation;
  }

  const primaryBusiness = organization.places?.find((place) => place.addressRoles?.includes('PRIMARY_BUSINESS'));

  return primaryBusiness;
};
