import { useCallback, useMemo, useRef, useState } from 'react';

import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { Button, FormControlLabel, IconButton, Stack, Switch, TextField, Typography } from '@mui/material';
import {
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';

export default function CustomizeTable({ closeMenu }) {
  const apiRef = useGridApiContext();
  const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector);
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const initialState = useRef(columnVisibilityModel);
  const [search, setSearch] = useState('');

  const columnsToShow = useMemo(
    () =>
      columns.filter((col) => {
        // something is overriding hideable
        if (col.hideable === false || col.actually_hideable === false) return false;
        if (!search) return true;
        return col.headerName?.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      }),
    [columns, search]
  );

  const toggleColumn = useCallback(
    (field: string, checked: boolean) => {
      apiRef.current.setColumnVisibility(field, checked);
    },
    [apiRef]
  );

  const allEnabled = useMemo(
    () => columnsToShow.every((col) => columnVisibilityModel[col.field] !== false),
    [columnVisibilityModel, columnsToShow]
  );

  const toggleAll = useCallback(
    (checked: boolean) => {
      const newColumnVisibilityModel = {
        ...columnVisibilityModel,
      };
      columnsToShow.forEach((col) => {
        newColumnVisibilityModel[col.field] = checked;
      });
      apiRef.current.setColumnVisibilityModel(newColumnVisibilityModel);
    },
    [apiRef, columnVisibilityModel, columnsToShow]
  );

  const reset = useCallback(() => {
    apiRef.current.setColumnVisibilityModel(initialState.current);
    closeMenu();
  }, [apiRef, closeMenu]);

  return (
    <Stack sx={{ minWidth: '256px' }}>
      <Typography sx={{ p: 0.5, pl: 2 }} variant="overline">
        CUSTOMIZE TABLE
      </Typography>
      <TextField
        placeholder="Search columns"
        sx={{ px: 1.5 }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        variant="outlined"
        size="small"
        type="search"
        fullWidth
        slotProps={{
          input: {
            startAdornment: <SearchIcon className="text-grey-500 mr-2" />,
            endAdornment: search && (
              <IconButton onClick={() => setSearch('')}>
                <ClearIcon />
              </IconButton>
            ),
          },
        }}
      />
      <Stack sx={{ maxHeight: '50vh', overflowY: 'auto', pt: 1 }}>
        {columnsToShow.map((col) => (
          <FormControlLabel
            key={col.field}
            sx={{ p: 1, m: 0, gap: 1, '&:hover': { bgcolor: 'primary.100' } }}
            control={<Switch checked={columnVisibilityModel[col.field] !== false} size="small" sx={{ p: 1 }} />}
            label={col.headerName ?? col.field}
            onChange={(_, val) => toggleColumn(col.field, val)}
          />
        ))}
      </Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 1, pb: 0.5, borderTop: '1px solid #D8D9D7' }}>
        <Button onClick={reset}>Reset</Button>
        <Button variant="contained" onClick={() => toggleAll(allEnabled ? false : true)}>
          {allEnabled ? 'Disable' : 'Enable'} All
        </Button>
      </Stack>
    </Stack>
  );
}
