import { useQuery } from '@redwoodjs/web';

export const GET_CUSTOMER_PORTAL_LOCATIONS = gql`
  query GetCustomerPortalLocations {
    customerPortalLocations {
      shipping {
        id
        label
      }
      billing {
        id
        label
      }
    }
  }
`;

export default function useCustomerPortalLocations() {
  const { data } = useQuery(GET_CUSTOMER_PORTAL_LOCATIONS);
  return data?.customerPortalLocations;
}
