import { useMemo, useState } from 'react';

import { Grid2, Stack, TextField, Typography } from '@mui/material';
import { SalesTermsMapper } from 'shared/mappers';

import Autocomplete from 'src/components/atoms/Autocomplete';
import QuickDatePicker from 'src/components/atoms/QuickDatePicker';

import useCustomerPortalApi from '../useCustomerPortalApi';
import useCustomerPortalLocations from '../useCustomerPortalLocations';

import { CustomerPortalPurchaseOrderData } from './useCustomerPortalPurchaseOrder';

export default function OrderDetails({ purchaseOrder }: { purchaseOrder: CustomerPortalPurchaseOrderData }) {
  const { updatePurchaseOrder } = useCustomerPortalApi();
  const [orderDate, setOrderDate] = useState(purchaseOrder.orderDate);
  const [shipToId, setShipToId] = useState(purchaseOrder.shipment?.destinationId);
  const [billToId, setBillToId] = useState(purchaseOrder.billToId);

  const locations = useCustomerPortalLocations();

  const shipTo = useMemo(() => locations?.shipping?.find((l) => l.id === shipToId), [locations, shipToId]);
  const billTo = useMemo(() => locations?.billing?.find((l) => l.id === billToId), [locations, billToId]);

  if (!locations) return null;

  const Field = ({ label, displayValue, children }) => (
    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
      {purchaseOrder.status !== 'DRAFT' ? (
        <Stack>
          <Typography variant="overline" sx={{ color: 'grey.600' }}>
            {label}
          </Typography>
          <Typography sx={{ fontSize: '17px' }}>{displayValue}</Typography>
        </Stack>
      ) : (
        children
      )}
    </Grid2>
  );

  return (
    <Grid2 container spacing={2}>
      <Field label="Customer" displayValue={purchaseOrder.businessEntity.name}>
        <TextField fullWidth label="Customer Name" disabled value={purchaseOrder.businessEntity.name} />
      </Field>
      <Field label="Order Date" displayValue={new Date(purchaseOrder.orderDate)?.toLocaleDateString()}>
        <QuickDatePicker
          label="Order Date"
          value={orderDate}
          onChange={(data) => {
            setOrderDate(data);
            updatePurchaseOrder(purchaseOrder.id, { orderDate: data });
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </Field>
      <Field label="Ship To" displayValue={shipTo?.label ?? '--'}>
        <Autocomplete
          value={shipTo}
          label="Ship To"
          options={locations.shipping}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(l) => l.label}
          disabled={locations.shipping.length < 2}
          onChange={(_, value) => {
            setShipToId(value?.id);
            updatePurchaseOrder(purchaseOrder.id, { shipToId: value?.id });
          }}
        />
      </Field>
      <Field label="Bill To" displayValue={billTo?.label ?? '--'}>
        <Autocomplete
          value={billTo}
          label="Bill To"
          options={locations.billing}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(l) => l.label}
          disabled={locations.billing.length < 2}
          onChange={(_, value) => {
            setBillToId(value?.id);
            updatePurchaseOrder(purchaseOrder.id, { billToId: value?.id });
          }}
        />
      </Field>
      <Field
        label="Sales Terms"
        displayValue={purchaseOrder.salesTerms ? SalesTermsMapper.getLabel(purchaseOrder.salesTerms) : '--'}
      >
        <TextField fullWidth label="Sales Terms" disabled value={purchaseOrder.salesTerms} />
      </Field>
    </Grid2>
  );
}
