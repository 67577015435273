import { isNil } from 'lodash';

import { LineItem, User } from '../../core';
import { Weight } from '../../core/product';
import { SalesTermsMapper } from '../../mappers';
import {
  AccountLevel,
  BusinessEntity,
  Contact,
  Expense,
  LineItem as LineItemType,
  ListTemplate,
  Membership,
  Note,
  Organization,
  Place,
  SalesOrderStatus,
  SalesTerms,
  Shipment,
} from '../../types';
import Money from '../../utils/Money';
import { AddressComponent } from '../components/address.template';
import { documentNotes } from '../components/documentNotes.template';
import { ExpensesComponent, ExpenseTemplateDataType } from '../components/expensesGrid.template';
import { ProductsComponent, ProductTemplateDataType } from '../components/productsGrid.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import {
  applyListMeta,
  formatCurrency,
  formatDate,
  formatDecimal,
  formatOrganizationName,
  getPrimaryBusiness,
} from './formatters';

// hardcoded until material inventory is added in
const MaterialInventoryProductIDs = {
  // Millennium Pacific
  4284: 'Pallet CHEP',
};

export type SalesOrderOptions = {
  /**
   * Allow document type to set preferred units for total calculation
   */
  totalUnitsType: 'ordered' | 'picked';
};

export type SalesOrderInputType = {
  organization: Organization;
  slug: string;
  shipDate: string;
  deliveryDate: string;
  salesPerson: Membership;
  subsidiary: Place;
  salesPersonId: number;
  businessEntity?: BusinessEntity;
  businessEntityId?: number;
  businessEntityContactId?: number;
  businessEntityContact?: Contact;
  currency?: string;
  salesTerms: SalesTerms;
  status: SalesOrderStatus;
  customerPo: string;
  billToId: number;
  billTo: Place;
  currencyCode?: string;
  customFields: Record<string, string | number>;
  shipment: Shipment;
  lineItems: LineItemType[];
  expenses: Expense[];
  submittedAt: string;
  notes: Note[];
};

export type SalesOrderTemplateDataType = Partial<{
  orderNumber: string;
  status: string;
  salesPerson: string;
  customer: string;
  shipment: Partial<{
    shipDate: string;
    deliveryDate: string;
    origin: Record<string, string | undefined | boolean> | string;
    destination: Record<string, string | undefined | boolean> | string;
    freightCompany: string;
  }>;
  pickUps: ListTemplate<{
    date?: string;
    address: Record<string, string | undefined | boolean>;
    weight: string;
    pallets: string;
  }>;
  products: ProductTemplateDataType;
  expenses: ListTemplate<ExpenseTemplateDataType>;
  customFields: Record<string, string | number>;
  billToAddress: Record<string, string | undefined | boolean>;
  totalUnitsOrdered: string;
  totalUnitsPicked: string;
  totalWeight: string;
  productsSubtotal: string;
  expensesSubtotal: string;
  orderTotal: string;
  customerPo: string;
  orderDate: string;
  notes: ListTemplate<{ text: string }>;
  contactNumber?: string;
  contactEmail?: string;

  businessAddress: Record<string, string | undefined | boolean>;
}>;

export const SalesOrderTemplate: DocumentTypePopulator<
  SalesOrderInputType,
  SalesOrderOptions,
  SalesOrderTemplateDataType
> = {
  populate: (salesOrder, { totalUnitsType = 'picked', type, templateId }, globalOptions) => {
    if (!salesOrder) {
      return {};
    }

    const {
      slug,
      status,
      organization,
      subsidiary,
      salesPerson,
      shipment,
      businessEntity,
      shipDate,
      deliveryDate,
      currencyCode,
      lineItems,
      billTo,
      customFields,
      expenses,
      salesTerms,
      customerPo,
      submittedAt,
      notes,
    } = salesOrder;

    const { freightCompany } = shipment;

    const getUnitsForTotal = (lineItem: LineItemType) => {
      if (totalUnitsType === 'picked') {
        return lineItem.unitsPicked ?? lineItem.unitsShipped ?? 0;
      }

      return lineItem.unitsOrdered ?? 0;
    };

    const getUnitPrice = (lineItem: LineItemType) => {
      const deliveredPrice = lineItem.deliveredCurrencyPrice ?? lineItem.deliveredPrice;
      const sellPrice = lineItem.sellCurrencyPrice ?? lineItem.sellPrice;

      return deliveredPrice ?? sellPrice ?? 0;
    };

    const nonMaterialInventoryLineItems = lineItems.filter((li) => !(li?.product?.id in MaterialInventoryProductIDs));

    const mergedLineItems = LineItem.Utils.mergeChildLineItemsIntoParents(nonMaterialInventoryLineItems);

    const totalUnitsOrdered = mergedLineItems.reduce((acc: undefined | number, lineItem) => {
      if (acc === undefined) {
        return lineItem.unitsOrdered ?? 0;
      }

      return acc + (lineItem.unitsOrdered ?? 0);
    }, undefined);

    const totalUnitsPicked = mergedLineItems.reduce((acc: undefined | number | null, lineItem) => {
      const unitsPicked = lineItem.unitsPicked ?? lineItem.unitsShipped;

      if (isNil(acc)) {
        // return null, since users will want an empty space to fill in the data here.
        return unitsPicked ?? null;
      }

      if (isNil(acc) || isNil(unitsPicked)) {
        return null;
      }

      return acc + unitsPicked;
    }, undefined);

    const totalWeight = mergedLineItems.reduce((acc, lineItem) => {
      const weight = Weight.of(lineItem.product)
        .withLot(lineItem.lot)
        .withLineItemPalletTags(lineItem.palletTagsOnLineItem)
        .forQuantity(getUnitsForTotal(lineItem));

      return acc + weight;
    }, 0);

    const productsSubtotal = mergedLineItems.reduce((acc, lineItem) => {
      const unitCount = getUnitsForTotal(lineItem);
      const unitPrice = getUnitPrice(lineItem);

      return acc + Money.toDollars(unitPrice) * unitCount;
    }, 0);

    const arExpenses = expenses.filter((expense) => {
      const arExpense = expense.accountLevel === AccountLevel.ACCOUNTS_RECEIVABLE;
      const orderExpense = expense.businessEntity?.id === businessEntity?.id;
      const notDlv = !expense.includedInDlvPrice;

      return arExpense && orderExpense && notDlv;
    });

    const freightExpenses = expenses.filter((expense) => {
      const apExpense = expense.accountLevel === AccountLevel.ACCOUNTS_PAYABLE;
      const freightExpense = expense.businessEntity?.id === freightCompany?.id;

      return apExpense && freightExpense;
    });

    const expensesSubtotal = arExpenses.reduce((acc, expense) => {
      return acc + Money.toDollars(expense.currencyAmount ?? expense.unitAmount) * expense.quantity;
    }, 0);

    const freightExpensesSubtotal = freightExpenses.reduce((acc, expense) => {
      return acc + Money.toDollars(expense.currencyAmount ?? expense.unitAmount) * expense.quantity;
    }, 0);

    const orderTotal = productsSubtotal + expensesSubtotal;
    const primaryBusiness = getPrimaryBusiness(organization);

    return {
      orderNumber: slug,

      orgLogo: organization?.logoUrl,
      organization: formatOrganizationName({
        organization,
        subsidiary,
        customOrgName: globalOptions.customOrgName,
      }),

      status: status as unknown as string,
      orderDate: formatDate(submittedAt),
      salesPerson: User.formatName(salesPerson?.user),
      customer: businessEntity?.name ?? '',
      salesTerms: SalesTermsMapper.getLabel(salesTerms),
      customFields,
      customerPo,
      phoneNumber: primaryBusiness?.phone ?? shipment.origin?.phone ?? '--',
      email: salesPerson?.user?.email ?? '--',
      businessAddress: AddressComponent.templatize(primaryBusiness),
      fax: primaryBusiness?.faxNumber ?? '--',

      billTo: AddressComponent.templatize(billTo),

      shipment: {
        shipDate: formatDate(shipDate),
        deliveryDate: formatDate(deliveryDate),
        origin: AddressComponent.templatize(shipment.origin),
        destination: AddressComponent.templatize(shipment.place),
        freightCompany: freightCompany?.name ?? '--',
        temperatureMin: shipment.temperatureMin,
        temperatureMax: shipment.temperatureMax,
        customFields: shipment.customFields,
        terms: shipment.terms,
      },

      products: ProductsComponent.templatize(mergedLineItems, {
        getUnitsForTotal,
        getUnitPrice,
        currency: currencyCode,
      }),

      pickUps: applyListMeta([
        {
          date: formatDate(shipDate),
          address: AddressComponent.templatize(shipment.origin),
          weight: formatDecimal(totalWeight),
          pallets: formatDecimal(totalUnitsOrdered),
        },
      ]),

      notes: documentNotes(notes, templateId, type),

      charges: ExpensesComponent.templatize(arExpenses, { currency: currencyCode }),
      freightCharges: ExpensesComponent.templatize(freightExpenses, { currency: currencyCode }),

      // computed values
      totalUnitsOrdered: formatDecimal(totalUnitsOrdered),
      totalUnitsPicked: formatDecimal(totalUnitsPicked),
      totalWeight: formatDecimal(totalWeight),
      productsSubtotal: formatCurrency(productsSubtotal, currencyCode),
      chargesSubtotal: formatCurrency(expensesSubtotal, currencyCode),
      freightChargesSubtotal: formatCurrency(freightExpensesSubtotal, currencyCode),
      orderTotal: formatCurrency(orderTotal, currencyCode),
    };
  },
};
