import { createTheme } from '@mui/material';
import _ from 'lodash';

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    grey: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      25: '#FAFBF9',
      50: '#F7F8F5',
      100: '#F2F3F0',
      200: '#EDEEEB',
      300: '#E0E1DF',
      400: '#BEBEBC',
      500: '#9FA09C',
      600: '#767773',
      700: '#62635F',
      800: '#434341',
      900: '#212220',
      A100: '#D5D5D3',
      A200: '#D5D5D3',
      A400: '#626260',
      A700: '#31312F',
    },
    primary: {
      50: '#F2F5E6',
      100: '#E8EDD3',
      200: '#D4DEB2',
      300: '#BCC78B',
      400: '#98A65F',
      500: '#818F48',
      main: '#818F48', // alias for green-500
      600: '#667239',
      700: '#50592D',
      800: '#3D4222',
      900: '#202412',

      // old colors
      light: '#95A065',
      dark: '#3A4710',
      contrastText: '#FAFDE9',
    },
    secondary: {
      main: '#BF5600',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FDFDFD',
    },
    error: {
      main: '#C13A3A',
    },
    info: {
      main: '#3A87C4',
    },
    action: {
      active: 'rgba(40, 49, 11, 0.54)',
    },
    warning: {
      main: '#F9A825',
    },
    text: {
      primary: '#212220',
      secondary: '#474746',
      disabled: '#767773',
    },
  },
  breakpoints: {
    values: {
      xs: 600,
      sm: 900,
      md: 1200,
      lg: 1980,
      xl: 3440,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#212220',
          background: '#28310B14',
        },
        colorSuccess: {
          color: '#0A4C00',
          background: '#30870038',
        },
        colorWarning: {
          color: '#604A00',
          background: '#F6C74938',
        },
        colorError: {
          color: '#A64242',
          background: '#DE322B38',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          '&:hover': {
            backgroundColor: '#F4F6EE',
          },
        },
      },
    },
  },
});

export const accounts = {
  dataGridTheme: createTheme(
    _.merge({
      palette: {
        background: {
          default: '#6672391F',
        },
        primary: {
          50: '#F4F6EE',
          100: '#EAEEDD',
          200: '#CFD9AD',
          300: '#B7BF99',
          400: '#919973',
          500: '#667239',
          main: '#667239', // alias for green-500
          600: '#798844',
          700: '#5B6633',
          800: '#3D4422',
          900: '#1E2211',

          // old colors
          light: '#95A065',
          dark: '#3A4710',
          contrastText: '#FAFDE9',
        },
      },
      theme,
    })
  ),
  dataGridStyling: {
    '& .MuiDataGrid-columnHeaders': {
      background: '#6672391F',
      border: '1px solid #E0E0E0',
      borderRadiusTopLeft: '5px',
      borderRadiusTopRight: '5px',
    },
    '& .MuiDataGrid-columnHeader': {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnSeparator': {
      paddingRight: '16px',
    },
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #E0E0E0',
      borderLeft: '1px solid #E0E0E0',
      borderRight: '1px solid #E0E0E0',
    },
    '& .MuiDataGrid-cell': {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '6px',
      paddingBottom: '6px',
    },

    '& .MuiDataGrid-toolbarContainer': {
      padding: '0px',
      paddingBottom: '20px',
    },
  },
};

export default theme;
