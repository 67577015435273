import { isNil } from 'lodash';

import { LineItem, User } from '../../core';
import { Weight } from '../../core/product';
import {
  AccountLevel,
  BusinessEntity,
  Contact,
  CustomFields,
  Expense,
  LineItem as LineItemType,
  ListTemplate,
  Membership,
  Note,
  Organization,
  Place,
  SalesTerms,
  Shipment,
} from '../../types';
import Money from '../../utils/Money';
import { AddressComponent, AddressTemplateType } from '../components/address.template';
import { documentNotes } from '../components/documentNotes.template';
import { ExpensesComponent, ExpenseTemplateDataType } from '../components/expensesGrid.template';
import { ProductsComponent, ProductTemplateDataType } from '../components/productsGrid.template';
import { ShipmentComponent } from '../components/shipment.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import { formatCurrency, formatDate, formatDecimal, formatOrganizationName, getPrimaryBusiness } from './formatters';

// hardcoded until material inventory is added in
const MaterialInventoryProductIDs = {
  // Millennium Pacific
  4284: 'Pallet CHEP',
};

export type PurchaseOrderOptions = {
  /**
   * Allow document type to set preferred units for total calculation
   */
  totalUnitsType: 'ordered' | 'received';
};

export type PurchaseOrderInputType = {
  organization: Organization;
  slug: string;
  receiveDate: string; // on POs, the delivery date is on the order itself
  buyer: Membership;
  buyerId: number;
  businessEntity?: BusinessEntity;
  businessEntityId?: number;
  subsidiary?: Place;
  businessEntityContactId?: number;
  businessEntityContact?: Contact;
  currency?: string;
  submittedAt: string;
  salesTerms: SalesTerms;
  status: string;
  customerPo: string;
  billToId: number;
  billTo: Place;
  currencyCode?: string;
  customFields: Record<string, string | number>;
  shipment: Shipment;
  lineItems: LineItemType[];
  expenses: Expense[];
  notes: Note[];
  vendorPo: string; // should be vendorSo
};

type PurchaseOrderTemplateDataType = Partial<{
  orderNumber: string;
  status: string;
  salesPerson: string;
  vendor: string;
  shipment: Partial<{
    shipDate: string;
    deliveryDate: string;
    origin: AddressTemplateType;
    destination: AddressTemplateType;
    temperatureMin: number;
    temperatureMax: number;
    customFields: CustomFields;
  }>;
  products: ProductTemplateDataType;
  expenses: ExpenseTemplateDataType;
  customFields: CustomFields;
  billToAddress: AddressTemplateType;
  totalUnitsOrdered: string;
  totalUnitsPicked: string;
  totalWeight: string;
  productsSubtotal: string;
  expensesSubtotal: string;
  orderTotal: string;
  vendorSo: string;
  notes: ListTemplate<{ text: string }>;

  phoneNumber: string;
  email: string;
  businessAddress: AddressTemplateType;
}>;

export const PurchaseOrderTemplate: DocumentTypePopulator<
  PurchaseOrderInputType,
  PurchaseOrderOptions,
  PurchaseOrderTemplateDataType
> = {
  populate: (purchaseOrder, { totalUnitsType = 'received', type, templateId }, globalOptions) => {
    if (!purchaseOrder) {
      return {};
    }

    const {
      slug,
      organization,
      subsidiary,
      status,
      buyer,
      shipment,
      businessEntity,
      receiveDate,
      currencyCode,
      lineItems,
      submittedAt,
      customFields,
      expenses,
      salesTerms,
      vendorPo,
      notes,
    } = purchaseOrder;

    const getUnitsForTotal = (lineItem: LineItemType) => {
      if (totalUnitsType === 'received') {
        return lineItem.unitsReceived ?? lineItem.unitsShipped ?? 0;
      }

      return lineItem.unitsOrdered ?? 0;
    };

    const getUnitPrice = (lineItem: LineItemType) => {
      return lineItem.buyCurrencyPrice ?? lineItem.buyPrice ?? 0;
    };

    const nonMaterialInventoryLineItems = lineItems.filter((li) => !(li?.product?.id in MaterialInventoryProductIDs));

    const mergedLineItems = LineItem.Utils.mergeChildLineItemsIntoParents(nonMaterialInventoryLineItems);

    const totalUnitsOrdered = mergedLineItems.reduce((acc: undefined | number | null, lineItem) => {
      if (isNil(acc)) {
        return lineItem.unitsOrdered;
      }

      return acc + (lineItem.unitsOrdered ?? 0);
    }, undefined);

    const totalUnitsReceived = mergedLineItems.reduce((acc: undefined | number, lineItem) => {
      if (acc === undefined) {
        return lineItem.unitsReceived ?? 0;
      }

      return acc + (lineItem.unitsReceived ?? 0);
    }, undefined);

    const totalWeight = mergedLineItems.reduce((acc, lineItem) => {
      const weight = Weight.of(lineItem.product)
        .withLot(lineItem.lot)
        .withLineItemVariableWeight(lineItem)
        // .withLineItemPalletTags(lineItem.palletTagsOnLineItem) (TAS-959)
        .forQuantity(getUnitsForTotal(lineItem));

      return acc + weight;
    }, 0);

    const productsSubtotal = mergedLineItems.reduce((acc, lineItem) => {
      const unitCount = getUnitsForTotal(lineItem);
      const unitPrice = getUnitPrice(lineItem);

      return acc + Money.toDollars(unitPrice) * unitCount;
    }, 0);

    const apExpenses = expenses.filter((expense) => {
      const arExpense = expense.accountLevel === AccountLevel.ACCOUNTS_PAYABLE;
      const orderExpense = expense.businessEntity?.id === businessEntity?.id;

      return arExpense && orderExpense;
    });

    const expensesSubtotal = apExpenses.reduce((acc, expense) => {
      return acc + Money.toDollars(expense.currencyAmount ?? expense.unitAmount) * expense.quantity;
    }, 0);

    const orderTotal = productsSubtotal + expensesSubtotal;

    const primaryBusiness = getPrimaryBusiness(organization);

    return {
      orderNumber: slug,

      orgLogo: organization?.logoUrl,
      organization: formatOrganizationName({
        organization,
        subsidiary,
        customOrgName: globalOptions.customOrgName,
      }),

      phoneNumber: primaryBusiness?.phone ?? shipment.origin?.phone ?? '--',
      email: buyer?.user?.email ?? '--',
      businessAddress: AddressComponent.templatize(primaryBusiness),
      fax: primaryBusiness?.faxNumber ?? '--',

      status,
      salesPerson: User.formatName(buyer?.user),
      vendor: businessEntity?.name ?? '',
      salesTerms,
      vendorSo: vendorPo,
      orderDate: formatDate(submittedAt),

      shipment: ShipmentComponent.templatize({ ...shipment, deliveryDate: receiveDate }),

      products: ProductsComponent.templatize(mergedLineItems, {
        getUnitsForTotal,
        getUnitPrice,
        currency: currencyCode,
      }),

      notes: documentNotes(notes, templateId, type),

      charges: ExpensesComponent.templatize(apExpenses, { currency: currencyCode }),

      customFields,

      // computed values
      totalUnitsOrdered: formatDecimal(totalUnitsOrdered),
      totalUnitsReceived: formatDecimal(totalUnitsReceived),
      totalWeight: formatDecimal(totalWeight),
      productsSubtotal: formatCurrency(productsSubtotal, currencyCode),
      expensesSubtotal: formatCurrency(expensesSubtotal, currencyCode),
      orderTotal: formatCurrency(orderTotal, currencyCode),
    };
  },
  definitionMap: {
    orderNumber: { label: 'Order Number', type: 'string' },
    status: { label: 'Status', type: 'string' },
    salesPerson: { label: 'Sales Person', type: 'string' },
    vendor: { label: 'Vendor', type: 'string' },
    shipment: {
      label: 'Shipment',
      type: 'object',
      children: ShipmentComponent.definitionMap,
    },
    products: {
      label: 'Products',
      type: 'list',
      children: ProductsComponent.definitionMap,
    },
    expenses: {
      label: 'Charges',
      type: 'list',
      children: ExpensesComponent.definitionMap,
    },
    customFields: {
      label: 'Custom Fields',
      type: 'customFields',
      root: 'purchaseOrder',
    },
    billToAddress: {
      label: 'Bill To Address',
      type: 'address',
      children: AddressComponent.definitionMap,
    },
    totalUnitsOrdered: { label: 'Total Units Ordered', type: 'string' },
    totalUnitsPicked: { label: 'Total Units Picked', type: 'string' },
    totalWeight: { label: 'Total Weight', type: 'string' },
    productsSubtotal: { label: 'Products Subtotal', type: 'string' },
    expensesSubtotal: { label: 'Expenses Subtotal', type: 'string' },
    orderTotal: { label: 'Order Total', type: 'string' },
    vendorSo: { label: 'Vendor SO', type: 'string' },
    notes: {
      label: 'Notes',
      type: 'list',
      children: {
        text: { label: 'Text', type: 'string' },
      },
    },
    phoneNumber: { label: 'Phone Number', type: 'string' },
    email: { label: 'Email', type: 'string' },
    businessAddress: {
      label: 'Business Address',
      type: 'address',
      children: AddressComponent.definitionMap,
    },
  },
};
