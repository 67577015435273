import useServerEnhancedGridQuery from 'src/modules/grids/serverEnhanced/useServerEnhancedGridQuery';
import { ServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';

export const GET_CUSTOMER_PORTAL_PURCHASE_ORDERS_GRID = gql`
  query GetCustomerPortalPurchaseOrdersPage($input: StandardPageInput) {
    customerPortalPurchaseOrderPage(input: $input) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          slug
          orderDate
          status
          currencyCode
          invoiceSlug
          destination
          totalPrice
          totalUnits
          invoiceSlug
        }
        cursor
      }
    }
  }
`;

export function useCustomerPortalPurchaseOrdersGrid(gridState: ServerEnhancedGridState) {
  return useServerEnhancedGridQuery({
    queryKey: 'customerPortalPurchaseOrderPage',
    query: GET_CUSTOMER_PORTAL_PURCHASE_ORDERS_GRID,
    gridState,
  });
}
