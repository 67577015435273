import { Paper } from '@mui/material';

import Section from 'src/components/atoms/Section';
import StatusStepper, { customerPortalPurchaseOrderStatuses } from 'src/components/atoms/StatusStepper';
import { ProductsGridSkeleton } from 'src/components/containers/grids/InventoryTransferGrid';

import useCustomerPortalProducts from '../useCustomerPortalProducts';

import LineItemsGrid from './LineItemsGrid';
import OrderDetails from './OrderDetails';
import { CustomerPortalPurchaseOrderData } from './useCustomerPortalPurchaseOrder';

export default function EditCustomerPortalPurchaseOrder({
  purchaseOrder,
}: {
  purchaseOrder: CustomerPortalPurchaseOrderData;
}) {
  const products = useCustomerPortalProducts(purchaseOrder?.id);

  return (
    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <StatusStepper allStatuses={customerPortalPurchaseOrderStatuses} currentStatus={purchaseOrder.status} />
      <Section title="Order Details" noPadding>
        <OrderDetails purchaseOrder={purchaseOrder} />
      </Section>
      <Section title="Products" noPadding>
        {!products ? <ProductsGridSkeleton /> : <LineItemsGrid purchaseOrder={purchaseOrder} products={products} />}
      </Section>
    </Paper>
  );
}
