import { ReactNode, useEffect, useRef, useState } from 'react';

import { debounce, useTheme } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { Configure, useHits, useSearchBox } from 'react-instantsearch-hooks-web';

import useCellFocus from 'src/hooks/useCellFocus';
import { SearchIndex } from 'src/providers/AlgoliaProvider';

import AutoCompleteCell from './AutoCompleteCell';

export type SearchCellProps<T> = GridRenderEditCellParams & {
  filterOptions?: (options: T[], params: { inputValue: string }) => T[];
  getOptionLabel: (option: T) => string;
  filters?: string;
  index?: SearchIndex;
  details?: (option: T, inputValue: string) => ReactNode;
  transform?: (value: T) => unknown;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  textFieldProps?: Record<string, unknown>;
};

/**
 * TODO: Some improvements that are required:
 * 1. preview the first match in the cell
 * 2. accept first option when tab is hit
 * 3. when the user hits down, select the option with tab
 *
 * Note: this must be used inside of an AddableDataGrid.
 */
export default function SearchCell<T>({
  id,
  value,
  field,
  filterOptions,
  details,
  getOptionLabel,
  filters,
  index,
  transform,
  isOptionEqualToValue,
  textFieldProps,
  onChange,
  hasFocus,
  ...params
}: SearchCellProps<T>) {
  const apiRef = useGridApiContext();
  const { refine } = useSearchBox();
  const { hits, results } = useHits();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const shouldLoad = !!index || !!filters;

  const [loading, setLoading] = useState(shouldLoad);

  useEffect(() => {
    const filtersLoaded = !filters || results._state.filters === filters;
    const indexLoaded = !index || results._state.index === index;
    const finishedLoading = loading && filtersLoaded && indexLoaded;

    // Handle case when hits does not change
    if (finishedLoading) {
      setLoading(false);
    }
  }, [results, filters, index, loading]);

  const debounceChange = debounce((value) => {
    refine(value);
  }, 100);

  if (!hits) {
    return null;
  }

  console.log(filters);

  return (
    <>
      {filters && open && <Configure filters={filters} />}
      <AutoCompleteCell
        value={value || null}
        fullWidth
        sx={{
          border: 0,
        }}
        loading={open && loading}
        filterOptions={filterOptions}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionKey={(option) => option.objectID}
        options={loading ? [] : hits}
        details={details}
        getOptionLabel={getOptionLabel}
        onOpen={() => {
          console.log(value ? getOptionLabel(value) : '');

          refine(value ? getOptionLabel(value) : '');
          setLoading(!!filters);
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        slotProps={{
          popper: {
            sx: {
              zIndex: theme.zIndex.tooltip + 1,
            },
          },
        }}
        textFieldProps={{
          sx: {
            border: 0,
            '& .MuiInputBase-input': {
              fontSize: '14px',
            },
          },
          fullWidth: true,
          onChange: (e) => {
            debounceChange(e.target.value);
          },
          onBlur: () => {
            refine(value ? getOptionLabel(value) : '');
          },
          ...textFieldProps,
        }}
        onChange={(e, value) => {
          const transformed = transform ? transform(value) : value;

          apiRef.current.setEditCellValue({ id, field, value: transformed });

          onChange?.(e, transformed);

          e.stopPropagation();
        }}
        {...params}
      />
    </>
  );
}
