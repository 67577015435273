import { useMemo } from 'react';

import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import Money from 'shared/utils/Money';

import { navigate, routes } from '@redwoodjs/router';
import { Metadata } from '@redwoodjs/web';

import Link from 'src/components/atoms/Link';
import StatusBadge from 'src/components/atoms/StatusBadge';
import { salesOrderStatuses } from 'src/components/atoms/StatusStepper';
import { GridColObj } from 'src/components/containers/grids/DataGrid';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import useGridExport from 'src/hooks/useGridExport';
import { createViewConfigStore, VIEW_PAGES } from 'src/managers/viewConfig.store';
import ServerEnhancedDataGrid from 'src/modules/grids/serverEnhanced/ServerEnhancedDataGrid';
import { useServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';
import GridToolbarV2, { GridToolbarQuickFilter } from 'src/modules/grids/toolbar/GridToolbarV2';
import { StoreStateProvider } from 'src/providers/StoreStateProvider';
import { dateCellFormatter, dateGetter, formatCurrency } from 'src/utils/grid/cell/cellFormatters';

import useCustomerPortalApi from '../useCustomerPortalApi';

import { useCustomerPortalPurchaseOrdersGrid } from './useCustomerPortalPurchaseOrdersGrid';

const STORAGE_KEY = 'customer-purchase-orders';

const columns: GridColObj = {
  slug: {
    headerName: 'Order #',
    flex: 1,
    renderCell: ({ value }) => (
      <Link to={routes.editCustomerPortalPurchaseOrder({ slug: value })} label={`PO #${value}`} />
    ),
  },
  orderDate: {
    headerName: 'Date',
    flex: 1,
    type: 'date',
    valueGetter: (value) => dateGetter(value),
    valueFormatter: dateCellFormatter,
  },
  status: {
    headerName: 'Status',
    flex: 1,
    minWidth: 110,
    getFilterSelectOptions: async () => salesOrderStatuses,
    valueGetter: (value) => (value === 'DRAFT_CUSTOMER' ? 'DRAFT' : value),
    renderCell: ({ value, row }) => (
      <StatusBadge status={value} context="salesOrder" indicatorValue={row.ediPendingUpdatesCount} />
    ),
  },
  destination: {
    headerName: 'Destination',
    flex: 1,
  },
  totalUnits: {
    headerName: 'Total Units',
    flex: 1,
    type: 'number',
  },
  totalPrice: {
    headerName: 'Total Price',
    flex: 1,
    type: 'number',
    serverFilterValueTransform: {
      to: (value) => Money.toCents(value),
      from: (value) => Money.toDollars(value),
    },
    valueFormatter: (value, row) =>
      typeof value === 'number'
        ? formatCurrency({ value: Money.toDollars(value), currency: row.currencyCode || 'USD' })
        : '--',
  },
  invoiceSlug: {
    headerName: 'Linked Invoice',
    flex: 1,
  },
};

const gridQuickFilters: GridToolbarQuickFilter[] = [
  {
    label: 'All',
    filters: [
      {
        field: 'status',
        where: [{ not: 'DRAFT' }],
      },
    ],
  },
  {
    label: 'Draft',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'DRAFT_CUSTOMER' }],
      },
    ],
  },
  {
    label: 'Pending',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'PENDING' }],
      },
    ],
  },
  {
    label: 'Picking',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'PICKING' }],
      },
    ],
  },
  {
    label: 'Shipped',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'SHIPPED' }],
      },
    ],
  },
  {
    label: 'Closed',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'CLOSED' }],
      },
    ],
  },
  {
    label: 'Cancelled',
    filters: [
      {
        field: 'status',
        where: [{ equals: 'CANCELLED' }],
      },
    ],
  },
];

export default function CustomerPortalPurchaseOrdersPage() {
  const apiRef = useGridApiRef();
  const enhancedGridState = useServerEnhancedGridState(STORAGE_KEY, gridQuickFilters);
  const { rows, loading, pageInfo, totalCount, fetchAllForExport } =
    useCustomerPortalPurchaseOrdersGrid(enhancedGridState);
  const { createPurchaseOrder } = useCustomerPortalApi();

  useGridExport(apiRef, 'purchase-orders', undefined, undefined, fetchAllForExport);

  const toolbar = useMemo(
    () => (
      <GridToolbarV2
        apiRef={apiRef}
        enhancedGridState={enhancedGridState}
        quickFilterCountModel="CustomerPortalPurchaseOrder"
        viewPage={VIEW_PAGES.CUSTOMER_PORTAL_PUCHASE_ORDERS}
      />
    ),
    [apiRef, enhancedGridState]
  );

  const handleAddOrder = async () => {
    const slug = await createPurchaseOrder();
    navigate(routes.editCustomerPortalPurchaseOrder({ slug }));
  };

  return (
    <>
      <Metadata title="Orders" />
      <PageHeader pageType={PageType.CustomerPortalPurchaseOrders}>
        <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
          <Button variant="contained" startIcon={<Add />} onClick={handleAddOrder}>
            Add New Order
          </Button>
        </Box>
      </PageHeader>
      <PageBody>
        <StoreStateProvider initializeStore={createViewConfigStore(VIEW_PAGES.CUSTOMER_PORTAL_PUCHASE_ORDERS)}>
          <ServerEnhancedDataGrid
            sx={{ p: 3 }}
            enhancedGridState={enhancedGridState}
            totalCount={totalCount}
            pageInfo={pageInfo}
            loading={loading}
            rows={rows}
            storageKey={STORAGE_KEY}
            columns={columns}
            apiRef={apiRef}
            slots={{ toolbar }}
          />
        </StoreStateProvider>
      </PageBody>
    </>
  );
}
