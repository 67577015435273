import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepConnectorProps,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import Color from 'color';

import Skeleton from 'src/components/atoms/Skeleton';
import convertToTitleCase from 'src/utils/convertToTitleCase';

export default function SkeletonStepper<T extends string>({ allStatuses }: { allStatuses: T[] }) {
  return (
    <Stepper connector={<SkeletonStatusStepConnector />} sx={{ alignItems: 'flex-start', mb: 4.5, px: 2 }}>
      {allStatuses.map((label) => {
        return (
          <Step key={label}>
            <StatusStepLabel StepIconComponent={SkeletonStatusStepIcon}>
              <Skeleton>
                <Typography>{convertToTitleCase(label)}</Typography>
              </Skeleton>
            </StatusStepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

const StatusStepLabel = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(110%)',
  },
}));

const SkeletonStatusStepConnector = (props: StepConnectorProps) => {
  const theme = useTheme();

  return (
    <StepConnector
      sx={{
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: Color(theme.palette.action.disabledBackground).alpha(0.11).toString(),
          borderTopWidth: 3,
          borderRadius: 1,
          position: 'relative',
          top: 10,
          left: '-8px', // Adjust this value to expand the line to left
          right: '-8px', // Adjust this value to expand the line to right
          width: 'calc(100% + 16px)',
          animation: 'pulse 1.5s ease-in-out 0.5s infinite',
        },
        '@keyframes pulse': {
          '0%': {
            opacity: 1,
          },
          '50%': {
            opacity: 0.4,
          },
          '100%': {
            opacity: 1,
          },
        },
      }}
      {...props}
    />
  );
};

function SkeletonStatusStepIcon() {
  return (
    <Box width={24} height={24} p={0.25}>
      <Skeleton variant="circular" />
    </Box>
  );
}
