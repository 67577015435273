import { User } from '../../core';
import { Weight } from '../../core/product';
import { SalesTermsMapper } from '../../mappers';
import {
  AccountLevel,
  BusinessEntity,
  BuySellOrderLineItem,
  Contact,
  Expense,
  ListTemplate,
  Membership,
  Note,
  Organization,
  Place,
  SalesTerms,
  Shipment,
} from '../../types';
import Money from '../../utils/Money';
import { AddressComponent } from '../components/address.template';
import { documentNotes } from '../components/documentNotes.template';
import { ExpensesComponent, ExpenseTemplateDataType } from '../components/expensesGrid.template';
import { productsBsoGrid, ProductsBSOGridOptions } from '../components/productsBsoGrid.template';
import { DocumentTypePopulator } from '../DocumentGenerator';

import { formatCurrency, formatDate, formatDecimal, formatOrganizationName, getPrimaryBusiness } from './formatters';

export type BuySellOrderInputType = {
  slug: string;
  status: string;

  organization: Organization;
  broker: Membership;
  brokerId: number;
  subsidiary?: Place;

  customer: BusinessEntity;
  customerId: number;
  billToId: number; // customer
  billTo: Place; // customer
  customerContact?: Contact;
  customerContactId?: number;
  customerSalesTerms: SalesTerms;
  customerPo: string;

  vendor: BusinessEntity;
  vendorId: number;
  vendorBillToId: number;
  vendorBillTo: Place;
  vendorSalesTerms: SalesTerms;
  vendorContact?: Contact;
  vendorContactId?: number;
  vendorSo: string;

  orderDate?: Date;

  exchangeRate: number;
  currencyCode?: string;

  customFields: Record<string, string | number>;

  shipment: Shipment;
  lineItems: BuySellOrderLineItem[];
  expenses: Expense[];
  notes: Note[];
};

export type BuySellOrderTemplateDataType = Partial<{
  orderNumber: string;
  status: string;
  salesPerson: string;

  orderDate: string;

  vendor: string;
  vendorContact: string;
  vendorSalesTerms: string;
  vendorPo: string;
  vendorBillToAddress: Record<string, string | undefined | boolean>;

  customer: string;
  customerContact: string;
  customerSalesTerms: string;
  customerPo: string;
  customerBillToAddress: Record<string, string | undefined | boolean>;

  shipment: Partial<{
    shipDate: string;
    deliveryDate: string;
    origin: Record<string, string | undefined | boolean>;
    destination: Record<string, string | undefined | boolean>;
    temperatureMin: number;
    temperatureMax: number;
  }>;

  products: ListTemplate<ProductsBSOGridOptions>;
  expenses: ListTemplate<ExpenseTemplateDataType>;
  customFields: Record<string, string | number>;

  totalUnitsOrdered: string;
  totalUnitsShipped: string;
  totalUnitsInvoiced: string;
  totalWeight: string;
  productsSubtotal: string;
  arChargesSubtotal: string;
  apChargesSubtotal: string;
  orderTotal: string;
  notes: ListTemplate<{ text: string }>;

  phoneNumber: string;
  email: string;
  businessAddress: Record<string, string | undefined | boolean>;
}>;

const getProductSubtotals = (
  lineItems: BuySellOrderLineItem[],
  apTotal: number,
  arTotal: number,
  unitsKey: 'unitsOrdered' | 'unitsShipped' | 'unitsInvoiced',
  totalUnitsType: 'ordered' | 'shipped' | 'invoiced'
) => {
  const buyTotal = lineItems.reduce((acc, lineItem) => {
    const buyPrice = lineItem.buyCurrencyPrice ?? lineItem.buyPrice ?? 0;

    return acc + Money.toDollars(buyPrice) * (lineItem[unitsKey] ?? 0);
  }, 0);

  const sellTotal = lineItems.reduce((acc, lineItem) => {
    const deliveredPrice = lineItem.deliveredCurrencyPrice ?? lineItem.deliveredPrice;
    const fallbackPrice = lineItem.sellCurrencyPrice ?? lineItem.sellPrice ?? 0;
    const sellPrice = deliveredPrice ?? fallbackPrice;

    return acc + Money.toDollars(sellPrice) * (lineItem[unitsKey] ?? 0);
  }, 0);

  return {
    [`${totalUnitsType}BuySubtotal`]: formatCurrency(buyTotal),
    [`${totalUnitsType}SellSubtotal`]: formatCurrency(sellTotal),
    [`${totalUnitsType}ApTotal`]: formatCurrency(buyTotal + apTotal),
    [`${totalUnitsType}ArTotal`]: formatCurrency(sellTotal + arTotal),
  };
};

const getTotalWeights = (
  lineItems: BuySellOrderLineItem[],
  unitsKey: 'unitsOrdered' | 'unitsShipped' | 'unitsInvoiced',
  totalUnitsType: 'ordered' | 'shipped' | 'invoiced'
) => {
  const weightTotal = lineItems.reduce((acc, lineItem) => {
    const weight = Weight.of(lineItem.product).forQuantity(lineItem[unitsKey] ?? 0);

    return acc + weight;
  }, 0);

  return {
    [`${totalUnitsType}Weight`]: formatDecimal(weightTotal),
  };
};

const getTotalUnits = (
  lineItems: BuySellOrderLineItem[],
  unitsKey: 'unitsOrdered' | 'unitsShipped' | 'unitsInvoiced',
  totalUnitsType: 'ordered' | 'shipped' | 'invoiced'
) => {
  const totalUnits = lineItems.reduce((acc, lineItem) => {
    return acc + (lineItem[unitsKey] ?? 0);
  }, 0);

  return {
    [`${totalUnitsType}Units`]: formatDecimal(totalUnits),
  };
};

const getProductAttributes = (lineItems: BuySellOrderLineItem[], apTotal: number, arTotal: number) => {
  return {
    ...getProductSubtotals(lineItems, apTotal, arTotal, 'unitsOrdered', 'ordered'),
    ...getProductSubtotals(lineItems, apTotal, arTotal, 'unitsShipped', 'shipped'),
    ...getProductSubtotals(lineItems, apTotal, arTotal, 'unitsInvoiced', 'invoiced'),
    ...getTotalWeights(lineItems, 'unitsOrdered', 'ordered'),
    ...getTotalWeights(lineItems, 'unitsShipped', 'shipped'),
    ...getTotalWeights(lineItems, 'unitsInvoiced', 'invoiced'),
    ...getTotalUnits(lineItems, 'unitsOrdered', 'ordered'),
    ...getTotalUnits(lineItems, 'unitsShipped', 'shipped'),
    ...getTotalUnits(lineItems, 'unitsInvoiced', 'invoiced'),
  };
};

export const BuySellOrderTemplate: DocumentTypePopulator<BuySellOrderInputType, {}, BuySellOrderTemplateDataType> = {
  populate: (buySellOrder, { type, templateId }, globalOptions) => {
    if (!buySellOrder) {
      return {};
    }

    const {
      slug,
      organization,
      subsidiary,
      status,
      shipment,
      currencyCode,
      lineItems,
      customFields,
      expenses,
      notes,
      broker,
      vendor,
      vendorSo,
      vendorSalesTerms,
      vendorContact,
      vendorBillTo,
      customer,
      customerPo,
      customerSalesTerms,
      customerContact,
      billTo,
      orderDate,
    } = buySellOrder;

    const { freightCompany } = shipment;

    const totalUnitsOrdered = lineItems.reduce((acc: undefined | number, lineItem) => {
      if (acc === undefined) {
        return lineItem.unitsOrdered;
      }

      return acc + (lineItem.unitsOrdered ?? 0);
    }, undefined);

    const totalUnitsShipped = lineItems.reduce((acc: undefined | number, lineItem) => {
      if (acc === undefined) {
        return lineItem.unitsShipped ?? 0;
      }

      return acc + (lineItem.unitsShipped ?? 0);
    }, undefined);

    const totalUnitsInvoiced = lineItems.reduce((acc: undefined | number, lineItem) => {
      if (acc === undefined) {
        return lineItem.unitsInvoiced ?? 0;
      }

      return acc + (lineItem.unitsInvoiced ?? 0);
    }, undefined);

    const shippedPallets = lineItems.reduce((acc: undefined | number, lineItem) => {
      if (acc === undefined) {
        return (lineItem.unitsShipped ?? 0) / (lineItem.product.cartonsPerPallet || 1);
      }

      return acc + (lineItem.unitsShipped ?? 0) / (lineItem.product.cartonsPerPallet || 1);
    }, undefined);

    const shippedWeight = lineItems.reduce((acc: undefined | number, lineItem) => {
      const weight = Weight.of(lineItem.product).forQuantity(lineItem.unitsShipped ?? 0);

      if (acc === undefined) {
        return weight;
      }

      return acc + weight;
    }, undefined);

    const apExpenses = expenses.filter((expense) => {
      const apExpense = expense.accountLevel === AccountLevel.ACCOUNTS_PAYABLE;
      const vendorExpense = expense.businessEntity?.id === vendor?.id;

      return apExpense && vendorExpense;
    });

    const arExpenses = expenses.filter((expense) => {
      const arExpense = expense.accountLevel === AccountLevel.ACCOUNTS_RECEIVABLE;
      const customerExpense = expense.businessEntity?.id === customer?.id;

      return arExpense && customerExpense;
    });

    const freightExpenses = expenses.filter((expense) => {
      const apExpense = expense.accountLevel === AccountLevel.ACCOUNTS_PAYABLE;
      const freightExpense = expense.businessEntity?.id === shipment.freightCompany?.id;

      return apExpense && freightExpense;
    });

    const apExpensesSubtotal = apExpenses.reduce((acc, expense) => {
      return acc + Money.toDollars(expense.currencyAmount ?? expense.unitAmount) * expense.quantity;
    }, 0);

    const arExpensesSubtotal = arExpenses.reduce((acc, expense) => {
      if (expense.includedInDlvPrice) {
        return acc;
      }

      return acc + Money.toDollars(expense.currencyAmount ?? expense.unitAmount) * expense.quantity;
    }, 0);

    const freightChargesSubtotal = freightExpenses.reduce((acc, expense) => {
      return acc + Money.toDollars(expense.currencyAmount ?? expense.unitAmount) * expense.quantity;
    }, 0);

    const primaryBusiness = getPrimaryBusiness(organization);

    return {
      orderNumber: slug,
      orderDate: formatDate(orderDate),

      orgLogo: organization?.logoUrl,
      organization: formatOrganizationName({
        organization,
        subsidiary,
        customOrgName: globalOptions.customOrgName,
      }),

      status,
      broker: User.formatName(broker?.user),
      phoneNumber: primaryBusiness?.phone ?? '--',
      email: broker?.user?.email ?? '--',
      fax: primaryBusiness?.faxNumber ?? '--',
      businessAddress: AddressComponent.templatize(primaryBusiness),

      vendor: vendor?.name ?? '',
      vendorSo,
      vendorContact: vendorContact?.name ?? '',
      vendorSalesTerms: SalesTermsMapper.getLabel(vendorSalesTerms),
      vendorBillToAddress: AddressComponent.templatize(vendorBillTo),

      customer: customer?.name ?? '',
      customerPo,
      customerContact: customerContact?.name ?? '',
      customerSalesTerms: SalesTermsMapper.getLabel(customerSalesTerms),
      customerBillToAddress: AddressComponent.templatize(billTo),

      shipment: {
        shipDate: formatDate(shipment.shipDate),
        deliveryDate: formatDate(shipment.deliveryDate),
        origin: AddressComponent.templatize(shipment.origin),
        destination: AddressComponent.templatize(shipment.destination),
        freightCompany: freightCompany?.name ?? '--',
        temperatureMax: shipment.temperatureMax,
        temperatureMin: shipment.temperatureMin,
        terms: shipment.terms,
        customFields: shipment.customFields,
      },

      products: productsBsoGrid(lineItems, {
        currencyCode,
      }),

      notes: documentNotes(notes, templateId, type),

      arCharges: ExpensesComponent.templatize(arExpenses, { currency: currencyCode }),
      apCharges: ExpensesComponent.templatize(apExpenses, { currency: currencyCode }),
      freightCharges: ExpensesComponent.templatize(freightExpenses, { currency: currencyCode }),

      customFields,

      // computed values
      totalUnitsOrdered: formatDecimal(totalUnitsOrdered),
      totalUnitsShipped: formatDecimal(totalUnitsShipped),
      totalUnitsInvoiced: formatDecimal(totalUnitsInvoiced),

      shippedPallets: formatDecimal(Math.ceil(shippedPallets ?? 0)),
      shippedWeight: formatDecimal(shippedWeight ?? 0),

      apChargesSubtotal: formatCurrency(apExpensesSubtotal, currencyCode),
      arChargesSubtotal: formatCurrency(arExpensesSubtotal, currencyCode),
      freightChargesSubtotal: formatCurrency(freightChargesSubtotal, currencyCode),
      ...getProductAttributes(lineItems, apExpensesSubtotal, arExpensesSubtotal),
    };
  },
};
