import useServerEnhancedGridQuery from 'src/modules/grids/serverEnhanced/useServerEnhancedGridQuery';
import { ServerEnhancedGridState } from 'src/modules/grids/serverEnhanced/useServerEnhancedGridState';

export const GET_CUSTOMER_PORTAL_INVOICES_GRID = gql`
  query GetCustomerPortalInvoicesPage($input: StandardPageInput) {
    customerPortalInvoicePage(input: $input) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          slug
          status
          invoiceDate
          shipDate
          paymentDueDate
          orderSlug
          totalPaid
          amountRemaining
          totalAmountDue
        }
        cursor
      }
    }
  }
`;

export function useCustomerPortalInvoicesGrid(gridState: ServerEnhancedGridState) {
  return useServerEnhancedGridQuery({
    queryKey: 'customerPortalInvoicePage',
    query: GET_CUSTOMER_PORTAL_INVOICES_GRID,
    gridState,
  });
}
