import { Box, Paper } from '@mui/material';

import { Metadata } from '@redwoodjs/web';

import StatusStepper, { financialEntityStatuses } from 'src/components/atoms/StatusStepper';
import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import SkeletonDataGrid from 'src/components/containers/skeleton/SkeletonDataGrid';
import SkeletonEntryForm from 'src/components/containers/skeleton/SkeletonEntryForm';
import SkeletonStepper from 'src/components/containers/skeleton/SkeletonStepper';

import useCustomerPortalPurchaseOrder from '../purchase-order/useCustomerPortalPurchaseOrder';

import DownloadInvoice from './DownloadInvoice';
import InvoiceCredits from './InvoiceCredits';
import InvoiceDetails from './InvoiceDetails';
import OrderDetails from './OrderDetails';
import OrderExpenses from './OrderExpenses';
import OrderProducts from './OrderProducts';
import useCustomerPortalInvoice from './useCustomerPortalInvoice';

export const financialStatusesSansDraft = financialEntityStatuses.filter((status) => status !== 'DRAFT');

export default function ViewCustomerPortalInvoicePage({ slug }) {
  const invoice = useCustomerPortalInvoice(slug);
  const purchaseOrder = useCustomerPortalPurchaseOrder(invoice?.orderSlug);

  return (
    <>
      <Metadata title={`Invoice #${slug}`} />
      <PageHeader pageType={PageType.CustomerPortalInvoice} entitySlug={slug}>
        <Box sx={{ flexGrow: 1 }} />
        <DownloadInvoice />
      </PageHeader>
      <PageBody sx={{ flexDirection: 'column' }}>
        {invoice ? (
          <Paper sx={{ pt: 3 }}>
            <StatusStepper allStatuses={financialStatusesSansDraft} currentStatus={invoice.status} />
            <InvoiceDetails invoice={invoice} />
            <InvoiceCredits invoice={invoice} />
          </Paper>
        ) : (
          <Skeleton stepper />
        )}
        {invoice && purchaseOrder ? (
          <Paper sx={{ pt: 3, mt: 3 }}>
            <OrderDetails purchaseOrder={purchaseOrder} />
            <OrderProducts purchaseOrder={purchaseOrder} />
            <OrderExpenses invoice={invoice} />
          </Paper>
        ) : (
          <Skeleton />
        )}
      </PageBody>
    </>
  );
}

function Skeleton({ stepper }: { stepper?: boolean }) {
  return (
    <Paper sx={{ mt: 3, p: 2 }}>
      {stepper && <SkeletonStepper allStatuses={financialStatusesSansDraft} />}
      <SkeletonEntryForm fields={6} />
      <SkeletonDataGrid columns={{ a: {}, b: {}, c: {} }} rowCount={3} storageKey="skele" />
    </Paper>
  );
}
