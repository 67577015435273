import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import algoliasearch, { SearchClient } from 'algoliasearch';
import { InvoiceStatus, LotStatus, PurchaseOrderStatus, SalesOrderStatus } from 'types/graphql';

import { useAuth } from 'src/auth';
import { LedgerEntryDetails, LedgerEntryGroup } from 'types/graphql';

/* Keep in sync with algolia.ts in /api/ */

export enum SearchIndex {
  Products = 'products',
  Lots = 'lots',
  Invoices = 'invoices',
  Orders = 'orders',
  BusinessEntities = 'businessEntities',
  Places = 'places',
  Contacts = 'contacts',
  ProductionRuns = 'productionRuns',
  LedgerEntries = 'ledgerEntries',
  LedgerAccounts = 'ledgerAccounts',
}

export type ProductIndex = {
  objectID: string;
  description: string;
  organizationId: number;
  productId: number;
  productCode: string;
};

export type PurchaseOrderIndex = {
  orderId: number;
  organizationId: number;
  slug: string;
  objectID: string;
  status: PurchaseOrderStatus;
  vendor: string;
  vendorSO: string;
  salesPerson: string;
  contact: string;
  shippedFrom: string;
};

export type SalesOrderIndex = {
  orderId: number;
  organizationId: number;
  slug: string;
  objectID: string;
  buyer: string;
  status: SalesOrderStatus;
  customerPO: string;
  salesPerson: string;
  contact: string;
  shipTo: string;
};

export type OrderReference = {
  purchaseOrderId?: number;
  salesOrderId?: number;
  buySellOrderId?: number;
  slug: string;
};

export type PalletTagReference = {
  tagNumber: string;
  tagNumbers: string[];
  palletTagId: number;
};

export type LotIndex = {
  organizationId: number;
  slug: string;
  lotId: number;
  productId: number;
  description: string;
  objectID: string;
  orders: OrderReference[];
  status: LotStatus;
  receiveDate: string;
  quantity: number;
  reserved: number;
  vendor: string;
  grower: string;
  palletTags: PalletTagReference[];
  warehouseId: number;
};

export type InvoiceIndex = {
  __type: 'Invoice' | 'BuySellOrderInvoice' | 'StandardInvoice';
  invoiceId: number;
  status: InvoiceStatus;
  objectID: string;
  slug: string;
  order: OrderReference;
};

export type BusinessEntityIndex = {
  businessEntityId: number;
  organizationId: number;
  objectID: string;
  name: string;
};

export type LedgerAccountIndex = {
  objectID: string; // aka fragment account path
  organizationId: number;
  path: string[];
  fullPath: string; // cannot facet an array
  displayPath: string[];
  number: string;
  name: string;
  type: string;
  updatedAt: number; // epoch
  currentBalance: number;
};

export type LedgerEntryIndex = LedgerEntryGroup & {
  objectID: string; // aka the fragment entry id
  accountPaths: string[];
  counterParties: string[];
  entry: LedgerEntryDetails & {
    postedAt: number;
    createdAt: number; // useful for stable sorting
  };
};

export const AlgoliaContext = createContext<SearchClient>(undefined);

export default function AlgoliaContextProvider({ children }: PropsWithChildren) {
  const { currentUser } = useAuth();
  const algolia = useMemo(() => {
    if (currentUser) {
      return algoliasearch(process.env.ALGOLIA_CLIENT_ID, currentUser.searchKey as string);
    }
  }, [currentUser]);

  return <AlgoliaContext.Provider value={algolia}>{children}</AlgoliaContext.Provider>;
}

export const useAlgolia = () => useContext(AlgoliaContext);
