import { useCallback, useMemo, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid-premium';
import { useDebounce } from 'usehooks-ts';

import { GridToolbarQuickFilter } from 'src/modules/grids/toolbar/GridToolbarV2';

import { ServerGridFilter } from './types';

export type ServerEnhancedGridState = {
  first: number;
  after?: number;
  sortItems: GridSortModel;
  gridFilters: ServerGridFilter[];
  searchText?: string;
  quickFilterOption?: GridToolbarQuickFilter;
  selectedRows: Record<string, unknown>;
  initialState?: {
    gridFilters?: ServerGridFilter[];
    quickFilter?: string;
    pagination?: { paginationModel: { pageSize: number } };
  };
};

export type ServerEnhancedGridStateWithHelpers = ServerEnhancedGridState & {
  quickFilterOptions?: GridToolbarQuickFilter[];
  forceRefetch: () => void;
  resetToDefault: () => void;
  setSearch: (value?: string) => void;
  setQuickFilterOption: (filters: GridToolbarQuickFilter) => void;
  updateFilters: (filters: ServerGridFilter[]) => void;
  gridUpdate: (sortItems: GridSortModel, first: number, after?: number) => void;
  updateSelectedRows: (selectedRows: Record<string, unknown>) => void;
};

export function useServerEnhancedGridState(
  storageKey: string,
  quickFilterOptions?: GridToolbarQuickFilter[],
  initialState = null
): ServerEnhancedGridStateWithHelpers {
  const cachedState = useMemo(() => {
    const stateJSON = localStorage.getItem(`${storageKey}-column-state`);
    if (!stateJSON) return initialState;
    const state = JSON.parse(stateJSON);
    const { serverFilters: gridFilters, quickFilter, pagination } = state;
    return { gridFilters, quickFilter, pagination };
  }, [storageKey]);

  const [serverEnhancedGridState, setServerEnhancedGridState] = useState<ServerEnhancedGridState>({
    first: cachedState?.pagination?.paginationModel?.pageSize ?? 25,
    after: undefined,
    sortItems: [],
    gridFilters: cachedState?.gridFilters || [],
    searchText: undefined,
    quickFilterOption:
      (cachedState?.quickFilter && quickFilterOptions?.find((o) => o.label === cachedState.quickFilter)) ||
      quickFilterOptions?.[0],
    selectedRows: {},
  });

  const setSearch = useCallback((value?: string) => {
    setServerEnhancedGridState((prev) => ({
      ...prev,
      searchText: value,
    }));
  }, []);

  const forceRefetch = useCallback(() => {
    setServerEnhancedGridState((prev) => ({ ...prev }));
  }, []);

  const setQuickFilterOption = useCallback((quickFilterOption: GridToolbarQuickFilter) => {
    setServerEnhancedGridState((prev) => ({ ...prev, quickFilterOption }));
  }, []);

  const updateFilters = useCallback((filters: ServerGridFilter[]) => {
    setServerEnhancedGridState((prev) => ({ ...prev, gridFilters: filters }));
  }, []);

  const gridUpdate = useCallback((sortItems: GridSortModel, first: number, after?: number) => {
    setServerEnhancedGridState((prev) => ({ ...prev, sortItems, first, after }));
  }, []);

  const updateSelectedRows = useCallback((selectedRows: Record<string, unknown>) => {
    setServerEnhancedGridState((prev) => ({ ...prev, selectedRows }));
  }, []);

  const resetToDefault = useCallback(() => {
    setServerEnhancedGridState((prev) => ({
      ...prev,
      gridFilters: [],
      searchText: undefined,
      quickFilterOption: quickFilterOptions?.[0],
    }));
  }, [quickFilterOptions]);

  // This prevents the grid from making too many requests
  const debouncedState = useDebounce(serverEnhancedGridState, 250);

  return useMemo(
    () => ({
      ...debouncedState,
      gridUpdate,
      setSearch,
      forceRefetch,
      setQuickFilterOption,
      updateFilters,
      quickFilterOptions,
      resetToDefault,
      updateSelectedRows,
    }),
    [
      gridUpdate,
      setSearch,
      forceRefetch,
      setQuickFilterOption,
      quickFilterOptions,
      debouncedState,
      updateFilters,
      resetToDefault,
      updateSelectedRows,
    ]
  );
}
