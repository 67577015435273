import { lens, withLenses } from '@dhmk/zustand-lens';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { useMutation } from '@redwoodjs/web';

import { createUseStoreState } from 'src/utils/createUseStoreState';

export type ViewConfigState = {
  config: Record<string, unknown>;
  dirty: boolean;
};

export type ViewConfigActions = {
  _setConfig: (config: Record<string, unknown>) => void;
};

export type ViewConfigStore = ViewConfigState & ViewConfigActions;

export enum VIEW_PAGES {
  INVENTORY = 'inventory',
  PURCHASE_ORDERS = 'purchase-orders-v2',
  SALES_ORDERS = 'sales-orders-v2',
  GROWER_PRODUCTS = 'grower-products-v2',
  PICK_TICKETS = 'pick-tickets-v2',
  RECEIVING_TICKETS = 'receiving-tickets-v2',
  PRODUCTION_RUNS = 'production-runs-v2',
  ACCOUNT_REGISTER = 'account-register',
  CUSTOMER_PORTAL_PUCHASE_ORDERS = 'customer-portal-purchase-orders',
  CUSTOMER_PORTAL_INVOICES = 'customer-portal-invoices',
}

const CREATE_VIEW_CONFIG = gql`
  mutation CreateViewConfig($input: CreateViewConfigurationInput!) {
    createViewConfiguration(input: $input) {
      id
      name
      config
    }
  }
`;
const UPDATE_VIEW_CONFIG = gql`
  mutation UpdateViewConfig($id: Int!, $input: UpdateViewConfigurationInput!) {
    updateViewConfiguration(id: $id, input: $input) {
      id
    }
  }
`;

const DELETE_VIEW_CONFIG = gql`
  mutation DeleteViewConfig($id: Int!) {
    deleteViewConfiguration(id: $id) {
      id
    }
  }
`;

export const QUERY_VIEW_CONFIGS = gql`
  query FindViewConfigurations($page: String!) {
    viewConfigurations(page: $page) {
      id
      name
      config
    }
  }
`;

export const persistViewConfig = {
  version: 1,
  migrate: (state, version) => {
    if (!state) {
      return;
    }

    // move state into its namespace
    if (version === 0) {
      state.view = {
        ...state,
      };
    }

    return state;
  },
  merge: (persistedState, currentState) => {
    if (!persistedState) {
      return currentState;
    }

    return {
      ...currentState,
      ...persistedState,
      view: {
        ...currentState.view,
        ...persistedState.view,
      },
    };
  },
};

export const createViewConfigStore = (storageKey: string) =>
  persist(immer(withLenses({ view: lens((set) => createViewConfigSlice(set)) })), {
    name: `view-config-${storageKey}`,
    ...persistViewConfig,
  });

export const createViewConfigSlice = (set): ViewConfigStore => ({
  config: null,
  dirty: false,
  _setConfig: (config) =>
    set((state) => {
      state.view = config;
    }),
});

export const useViewConfigActions = () => {
  const [createViewConfigMutation] = useMutation(CREATE_VIEW_CONFIG);
  const [updateViewConfigMutation] = useMutation(UPDATE_VIEW_CONFIG);
  const [deleteViewConfigMutation] = useMutation(DELETE_VIEW_CONFIG);

  const _setConfig = useViewConfigStore((store) => store._setConfig) as ViewConfigActions['_setConfig'];

  const createViewConfig = (input) => {
    return createViewConfigMutation({
      variables: {
        input,
      },
    });
  };

  const updateViewConfig = ({ id, config, name }: { id: string; config?: Record<string, unknown>; name?: string }) => {
    const input: any = {};
    if (config) input.config = config;
    if (name) input.name = name;

    return updateViewConfigMutation({ variables: { id, input } });
  };

  const deleteViewConfig = ({ id }) => {
    return deleteViewConfigMutation({
      variables: {
        id,
      },
    });
  };

  const setConfig = (config) => {
    _setConfig(config);
  };

  return { setConfig, createViewConfig, updateViewConfig, deleteViewConfig };
};

export const useViewConfigStore = createUseStoreState<ViewConfigStore>('view');

/**
 * Only saves the necessary parts of the data grid state to the database.
 */
export const dataGridStateToConfig = (state) => {
  const config = {
    aggregation: state.aggregation,
    columns: {
      columnVisibilityModel: state.columns.columnVisibilityModel,
      orderedFields: state.columns.orderedFields,
    },
    filter: state.filter,
    pinnedColumns: state.pinnedColumns,
    rowGrouping: state.rowGrouping,
    sorting: state.sorting,
  };
  Object.keys(config).forEach((key) => {
    if (!config[key]) delete config[key];
  });
  return config;
};

export const emptyGridState = {
  aggregation: null,
  columns: {
    columnVisibilityModel: {},
    orderedFields: [],
  },
  filter: null,
  pinnedColumns: [],
  rowGrouping: null,
  sorting: null,
  serverFilters: [],
};

/**
 * Helper function to get the column order from the view config.
 * (This is helpful for only basic data grids - Data grid with
 * custom config settings will likely need to use their own selector)
 * @returns {string[]} The column order.
 */
export const useViewColumnOrder = () => {
  return useViewConfigStore((store) => store.view?.config?.grid?.columns?.orderedFields) as string[];
};
