import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { GRID_AGGREGATION_FUNCTIONS } from '@mui/x-data-grid-premium';
import Money from 'shared/utils/Money';

import BoldHeader from 'src/components/atoms/grid/BoldHeader';
import Section from 'src/components/atoms/Section';
import DataGrid, { GridColObj } from 'src/components/containers/grids/DataGrid';
import { totalsLabelAggregation } from 'src/utils/grid/column/columnAggregation';

import { CustomerPortalPurchaseOrderData } from '../purchase-order/useCustomerPortalPurchaseOrder';
import useCustomerPortalProducts from '../useCustomerPortalProducts';

type Props = {
  purchaseOrder: CustomerPortalPurchaseOrderData;
};

const productColumns: GridColObj = {
  product: {
    headerName: 'Product',
    flex: 1,
    renderHeader: BoldHeader,
    valueGetter: (value) => value?.label,
    renderCell: ({ value, rowNode }) => {
      if (rowNode.isAutoGenerated) return <Typography variant="overline">{value}</Typography>;
      return value;
    },
  },
  unitsOrdered: {
    headerName: 'Units Ordered',
    type: 'number',
    flex: 1,
    renderHeader: BoldHeader,
  },
  unitsShipped: {
    headerName: 'Units Shipped',
    type: 'number',
    flex: 1,
    valueGetter: (value) => value ?? 0,
    renderHeader: BoldHeader,
  },
  totalPrice: {
    headerName: 'Total Amount',
    type: 'number',
    flex: 1,
    renderHeader: BoldHeader,
    valueGetter: (_, row) => row.unitsOrdered * row.sellPrice,
    valueFormatter: (value) => Money.toHuman(value),
  },
};

export default function OrderProducts({ purchaseOrder }: Props) {
  const products = useCustomerPortalProducts(purchaseOrder.id);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(purchaseOrder.lineItems.map((li) => ({ ...li, product: products.find((p) => p.id === li.productId) })));
  }, [products, purchaseOrder.lineItems]);

  return (
    <Section title="Products" sx={{ mt: 3 }}>
      <DataGrid
        rows={rows}
        columns={productColumns}
        storageKey="invoice-order-products"
        variant="list"
        disableAggregation={rows.length < 2}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          totals: totalsLabelAggregation,
        }}
        initialState={{
          aggregation: {
            model: {
              product: 'totals',
              unitsOrdered: 'sum',
              unitsShipped: 'sum',
              totalPrice: 'sum',
            },
          },
        }}
      />
    </Section>
  );
}
