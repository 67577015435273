export type MemoReason = {
  code: string;
  label: string;
};

export const memoReasons: MemoReason[] = [
  { code: 'bad_product', label: 'Bad Product' },
  { code: 'wrong_product', label: 'Wrong Product' },
  { code: 'shipping_exception', label: 'Shipping Exception' },
  { code: 'non_payment', label: 'Non-payment' },
  { code: 'other', label: 'Other' },
];
