import { useQuery } from '@redwoodjs/web';

export type CustomerPortalInvoice = {
  id: string;
  slug: string;
  status: string;
  customerName: string;
  salesTerms: string;
  invoiceDate: string;
  paymentDueDate: string;
  totalAmountDue: number;
  totalPaid: number;
  amountRemaining: number;
  orderSlug: string;
  receiptAdjustments: Array<{
    id: number;
    type: string;
    amount: number;
    reason: string;
    memo: string;
  }>;
  expenses: Array<{
    id: number;
    category: string;
    quantity: number;
    description: string;
    totalAmount: number;
  }>;
};

export const GET_CUSTOMER_PORTAL_INVOICE = gql`
  query GetCustomerPortalInvoice($slug: String!) {
    customerPortalInvoice(slug: $slug) {
      id
      slug
      status
      customerName
      salesTerms
      invoiceDate
      paymentDueDate
      totalAmountDue
      totalPaid
      amountRemaining
      orderSlug
      receiptAdjustments {
        id
        type
        reason
        amount
        memo
      }
      expenses {
        id
        category
        quantity
        description
        totalAmount
        includedInDlvPrice
      }
    }
  }
`;

export default function useCustomerPortalInvoice(slug: string) {
  const { data } = useQuery<{ customerPortalInvoice: CustomerPortalInvoice }>(GET_CUSTOMER_PORTAL_INVOICE, {
    variables: { slug },
  });
  return data?.customerPortalInvoice;
}
