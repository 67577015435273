import { useQuery } from '@redwoodjs/web';

export const GET_CUSTOMER_PORTAL_PURCHASE_ORDER = gql`
  query GetCustomerPortalPurchaseOrder($slug: String!) {
    customerPortalPurchaseOrder(slug: $slug) {
      id
      slug
      status
      orderDate
      salesTerms
      billToId
      businessEntity {
        name
      }
      shipment {
        destinationId
      }
      lineItems {
        id
        productId
        unitsOrdered
        unitsShipped
        sellPrice
      }
    }
  }
`;

export type CustomerPortalPurchaseOrderData = {
  id: number;
  status: string;
  orderDate: string;
  salesTerms?: string;
  businessEntity: {
    name: string;
  };
  billToId?: number;
  shipment?: {
    destinationId: number;
  };
  lineItems: Array<{
    id: number;
    productId: number;
    unitsOrdered: number;
    // these are only used for read-only views, the sell price is calculated based on pricing sheets during the order creation
    unitsShipped?: number;
    sellPrice?: number;
  }>;
};

export default function useCustomerPortalPurchaseOrder(slug?: string) {
  const { data } = useQuery<{ customerPortalPurchaseOrder: CustomerPortalPurchaseOrderData }>(
    GET_CUSTOMER_PORTAL_PURCHASE_ORDER,
    {
      variables: { slug },
      skip: !slug,
    }
  );

  return data?.customerPortalPurchaseOrder;
}
