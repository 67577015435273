import type { SxProps, Theme } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-premium';

// d for descendant, idk i just wanted it to be short
const d = (cls: string) => `& .${cls}`;

export const baseGridStyles = (variant: 'list' | 'grid' = 'grid'): SxProps<Theme> => ({
  border: 'none',
  outline: 'none',
  bgcolor: 'white',
  overflow: 'visible',

  [d(gridClasses.main)]: {
    borderRadius: '4px',
    display: 'grid',
    outline: '1px solid #E0E0E0',
  },

  [d(gridClasses.columnHeaders)]: {
    bgcolor: 'grey.100',
  },

  [d(gridClasses.columnHeader)]: {
    bgcolor: variant === 'list' ? '#EFF0ED' : 'grey.100',
    borderColor: 'grey.300',
    '&:last-child': {
      borderRight: 'none',
    },
    '&:focus-within, &:focus': {
      outline: 'none',
    },
    '& p': {
      fontWeight: 700,
      letterSpacing: '0.17px',
    },
    [d(gridClasses.columnHeaderTitle)]: {
      letterSpacing: '0.17px',
      fontWeight: 700,
    },
    '&[data-field="__tree_data_group__"]': {
      borderRight: 0,
    },
  },

  [d(gridClasses.columnHeaderTitle)]: {
    fontWeight: 700,
  },

  [d(gridClasses['row--editing'])]: {
    boxShadow: 'none',
  },

  [d(gridClasses.row)]: {
    // styling for editable rows
    [`&.${gridClasses['row--editable']}`]: {
      '&:hover': {
        [d(gridClasses.cell)]: {
          bgcolor: 'primary.50',
          [`&.${gridClasses['cell--editing']}`]: {
            bgcolor: 'white',
          },
        },
      },
      [d(gridClasses.cell)]: {
        bgcolor: 'white',
        [`&:not(.${gridClasses['cell--editable']})`]: {
          bgcolor: 'grey.25',
        },
      },
    },

    // styling for non editable rows
    [`&:not(.${gridClasses['row--editable']})`]: {
      [d(gridClasses.cell)]: {
        bgcolor: 'white',
      },
      '&:hover': {
        [d(gridClasses.cell)]: {
          bgcolor: 'primary.50',
        },
      },
    },
  },

  [d(gridClasses.cell)]: {
    display: 'flex',
    alignItems: 'center',
    borderColor: 'grey.300',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:last-of-type': {
      borderRight: 'none',
    },
    '&:focus-within, &:focus': {
      outline: 'none',
    },
    '&[data-field="__tree_data_group__"]': {
      bgcolor: 'white',
      borderRight: 0,
    },
    '&[data-field="actions"]': {
      bgcolor: 'white',
    },
    '&.white': {
      bgcolor: 'white',
    },
    ...(variant === 'list'
      ? { borderRight: 0, borderLeft: 0 }
      : {
          borderRight: '1px solid #E0E0E0',
        }),
  },

  [d(gridClasses.pinnedRows)]: {
    boxShadow: 'none',
    borderTop: '1px solid',
    borderColor: 'grey.300',

    [d(gridClasses.cell)]: {
      bgcolor: 'white',
      fontWeight: 700,
    },
  },

  [d(gridClasses['row--dynamicHeight'])]: {
    minHeight: '48px !important',
  },

  [d(gridClasses.aggregationColumnHeaderLabel)]: {
    display: 'none',
  },

  '@media print': {
    [d(gridClasses.footerContainer)]: {
      display: 'none',
    },
  },

  '& .AutoCompleteCell-edit': {
    padding: '0 !important',
  },
});
