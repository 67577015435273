import { useOrganization } from '@clerk/clerk-react';
import { AppBar, Button, Divider, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAuth, useIsCustomer, useIsMember } from 'src/auth';
import { useIsSmallScreen } from 'src/hooks/screen-sizes';

import TransformedLink from '../atoms/TransformedLink';

import NotificationMenu from './menus/NotificationMenu';
import { UserMenu } from './menus/UserMenu';
import { SearchBar } from './SearchBar';

export const GLOBAL_APP_BAR_HEIGHT = 80;

export default function MainAppBar() {
  const theme = useTheme();
  const flags = useFlags();
  const isSmallScreen = useIsSmallScreen();
  const isMember = useIsMember();
  const isCustomer = useIsCustomer();
  const { currentUser } = useAuth();
  const { organization, isLoaded: orgLoaded } = useOrganization();

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.primary.dark,
        zIndex: theme.zIndex.drawer + 1,
        height: GLOBAL_APP_BAR_HEIGHT,
        display: 'flex',
        boxShadow:
          '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);',
      }}
    >
      <Box className="flex pl-4 pr-3 items-center my-auto" sx={{ gap: isSmallScreen ? 2 : 3 }}>
        <Button className="cursor-pointer flex items-center -ml-3" href="/" LinkComponent={TransformedLink}>
          <img className="h-[30px]" src={isSmallScreen ? '/icon_light.svg' : '/logo_light.svg'} alt="Agriful" />
        </Button>
        {isCustomer && orgLoaded && (
          <>
            <Divider orientation="vertical" sx={{ borderColor: 'primary.400', maxHeight: '24px' }} />
            {organization.imageUrl && (
              <Box mr={-1}>
                <img src={organization.imageUrl} width="auto" style={{ maxHeight: '72px' }} alt="" />
              </Box>
            )}
            <Typography variant="h6" sx={{ color: 'primary.100' }}>
              {organization.name}
            </Typography>
          </>
        )}
        {isMember && <SearchBar />}
        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: 'flex', gap: '8px' }}>
          {flags.notifications && <NotificationMenu />}
          {!!currentUser && <UserMenu />}
        </Box>
      </Box>
    </AppBar>
  );
}
