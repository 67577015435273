import { useCallback } from 'react';

import { enqueueSnackbar } from 'notistack';
import {
  UpdateCustomerPortalPurchaseOrderInput,
  CustomerPortalLineItemInput,
  CreateCustomerPortalPurchaseOrderInput,
} from 'types/graphql';

import { useMutation } from '@redwoodjs/web';

import { useDebounce } from 'src/hooks/useDebounce';

import { GET_CUSTOMER_PORTAL_PURCHASE_ORDER } from './purchase-order/useCustomerPortalPurchaseOrder';
import { GET_CUSTOMER_PORTAL_PURCHASE_ORDERS_GRID } from './purchase-orders/useCustomerPortalPurchaseOrdersGrid';

export const CREATE_CUSTOMER_PORTAL_PURCHASAE_ORDER = gql`
  mutation CreateCustomerPortalPurchaseOrder($input: CreateCustomerPortalPurchaseOrderInput) {
    createCustomerPortalPurchaseOrder(input: $input)
  }
`;

export const UPDATE_CUSTOMER_PORTAL_PURCHASAE_ORDER = gql`
  mutation UpdateCustomerPortalPurchaseOrder($id: Int!, $input: UpdateCustomerPortalPurchaseOrderInput!) {
    updateCustomerPortalPurchaseOrder(id: $id, input: $input) {
      status
    }
  }
`;

export const SUBMIT_CUSTOMER_PORTAL_PURCHASAE_ORDER = gql`
  mutation SubmitCustomerPortalPurchaseOrder($id: Int!) {
    submitCustomerPortalPurchaseOrder(id: $id) {
      status
    }
  }
`;

export const CREATE_CUSTOMER_PORTAL_LINE_ITEM = gql`
  mutation CreateCustomerPortalLineItem($orderId: Int!, $input: CustomerPortalLineItemInput!) {
    createCustomerPortalLineItem(orderId: $orderId, input: $input) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_PORTAL_LINE_ITEM = gql`
  mutation UpdateCustomerPortalLineItem($lineItemId: Int!, $input: CustomerPortalLineItemInput!) {
    updateCustomerPortalLineItem(lineItemId: $lineItemId, input: $input) {
      id
    }
  }
`;

export const DELETE_CUSTOMER_PORTAL_LINE_ITEM = gql`
  mutation DeleteCustomerPortalLineItem($lineItemId: Int!) {
    deleteCustomerPortalLineItem(lineItemId: $lineItemId)
  }
`;

export default function useCustomerPortalApi() {
  const [createPOMutation] = useMutation(CREATE_CUSTOMER_PORTAL_PURCHASAE_ORDER);
  const [updatePOMutation] = useMutation(UPDATE_CUSTOMER_PORTAL_PURCHASAE_ORDER);
  const [submitPOMutation] = useMutation(SUBMIT_CUSTOMER_PORTAL_PURCHASAE_ORDER);
  const [createLIMutation] = useMutation(CREATE_CUSTOMER_PORTAL_LINE_ITEM);
  const [updateLIMutation] = useMutation(UPDATE_CUSTOMER_PORTAL_LINE_ITEM);
  const [deleteLIMutation] = useMutation(DELETE_CUSTOMER_PORTAL_LINE_ITEM);

  const createPurchaseOrder = useCallback(
    async (input?: CreateCustomerPortalPurchaseOrderInput) => {
      const response = await createPOMutation({
        refetchQueries: [GET_CUSTOMER_PORTAL_PURCHASE_ORDERS_GRID],
        variables: { input },
      });
      return response.data?.createCustomerPortalPurchaseOrder;
    },
    [createPOMutation]
  );

  const updatePurchaseOrder = useDebounce(async (id: number, input: UpdateCustomerPortalPurchaseOrderInput) => {
    const response = await updatePOMutation({
      variables: { id, input },
    });
    return response.data?.updateCustomerPortalPurchaseOrder;
  }, 500);

  const submitPurchaseOrder = useCallback(
    async (id: number) => {
      const response = await submitPOMutation({
        variables: { id },
        refetchQueries: [GET_CUSTOMER_PORTAL_PURCHASE_ORDER, GET_CUSTOMER_PORTAL_PURCHASE_ORDERS_GRID],
        onCompleted: () => {
          enqueueSnackbar('Order submitted successfully', { variant: 'success' });
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        },
      });
      return response.data?.submitCustomerPortalPurchaseOrder;
    },
    [submitPOMutation]
  );

  const createLineItem = useCallback(
    async (orderId: number, input: CustomerPortalLineItemInput) => {
      const response = await createLIMutation({ variables: { orderId, input } });
      return response.data?.createCustomerPortalLineItem;
    },
    [createLIMutation]
  );

  const updateLineItem = useCallback(
    async (lineItemId: number, input: CustomerPortalLineItemInput) => {
      const response = await updateLIMutation({ variables: { lineItemId, input } });
      return response.data?.updateCustomerPortalLineItem;
    },
    [updateLIMutation]
  );

  const deleteLineItem = useCallback(
    async (lineItemId: number) => await deleteLIMutation({ variables: { lineItemId } }),
    [deleteLIMutation]
  );

  return {
    createPurchaseOrder,
    updatePurchaseOrder,
    submitPurchaseOrder,
    createLineItem,
    updateLineItem,
    deleteLineItem,
  };
}
