import { PropsWithChildren, createContext, useContext, useRef } from 'react';

import { GridCellParams, GridRowId } from '@mui/x-data-grid-premium';
import { StateCreator, StoreApi, createStore, useStore } from 'zustand';

type GridKeyExpansionObj = Partial<Record<'string' | 'number', boolean>>;
interface GridCoreState {
  hoveredRowId?: string;
  hoveredField?: string;
  setHoveredRowId: (gridId: string) => void;
  expandedGroups: GridKeyExpansionObj;
  setExpandedGroups: (groups: GridKeyExpansionObj) => void;
  setGroupExpanded: (group: 'string' | 'number', value: boolean) => void;
  /**
   * This row id is used by `getRowHeight` to determine whether to set the "Show More" row height to 'auto'
   */
  showMoreRowId: GridRowId;
  isHoveringCell: (cellParams: GridCellParams) => boolean;
  toggleShowMore: (rowId: GridRowId) => void;
}

const initializeGridCoreStore: StateCreator<GridCoreState> = (set, get) => ({
  hoveredRowId: null,
  setHoveredRowId: (gridId) => set({ hoveredRowId: gridId }),
  setHoveredField: (field) => set({ hoveredField: field }),
  horveredField: null,
  expandedGroups: {},
  showMoreRowId: null,
  setExpandedGroups: (groups) => set({ expandedGroups: groups }),
  setGroupExpanded: (group, shouldExpand) =>
    set((state) => {
      return {
        expandedGroups: { ...state.expandedGroups, [group]: shouldExpand },
      };
    }),
  isHoveringCell: (cellParams) => cellParams.id === get().hoveredRowId && cellParams.field === get().hoveredField,
  toggleShowMore: (rowId) => {
    set((state) => {
      return {
        showMoreRowId: state.showMoreRowId === rowId ? null : rowId,
      };
    });
  },
});

const GridCoreContext = createContext<StoreApi<GridCoreState> | null>(null);

export default function GridCoreProvider({ children }: PropsWithChildren) {
  const storeRef = useRef(createStore(initializeGridCoreStore));

  return <GridCoreContext.Provider value={storeRef.current}>{children}</GridCoreContext.Provider>;
}

export function useGridCore<T>(selector: (state: GridCoreState) => T): T {
  const store = useContext(GridCoreContext);

  if (!store) {
    throw new Error('Missing GridCoreProvider');
  }

  return useStore(store, selector);
}
