import { Typography } from '@mui/material';
import { GRID_AGGREGATION_FUNCTIONS } from '@mui/x-data-grid-premium';
import Money from 'shared/utils/Money';

import BoldHeader from 'src/components/atoms/grid/BoldHeader';
import Section from 'src/components/atoms/Section';
import DataGrid, { GridColObj } from 'src/components/containers/grids/DataGrid';
import { totalsLabelAggregation } from 'src/utils/grid/column/columnAggregation';

import { CustomerPortalInvoice } from './useCustomerPortalInvoice';

type Props = {
  invoice: CustomerPortalInvoice;
};

const expenseColumns: GridColObj = {
  category: {
    headerName: 'Category',
    flex: 1,
    renderHeader: BoldHeader,
    renderCell: ({ value, rowNode }) => {
      if (rowNode.isAutoGenerated) return <Typography variant="overline">{value}</Typography>;
      return value;
    },
  },
  quantity: {
    headerName: 'Qty.',
    type: 'number',
    flex: 1,
    renderHeader: BoldHeader,
  },
  description: {
    headerName: 'Description',
    flex: 1,
    renderHeader: BoldHeader,
  },
  totalAmount: {
    headerName: 'Total Amount',
    type: 'number',
    flex: 1,
    renderHeader: BoldHeader,
    valueGetter: (value, row) => (row.includedInDlvPrice ? 0 : value),
    renderCell: ({ row, value, rowNode }) => {
      if (rowNode.isAutoGenerated) return Money.toHuman(value);
      return <div className={row.includedInDlvPrice ? 'line-through' : ''}>{Money.toHuman(row.totalAmount)}</div>;
    },
  },
};

export default function OrderExpenses({ invoice }: Props) {
  return (
    <Section title="Expenses">
      <DataGrid
        rows={invoice.expenses}
        columns={expenseColumns}
        storageKey="invoice-order-expenses"
        variant="list"
        disableAggregation={invoice.expenses.length < 2}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          totals: totalsLabelAggregation,
        }}
        initialState={{
          aggregation: {
            model: {
              category: 'totals',
              quantity: 'sum',
              totalAmount: 'sum',
            },
          },
        }}
      />
    </Section>
  );
}
