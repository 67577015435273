import { useEffect, useMemo } from 'react';

import { Warning } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { dateSort, fromUTC, parseDateTime, startOfDay } from 'shared/utils/DateTime';

import useSystemUnitRounding from 'src/hooks/useSystemUnitRounding';
import { LotIndex, SearchIndex } from 'src/providers/AlgoliaProvider';
import fuzzyComplete from 'src/utils/fuzzyComplete';

import UnderlinedMatch from '../UnderlinedMatch';

import SearchCell from './SearchCell';

// commodity might be string if the product has been changed
// (this means the product is an algolia result, rather than a processed line item/lot)
const getCommodityFromProduct = (commodity: string | { name: string }) => {
  return typeof commodity === 'string' ? commodity : commodity?.name;
};

const getCommodityFromLot = (lot) => {
  return lot?.commodity ?? lot?.product?.commodity?.name;
};

/*
  Returns a map of the lots and their totals.
*/
export const getLotTotals = (productRows, targetRow) => {
  return productRows?.reduce((lotMap, row) => {
    if (row.id === targetRow.id) return lotMap;

    if (row.lots?.length) {
      const lotUnitsTotals = row.lots.reduce((lotMap, lot) => {
        const currentTotal = lotMap[lot.slug] ?? 0;
        const childRowUnitsPicked = lot.unitsPicked ?? 0;

        return {
          ...lotMap,
          [lot.slug]: currentTotal + childRowUnitsPicked,
        };
      }, lotMap);

      return lotUnitsTotals;
    }

    const lotSlug = row.lot?.slug;
    const currentTotal = lotMap[lotSlug] ?? 0;
    const unitsRequired = row.unitsPicked ?? row.unitsOrdered;

    return {
      ...lotMap,
      [lotSlug]: currentTotal + unitsRequired,
    };
  }, {});
};

export default function EditLotCell(
  params: GridRenderEditCellParams & {
    warehouseId: number;
    productsRef?: React.MutableRefObject<any>;
    clearOnChange?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
  }
) {
  const apiRef = useGridApiContext();

  const product = params.row.product ?? params.row.orderedProduct;
  const unitsForRow = params.row.unitsPicked ?? params.row.unitsOrdered;
  const products = params.productsRef?.current;

  const flags = useFlags();

  const lotTotals = useMemo(() => {
    return getLotTotals(products, params.row);
  }, [products, unitsForRow]);

  const productCommodity = getCommodityFromProduct(product?.commodity);
  const lotCommodity = getCommodityFromLot(params.row.lot);

  useEffect(() => {
    if (lotCommodity !== productCommodity && params.clearOnChange) {
      console.debug(
        `Lot commodity (${lotCommodity}) and row commodity (${productCommodity}) are mismatching. Clearing lot.`
      );

      apiRef?.current.setEditCellValue({
        id: params.id,
        field: params.field,
        value: null,
      });
    }
  }, [lotCommodity, apiRef, productCommodity, params.id]);

  let algoliaFilter = `warehouseId:${params.warehouseId} AND (status:OPEN OR status:MID_PRODUCTION_RUN OR status:MID_RECEIVING)`;

  if (productCommodity) {
    algoliaFilter += ` AND commodity:"${productCommodity}"`;
  }

  if (product?.countryOfOrigin) {
    algoliaFilter += ` AND countryOfOrigin:${product.countryOfOrigin}`;
  }

  const unitRounding = useSystemUnitRounding();

  return (
    <>
      {/* Filters Algolia results to only `orderedCommodity` */}

      <SearchCell<LotIndex>
        {...params}
        filters={algoliaFilter}
        index={SearchIndex.Lots}
        value={params.value}
        transform={(value) => {
          if (!value) {
            return null;
          }

          return (
            value && {
              id: value.lotId,
              slug: value.slug,
              quantity: unitRounding(value.quantity),
              receiveDate: value.receiveDate,
              vendor: value.vendor,
              grower: value.grower,
              palletTags: value.palletTags,
              warehouseId: value.warehouseId,
            }
          );
        }}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value?.id;
        }}
        filterOptions={fuzzyComplete(['slug', 'description', 'palletTags.*.tagNumber'], {
          baseSort: dateSort,
          filter: (options) => {
            if (flags['disableLotSelectionFiltering']) {
              return options;
            }

            return options.filter((option) => {
              return lotTotals?.[option.slug] === undefined || option.slug === params.value?.slug;
            });
          },
        })}
        textFieldProps={{
          sx: {
            overflow: 'hidden',
            scrollX: 'auto',
            wrap: 'nowrap',
          },
          inputRef: params.inputRef,
        }}
        getOptionLabel={(option) => option?.slug ?? ''}
        details={(option, inputValue) => {
          const sourcedFrom = option.vendor ? 'Vendor' : 'Grower';

          const unitsRemaining = option.quantity - (lotTotals?.[option.slug] ?? 0) - unitsForRow;

          const notEnoughUnits = unitsRemaining < 0;
          const isLotMidProductionRun = option.status === 'MID_PRODUCTION_RUN';
          const isLotMidReceiving = option.status === 'MID_RECEIVING';

          const availableColor = notEnoughUnits ? 'error.main' : 'text.secondary';

          const receiveDate = parseDateTime(option.receiveDate);

          return (
            <>
              <Typography variant="subtitle2" color="text.primary">
                <UnderlinedMatch target={option.description} input={inputValue} />
              </Typography>
              <Typography
                variant="subtitle2"
                color={availableColor}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Available: {option.quantity}, Reserved: {option.reserved}
              </Typography>

              {isLotMidProductionRun ||
                (isLotMidReceiving && (
                  <Typography
                    variant="subtitle2"
                    color="error.main"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Warning fontSize="inherit" /> This lot is mid{' '}
                    {isLotMidProductionRun ? 'production run' : 'receiving'}.
                  </Typography>
                ))}

              {!isLotMidProductionRun && !isLotMidReceiving && notEnoughUnits && (
                <Typography
                  variant="subtitle2"
                  color="error.main"
                  fontWeight={900}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                  }}
                >
                  <Warning fontSize="inherit" />
                  Lot will be overdrawn if picked! ({unitsRemaining})
                </Typography>
              )}

              <Typography variant="subtitle2" color="text.secondary">
                Age: {receiveDate ? startOfDay().diff(receiveDate, 'days').days.toFixed() : '--'}
                {option.grade && `, QC Grade: ${option.grade}`}
              </Typography>
              {option.repackDate && (
                <Typography variant="subtitle2" color="text.secondary">
                  Repack Date: {fromUTC(option.repackDate).toFormat('MM/dd/yyyy')}
                </Typography>
              )}
              <Typography variant="subtitle2" color="text.secondary">
                {sourcedFrom}: {option.vendor || option.grower}
              </Typography>
              <MatchingPalletTags palletTags={option.palletTags} inputValue={inputValue} />
            </>
          );
        }}
        onChange={(e, value) => {
          apiRef?.current.forceUpdate();

          if (value?.id === params.value?.id) return;

          apiRef?.current.setEditCellValue({
            id: params.id,
            field: 'palletTagsOnLineItem',
            value: [],
          });
        }}
      />
    </>
  );
}

const MatchingPalletTags = ({ palletTags, inputValue }) => {
  const matchingTags = useMemo(() => {
    return palletTags
      ?.map((pt) => {
        const matches = match(pt.tagNumber, inputValue);
        const parts = parse(pt.tagNumber, matches, { insideWords: true });
        const hasMatch = parts.some((part) => part.highlight);

        if (!hasMatch) return null;

        return (
          <>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  textDecoration: part.highlight ? 'underline' : 'inherit',
                }}
              >
                {part.text}
              </span>
            ))}
          </>
        );
      })
      .filter(Boolean);
  }, [palletTags, inputValue]);

  if (!matchingTags?.length) return null;

  return (
    <Typography variant="subtitle2" color="text.secondary">
      Tags:{' '}
      {matchingTags.map((tagNumber, index) => {
        if (!tagNumber) return null;

        return (
          <>
            {tagNumber}
            {index < matchingTags.length - 1 && ', '}
          </>
        );
      })}
    </Typography>
  );
};
