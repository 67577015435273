type SalesOrderStatus =
  | 'DRAFT'
  | 'PENDING'
  | 'SUBMITTED'
  | 'APPROVED'
  | 'PICKING'
  | 'SHIPPED'
  | 'CLOSED'
  | 'CANCELLED'
  | 'ERROR';

type EditableFields =
  | 'orderDate'
  | 'salesPerson'
  | 'salesTerms'
  | 'shippingTerms'
  | 'customer'
  | 'customerPO'
  | 'billTo'
  | 'shipTo'
  | 'shipFrom'
  | 'shipDate'
  | 'shipment'
  | 'deliveryDate'
  | 'currency'
  | 'subsidiary'
  | 'expenses';

export type FieldEditability = {
  [field in EditableFields]?: boolean;
};

export class Validator {
  private static toSubmitted: SalesOrderStatus[] = ['DRAFT', 'SUBMITTED'];
  private static toPicking: SalesOrderStatus[] = ['DRAFT', 'PENDING', 'SUBMITTED', 'APPROVED', 'PICKING'];
  private static toShipped: SalesOrderStatus[] = ['DRAFT', 'PENDING', 'SUBMITTED', 'APPROVED', 'PICKING', 'SHIPPED'];
  private static toClosed: SalesOrderStatus[] = [
    'DRAFT',
    'PENDING',
    'SUBMITTED',
    'APPROVED',
    'PICKING',
    'SHIPPED',
    'CLOSED',
  ];
  private static whilePicking: SalesOrderStatus[] = ['PICKING'];

  public static active: SalesOrderStatus[] = ['SUBMITTED', 'PICKING', 'SHIPPED'];

  public static isRevertable(status: SalesOrderStatus, isCustomerPortalOrder?: boolean): boolean {
    if (isCustomerPortalOrder) return status !== 'PENDING';

    return status !== 'DRAFT' && status !== 'CANCELLED';
  }

  public static getFieldEditability(
    status: SalesOrderStatus,
    isEdiOrder?: boolean,
    isCustomerPortalOrder?: boolean
  ): FieldEditability {
    const toClosed = this.toClosed.includes(status);
    const toSubmitted = this.toSubmitted.includes(status);
    const toPicking = this.toPicking.includes(status);
    const toShipped = this.toShipped.includes(status);

    return {
      // Uptil submitted
      customer: isEdiOrder || isCustomerPortalOrder ? false : toSubmitted,

      // Uptil picking
      customerPO: isEdiOrder ? false : toPicking,
      shipFrom: toPicking,
      shipTo: isCustomerPortalOrder ? false : toPicking,

      // TODO: Implement shipment
      // TODO: Implement walking back of Order.

      // TODO: Integrate Qty. Available with "Ship From" warehouse

      // Uptil shipped
      shipDate: toShipped,
      shippingTerms: toShipped,
      shipment: toShipped,
      currency: toShipped,
      billTo: isCustomerPortalOrder ? false : toShipped,
      orderDate: toShipped,
      deliveryDate: toShipped,

      // Uptil closed
      expenses: toClosed,
      salesPerson: toClosed,
      salesTerms: toClosed,
      subsidiary: toClosed,
    };
  }

  public static getPickingOrderEditability(status: SalesOrderStatus): FieldEditability {
    const whilePicking = this.whilePicking.includes(status);
    const neverEditable = false;

    return {
      customer: neverEditable,
      customerPO: neverEditable,
      shipTo: neverEditable,
      currency: neverEditable,
      salesPerson: neverEditable,
      salesTerms: neverEditable,
      subsidiary: neverEditable,
      billTo: neverEditable,

      orderDate: whilePicking,
      shipFrom: whilePicking,
      shipDate: whilePicking,
      shippingTerms: whilePicking,
      shipment: whilePicking,
      deliveryDate: whilePicking,
      expenses: whilePicking,
    };
  }
}

// Implement required fields. Add color coding.

/**
 * On Line Items Grid:
 * 1. Make Lot an editable field
 * 2. Make Qty. Available should be visible on Picking status on the Order page.
 */
