import { PropsWithChildren } from 'react';

import { useIsCustomer, useIsMember } from 'src/auth';
import RouteLinks from 'src/components/containers/RouteLinks';
import CustomerRouteLinks from 'src/modules/customer-portal/CustomerRouteLinks';

import ContentLayout from './ContentLayout';

const empty = () => {};

export default function ContentLayoutPopulated({ children }: PropsWithChildren) {
  const isMember = useIsMember();
  const isCustomer = useIsCustomer();

  if (isMember) {
    return <ContentLayout listItems={RouteLinks}>{children}</ContentLayout>;
  }

  if (isCustomer) {
    return <ContentLayout listItems={CustomerRouteLinks}>{children}</ContentLayout>;
  }

  return <ContentLayout listItems={empty}>{children}</ContentLayout>;
}
