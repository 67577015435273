import { useMemo, useState } from 'react';

import { Add, Remove, ShoppingCart } from '@mui/icons-material';
import { Box, Button, Grid2, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import Money from 'shared/utils/Money';

import { navigate, routes } from '@redwoodjs/router';
import { Metadata } from '@redwoodjs/web';

import { PageBody } from 'src/components/containers/PageBody';
import { PageHeader, PageType } from 'src/components/containers/PageHeader';
import ClearableSearch from 'src/modules/grids/toolbar/ClearableSearch';

import useCustomerPortalApi from '../useCustomerPortalApi';
import useCustomerPortalProducts from '../useCustomerPortalProducts';

export default function CustomerPortalProductsPage() {
  const products = useCustomerPortalProducts(-1);
  const [cart, setCart] = useState<Record<number, number>>({});
  const { createPurchaseOrder } = useCustomerPortalApi();
  const [search, setSearch] = useState('');

  const changeQuantity = (productId: number, quantity: number) => {
    if (quantity === 0) {
      const newCart = { ...cart };
      delete newCart[productId];
      setCart(newCart);
    } else {
      setCart({ ...cart, [productId]: quantity });
    }
  };

  const createOrder = async () => {
    const slug = await createPurchaseOrder({
      lineItems: Object.entries(cart).map(([id, unitsOrdered]) => ({ productId: +id, unitsOrdered })),
    });
    navigate(routes.editCustomerPortalPurchaseOrder({ slug }));
  };

  const total = Object.values(cart).reduce((acc, quantity) => acc + quantity, 0);

  const filteredProducts = useMemo(
    () =>
      products.filter(
        (product) =>
          product.label.toLowerCase().includes(search.toLowerCase()) ||
          product.code.toLowerCase().includes(search.toLowerCase())
      ),
    [products, search]
  );

  return (
    <>
      <Metadata title="Products" />
      <PageHeader pageType={PageType.CustomerPortalProducts}>
        <Box sx={{ ml: 'auto', display: 'flex', my: 'auto', gap: 1 }}>
          <Button
            variant="contained"
            startIcon={<ShoppingCart />}
            disabled={Object.keys(cart).length === 0}
            onClick={createOrder}
          >
            Go to Purchase Order ({total})
          </Button>
        </Box>
      </PageHeader>
      <PageBody sx={{ flexDirection: 'column', gap: 2 }}>
        <ClearableSearch searchState={search} onSearch={setSearch} className="bg-white" />
        <Grid2 container spacing={2} sx={{ minWidth: '75%' }}>
          {filteredProducts.map((product) => (
            <Grid2 key={product.id} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Stack spacing={1} sx={{ height: '100%' }}>
                  <Typography sx={{ fontSize: '16px' }}>{product.label}</Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography sx={{ fontSize: '14px', color: 'grey.600' }}>{product.code}</Typography>
                  <Typography sx={{ fontSize: '17px', fontWeight: 700 }}>{Money.toHuman(product.price)}</Typography>
                  {cart[product.id] ? (
                    <QuantityControl
                      quantity={cart[product.id]}
                      confirm={(quantity) => changeQuantity(product.id, quantity)}
                    />
                  ) : (
                    <Button variant="contained" startIcon={<Add />} onClick={() => changeQuantity(product.id, 1)}>
                      Add to Order
                    </Button>
                  )}
                </Stack>
              </Paper>
            </Grid2>
          ))}
        </Grid2>
      </PageBody>
    </>
  );
}

function QuantityControl({ quantity, confirm }) {
  const [value, setValue] = useState(quantity + '');

  const onClickButton = (add = false) => {
    const v = parseInt(value) || 0;
    const newValue = v + (add ? 1 : -1);
    setValue(String(newValue));
    confirm(newValue);
  };

  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={() => onClickButton(false)}>
        <Remove />
      </IconButton>
      <TextField
        size="small"
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => confirm(parseInt(value) || 0)}
        slotProps={{
          htmlInput: {
            style: {
              textAlign: 'center',
              '-moz-appearance': 'textfield',
              '&::-webkit-inner-spin-button': { '-webkit-appearance': 'none' },
              '&::-webkit-outer-spin-button': { '-webkit-appearance': 'none' },
            },
          },
        }}
      />
      <IconButton onClick={() => onClickButton(true)}>
        <Add />
      </IconButton>
    </Stack>
  );
}
