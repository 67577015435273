import { createContext, PropsWithChildren, useContext, useEffect } from 'react';

import { useQuery } from '@redwoodjs/web';

import { useAuth } from 'src/auth';

export const OrgLocalizationContext = createContext<{
  salesOrderDocumentPrefix?: string;
}>(undefined);

const GET_ORG_LOCALIZATION = gql`
  query GetOrgLocalization {
    organization {
      salesOrderDocumentPrefix
    }
  }
`;

const localizationDefaults = {
  salesOrderDocumentPrefix: 'SO',
};

export default function OrgLocalizationProvider({ children }: PropsWithChildren) {
  const { currentUser } = useAuth();
  const { data, refetch, called } = useQuery(GET_ORG_LOCALIZATION, {
    skip: !currentUser,
  });

  // only fetch the query if we have a user and we haven't already fetched it
  useEffect(() => {
    if (!data && currentUser && !called) {
      refetch();
    }
  }, [data, called, refetch, currentUser]);

  return (
    <OrgLocalizationContext.Provider value={data?.organization ?? localizationDefaults}>
      {children}
    </OrgLocalizationContext.Provider>
  );
}

export const useOrgLocalization = () => useContext(OrgLocalizationContext) ?? {};
