import { useEffect } from 'react';
import { useMemo } from 'react';

import { useOrganization } from '@clerk/clerk-react';
import { Insights as InsightsIcon, ViewKanban as OrderBoardIcon } from '@mui/icons-material';
import PurchasesIcon from '@mui/icons-material/ContentPaste';
import GrowerProductIcon from '@mui/icons-material/Inventory';
import SalesIcon from '@mui/icons-material/Receipt';
import AccountingIcon from '@mui/icons-material/ReceiptLong';
import SystemIcon from '@mui/icons-material/Settings';
import InventoryIcon from '@mui/icons-material/ViewModule';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';

import { navigate, routes } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';

import { useAuth } from 'src/auth';
import ScannerIcon from 'src/components/atoms/ScannerIcon';
import { FeatureFlags } from 'src/lib/constants';
import { CREATE_GROWER_PRODUCT_MUTATION } from 'src/modules/orders/growerProducts/api/create';
import { CREATE_PURCHASE_ORDER_MUTATION } from 'src/modules/orders/purchaseOrders/api/creates';
import {
  CREATE_BUY_SELL_ORDER_MUTATION,
  CREATE_SALES_ORDER_MUTATION,
} from 'src/modules/orders/salesOrders/api/creates';

import OrganizationLogo from '../atoms/OrganizationLogo';

import SidebarNestedLinks from './sidebar/SidebarAccordian';
import SidebarLink from './sidebar/SidebarLink';

export type RouteKeys = keyof typeof routes;
export type AnyRoute = (typeof routes)[RouteKeys];

export default function RouteLinks() {
  const { organization } = useOrganization();

  const [createPurchaseOrder] = useMutation(CREATE_PURCHASE_ORDER_MUTATION);
  const [createSalesOrder] = useMutation(CREATE_SALES_ORDER_MUTATION);
  const [createBuySellOrder] = useMutation(CREATE_BUY_SELL_ORDER_MUTATION);
  const [createGrowerProduct] = useMutation(CREATE_GROWER_PRODUCT_MUTATION);

  const [canCreateNewPurchaseOrder, setCanCreateNewPurchaseOrder] = React.useState(false);
  const [canViewPurchaseOrders, setCanViewPurchaseOrders] = React.useState(false);
  const [canCreateNewSalesOrder, setCanCreateNewSalesOrder] = React.useState(false);
  const [canViewSalesOrders, setCanViewSalesOrders] = React.useState(false);
  const [canViewInventory, setCanViewInventory] = React.useState(false);
  const [canViewSettings, setCanViewSettings] = React.useState(false);

  const [isWorkOrdersEnabled, setWorkOrdersEnabled] = React.useState(false);

  const [businessIntelligenceDashboards, setBusinessIntelligenceDashboards] = React.useState(null);
  const [isBuySellOrdersEnabled, setBuySellOrdersEnabled] = React.useState(false);
  const [isProductionRunsEnabled, setProductionRunsEnabled] = React.useState(false);
  const [isCheckPrintingEnabled, setCheckPrintingEnabled] = React.useState(false);
  const [isOrderBoardEnabled, setOrderBoardEnabled] = React.useState(false);
  const [isAccountingEnabled, setAccountingEnabled] = React.useState(false);
  const [isAccountRegisterEnabled, setAccountRegisterEnabled] = React.useState(false);

  const ldClient = useLDClient();
  const flags = useFlags();

  const { currentUser } = useAuth();

  // TODO: Remove as part of this ticket, enable in LD - https://www.notion.so/agriful/Unable-to-target-specific-users-for-BI-Reports-1775a07ef9cb80b48935fcff8e20cab0
  const isParadigmFreshSalesTeam = [
    'user_2qptjxLvDLwUFzr4RyuosXE6QTu', // Rob Harris
    'user_2qptgbFDkqqpcYrEW8chr1BDlNf', // Craig Olson
    'user_2qptfc4KFF2JGYFZ4V63N8agNpK', // Jeremy Burton
  ].includes(currentUser?.id as string);

  // Set up feature flags
  useEffect(() => {
    // Set up BI Dashboards' feature flag
    const biDashboards = ldClient?.variation(FeatureFlags.BIDashboards, {}) ?? null;
    setBusinessIntelligenceDashboards(biDashboards);

    // Set up Grower Products' feature flag
    const workOrderFlagVariation = ldClient?.variation(FeatureFlags.WorkOrders, false) ?? false;
    setWorkOrdersEnabled(workOrderFlagVariation);

    // Set up Buy Sell Orders' feature flag
    const buySellOrderFlagVariation = ldClient?.variation(FeatureFlags.BuySellOrders, false) ?? false;
    setBuySellOrdersEnabled(buySellOrderFlagVariation);

    // Set up Production Runs' feature flag
    const productionRunsFlagVariation = ldClient?.variation(FeatureFlags.ProductionRuns, false) ?? false;
    setProductionRunsEnabled(productionRunsFlagVariation);

    const checkPrintingFlagVariation = ldClient?.variation(FeatureFlags.CheckPrinting, false) ?? false;
    setCheckPrintingEnabled(checkPrintingFlagVariation);

    const accountingFlagVariation = ldClient?.variation(FeatureFlags.Accounting, false) ?? false;
    setAccountingEnabled(accountingFlagVariation);

    const orderBoardVariation = ldClient?.variation(FeatureFlags.OrderBoard, false) ?? false;
    setOrderBoardEnabled(orderBoardVariation);

    const accountRegisterFlagVariation = ldClient?.variation(FeatureFlags.AccountRegister, false) ?? false;
    setAccountRegisterEnabled(accountRegisterFlagVariation);

    const canCreateNewPurchaseOrder = ldClient?.variation(FeatureFlags.CreatePOPermissions, false) ?? false;
    setCanCreateNewPurchaseOrder(canCreateNewPurchaseOrder);

    const canViewPurchaseOrders = ldClient?.variation(FeatureFlags.ViewPOPermissions, false) ?? false;
    setCanViewPurchaseOrders(canViewPurchaseOrders);

    const canCreateNewSalesOrder = ldClient?.variation(FeatureFlags.CreateSOPermissions, false) ?? false;
    setCanCreateNewSalesOrder(canCreateNewSalesOrder);

    const canViewSalesOrders = ldClient?.variation(FeatureFlags.ViewSOPermissions, false) ?? false;
    setCanViewSalesOrders(canViewSalesOrders);

    const canViewInventory = ldClient?.variation(FeatureFlags.ViewInventoryPermissions, false) ?? false;
    setCanViewInventory(canViewInventory);

    const canViewSettings = ldClient?.variation(FeatureFlags.ViewSettingsPermissions, false) ?? false;
    setCanViewSettings(canViewSettings);
  }, [flags, ldClient]);

  function handleCreateNewPurchaseOrder(newTab) {
    createPurchaseOrder({
      variables: {
        input: {
          status: 'DRAFT',
        },
      },
      onCompleted: ({ createPurchaseOrder }) => {
        const route = routes.purchaseOrder({ slug: createPurchaseOrder.slug });
        newTab ? window.open(route) : navigate(route);
      },
    });
  }

  function handleCreateNewSalesOrder(newTab) {
    createSalesOrder({
      variables: {
        input: {
          status: 'DRAFT',
        },
      },
      onCompleted: ({ createSalesOrder }) => {
        const route = routes.salesOrder({ slug: createSalesOrder.slug });
        newTab ? window.open(route) : navigate(route);
      },
    });
  }

  function handleCreateNewBuySellOrder(newTab) {
    createBuySellOrder({
      variables: { input: {} },
      onCompleted: ({ createBuySellOrder }) => {
        const route = routes.buySellOrder({ slug: createBuySellOrder.slug });
        newTab ? window.open(route) : navigate(route);
      },
    });
  }

  function handleCreateNewGrowerProduct(newTab) {
    createGrowerProduct({
      variables: { input: {} },
      onCompleted: ({ createWorkOrder }) => {
        const route = routes.growerProduct({ slug: createWorkOrder.slug });
        newTab ? window.open(route) : navigate(route);
      },
    });
  }

  return (
    <>
      <SidebarLink
        text={organization?.name ?? 'Organization'}
        icon={OrganizationLogo}
        to={routes.organizationSettings}
        testId="nav-organization-settings"
        disabled={!canViewSettings}
      />
      {isOrderBoardEnabled && (
        <SidebarLink text="Order Board" icon={OrderBoardIcon} to={routes.orderBoard} testId="nav-order-board" />
      )}

      {canViewSalesOrders && (
        <SidebarNestedLinks
          icon={SalesIcon}
          route="sales-orders"
          text="Sales Orders"
          testId="nav-sales-orders"
          links={[
            ...(canCreateNewSalesOrder
              ? [{ text: 'New Sales Order', action: handleCreateNewSalesOrder, testId: 'nav-new-sales-order' }]
              : []),

            ...(canCreateNewSalesOrder && isBuySellOrdersEnabled
              ? [
                  {
                    text: 'New Buy-Sell Order',
                    action: handleCreateNewBuySellOrder,
                    testId: 'nav-new-bye-sell-order',
                  },
                ]
              : []),

            { text: 'View Sales Orders', to: routes.salesOrders, testId: 'nav-view-sales-orders' },
          ]}
        />
      )}

      {canViewPurchaseOrders && (
        <SidebarNestedLinks
          icon={PurchasesIcon}
          route="purchase-orders"
          text="Purchase Orders"
          testId="nav-purchase-orders"
          links={[
            ...(canCreateNewPurchaseOrder
              ? [
                  {
                    text: 'New Purchase Order',
                    action: handleCreateNewPurchaseOrder,
                    testId: 'nav-new-purchase-order',
                  },
                ]
              : []),

            ...(canCreateNewPurchaseOrder && isBuySellOrdersEnabled
              ? [
                  {
                    text: 'New Buy-Sell Order',
                    action: handleCreateNewBuySellOrder,
                    testId: 'nav-new-bye-sell-order',
                  },
                ]
              : []),

            { text: 'View Purchase Orders', to: routes.purchaseOrders, testId: 'nav-view-purchase-orders' },
          ]}
        />
      )}

      {isWorkOrdersEnabled && (
        <SidebarNestedLinks
          icon={GrowerProductIcon}
          route="grower-products"
          text="Grower Products"
          testId="nav-grower-products"
          links={[
            { text: 'New Grower Product', action: handleCreateNewGrowerProduct, testId: 'nav-new-grower-product' },
            { text: 'View Grower Products', to: routes.growerProducts, testId: 'nav-view-grower-products' },
          ]}
        />
      )}

      {canViewInventory && (
        <SidebarNestedLinks
          icon={InventoryIcon}
          route="inventory"
          text="Inventory"
          testId="nav-inventory"
          links={[
            { text: 'Inventory Report', to: routes.inventory, testId: 'nav-inventory-report' },
            ...(flags.inventoryTransfers
              ? [{ text: 'Inventory Transfer', to: routes.inventoryTransfers, testId: 'nav-inventory-transfers' }]
              : []),
            { text: 'Pick Tickets', to: routes.viewPickTickets, testId: 'nav-pick-tickets' },
            { text: 'Receiving Tickets', to: routes.viewReceivingTickets, testId: 'nav-receiving-tickets' },
            ...(isProductionRunsEnabled
              ? [{ text: 'Production Runs', to: routes.productionRuns, testId: 'nav-production-runs' }]
              : []),
            ...(flags.freightTracking
              ? [{ text: 'Freight Tracking', to: routes.loads, testId: 'nav-freight-tracking' }]
              : []),
          ]}
        />
      )}

      <AccountingLinks />

      {flags.businessIntelligence && !isParadigmFreshSalesTeam && (
        <SidebarNestedLinks
          text="Business Intelligence"
          route="business-intelligence"
          icon={InsightsIcon}
          testId="nav-business-intelligence"
          links={
            !isNil(businessIntelligenceDashboards)
              ? businessIntelligenceDashboards.map((dashboard) => {
                  return {
                    text: dashboard.name,
                    to: routes.biDashboards({
                      slug: dashboard.slug,
                      dashboardId: dashboard.presetDashboardId,
                    }),
                    testId: `nav-${dashboard.slug}`,
                  };
                })
              : []
          }
        />
      )}

      {flags.scanPage && <SidebarLink text="Scanning" icon={ScannerIcon} to={routes.scan} testId="nav-scaning" />}

      {canViewSettings && (
        <SidebarLink
          text="System Management"
          icon={SystemIcon}
          to={routes.systemManagement}
          testId="nav-system-management"
        />
      )}
    </>
  );
}

const AccountingLinks = () => {
  const flags = useFlags();
  const isAccountingEnabled = flags.accounting;

  const links = useMemo(() => {
    const isAccountRegisterEnabled = flags.accountRegister;
    const isCheckPrintingEnabled = flags.checkPrinting;
    const isAccountingReportsEnabled = flags.accountingReports;

    const links = [
      { text: 'Payables', to: routes.payables, testId: 'nav-payables' },
      { text: 'Invoices', to: routes.invoices, testId: 'nav-invoices' },
    ];

    if (isAccountRegisterEnabled) {
      links.push({ text: 'Accounts', to: routes.chartOfAccounts, testId: 'nav-accounts' });
    }

    if (isCheckPrintingEnabled) {
      links.push(
        { text: 'Payments', to: routes.payments, testId: 'nav-payments' },
        { text: 'Print Checks', to: routes.printChecks, testId: 'nav-print-checks' }
      );
    }

    if (isAccountingReportsEnabled) {
      links.push(
        { text: 'Reports', to: routes.accountingReports, testId: 'nav-accounting-reports' },
        { text: 'Aging Reports', to: routes.agingReports, testId: 'nav-aging-reports' }
      );
    }

    return links;
  }, [flags]);

  if (!isAccountingEnabled) {
    return null;
  }

  return (
    <SidebarNestedLinks
      text="Accounting"
      route="accounting"
      icon={AccountingIcon}
      testId="nav-accounting"
      links={links}
    />
  );
};
