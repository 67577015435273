import { useCallback, useEffect, useMemo } from 'react';

import { Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import Money from 'shared/utils/Money';
import { z } from 'zod';

import AutoCompleteCell from 'src/components/atoms/grid/AutoCompleteCell';
import BoldHeader from 'src/components/atoms/grid/BoldHeader';
import UnderlinedMatch from 'src/components/atoms/UnderlinedMatch';
import AddableDataGrid from 'src/components/containers/grids/AddableDataGrid';
import { GridColObj } from 'src/components/containers/grids/DataGrid';
import useSystemUnitRounding from 'src/hooks/useSystemUnitRounding';
import columnActions from 'src/utils/grid/column/columnActions';

import useCustomerPortalApi from '../useCustomerPortalApi';
import { CustomerPortalProduct } from '../useCustomerPortalProducts';

import { CustomerPortalPurchaseOrderData } from './useCustomerPortalPurchaseOrder';

type Props = {
  purchaseOrder: CustomerPortalPurchaseOrderData;
  products: CustomerPortalProduct[];
};

export default function LineItemsGrid({ purchaseOrder, products }: Props) {
  const apiRef = useGridApiRef();
  const [rows, setRows] = React.useState([]);
  const unitRounding = useSystemUnitRounding();
  const { createLineItem, updateLineItem, deleteLineItem } = useCustomerPortalApi();

  useEffect(() => {
    setRows(purchaseOrder.lineItems.map((li) => ({ ...li, product: products.find((p) => p.id === li.productId) })));
  }, [products, purchaseOrder.lineItems]);

  const rowToInput = (row) => ({
    productId: row.product?.id,
    unitsOrdered: row.unitsOrdered,
  });

  const processRowSave = async (input) => {
    const { id } = await createLineItem(purchaseOrder.id, input);
    return { id };
  };

  const processRowChanges = (id, input) => {
    updateLineItem(id, input);
  };

  const handleDeleteLineItem = useCallback(
    (id) => {
      setRows((rows) => rows.filter((row) => row.id !== id));
      deleteLineItem(id);
    },
    [deleteLineItem]
  );

  const columns: GridColObj = useMemo(
    () => ({
      product: {
        headerName: 'Products',
        flex: 1,
        editable: true,
        renderHeader: BoldHeader,
        renderEditCell: (params) => (
          <AutoCompleteCell<CustomerPortalProduct>
            {...params}
            options={products}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { inputValue }) => (
              <Stack sx={{ width: '100%' }}>
                <UnderlinedMatch target={option.label} input={inputValue} />
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle2" color="grey.600">
                    {Money.toHuman(option.price)}
                  </Typography>
                  {option.code && (
                    <Typography variant="subtitle2" color="grey.600">
                      <UnderlinedMatch target={option.code} input={inputValue} />
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
          />
        ),
        sortComparator: (a, b) => b?.label.localeCompare(a?.label),
        valueFormatter: (value) => value?.label,
      },
      unitsOrdered: {
        headerName: 'Units Ordered',
        type: 'number',
        minWidth: 120,
        flex: 1,
        editable: true,
        valueSetter: (value, row) => ({
          ...row,
          unitsOrdered: unitRounding(value),
        }),
        renderHeader: BoldHeader,
      },
      price: {
        headerName: 'Price Per Unit',
        type: 'number',
        minWidth: 120,
        flex: 1,
        renderHeader: BoldHeader,
        valueGetter: (_, row) => (row.product ? Money.toHuman(row.product.price) : ''),
      },
      total: {
        headerName: 'Total Price',
        type: 'number',
        minWidth: 120,
        flex: 1,
        renderHeader: BoldHeader,
        valueGetter: (_, row) => {
          if (!row.product || !row.unitsOrdered) {
            return '';
          }
          return Money.toHuman(row.product.price * row.unitsOrdered);
        },
      },
      actions: {
        type: 'actions',
        width: 10,
        maxWidth: 10,
        align: 'left',
        getActions: columnActions(
          ({ id }) => ({
            deleteRow: () => handleDeleteLineItem(id),
          }),
          { showOnHover: true }
        ),
      },
    }),
    [unitRounding, products, handleDeleteLineItem]
  );

  if (!purchaseOrder) return null;

  return (
    <AddableDataGrid
      storageKey="customer-portal-purchase-order-line-items"
      getRowHeight={() => 'auto'}
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      fieldToFocus="product"
      setRows={setRows}
      newRowLabel="Add new product"
      canEdit={purchaseOrder.status === 'DRAFT'}
      createRow={() => ({
        product: null,
        unitsOrdered: 0,
      })}
      processCellChanges={processRowChanges}
      processCellSave={processRowSave}
      rowModel={() =>
        z.object({
          product: z
            .object(
              { id: z.number(), code: z.string().nullable(), label: z.string(), price: z.number() },
              { invalid_type_error: 'Must select a product' }
            )
            .refine((value) => !!value.id, 'Must select a product'),
          unitsOrdered: z
            .number({
              invalid_type_error: 'Must order at least 1 unit',
            })
            .min(1, 'Must order at least 1 unit'),
        })
      }
      rowToInput={rowToInput}
    />
  );
}
