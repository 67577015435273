import { Box, Divider, Grid2, Stack, Typography } from '@mui/material';
import { SalesTermsMapper } from 'shared/mappers';
import { asUTCDate } from 'shared/utils/DateTime';
import Money from 'shared/utils/Money';

import Section from 'src/components/atoms/Section';

import { CustomerPortalInvoice } from './useCustomerPortalInvoice';

type Props = {
  invoice: CustomerPortalInvoice;
};

export default function InvoiceDetails({ invoice }: Props) {
  return (
    <Section title="Invoice Details">
      <Grid2 container spacing={2}>
        <Grid2 container spacing={2} size={{ sm: 12, md: 8 }}>
          <Field label="Bill To" displayValue={invoice.customerName} />
          <Field label="Payment Terms" displayValue={SalesTermsMapper.getLabel(invoice.salesTerms)} />
          <Field label="Due Date" displayValue={asUTCDate(invoice.paymentDueDate)?.toFormat('MM/dd/yyyy')} />
          <Field label="Issue Date" displayValue={asUTCDate(invoice.invoiceDate)?.toFormat('MM/dd/yyyy')} />
        </Grid2>
        <Grid2
          size={{ sm: 12, md: 4 }}
          sx={{ bgcolor: 'grey.50', p: 2, display: 'flex', flexDirection: 'column', gap: 1, borderRadius: '5px' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="overline" sx={{ color: 'grey.600' }}>
              Total Due
            </Typography>
            <Typography sx={{ fontSize: '20px' }}>{Money.toHuman(invoice.totalAmountDue)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="overline" sx={{ color: 'grey.600' }}>
              Paid
            </Typography>
            <Typography sx={{ fontSize: '20px', color: 'grey.600' }}>{Money.toHuman(invoice.totalPaid)}</Typography>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="overline" sx={{ color: 'grey.600' }}>
              Remaining
            </Typography>
            <Typography sx={{ fontSize: '20px' }}>{Money.toHuman(invoice.amountRemaining)}</Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Section>
  );
}

const Field = ({ label, displayValue }) => (
  <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
    <Stack>
      <Typography variant="overline" sx={{ color: 'grey.600' }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: '17px' }}>{displayValue}</Typography>
    </Stack>
  </Grid2>
);
