import { useStoreStateLensFocus } from 'src/providers/StoreStateLensFocusProvider';
import { useStoreState } from 'src/providers/StoreStateProvider';

export type UseStoreState<T> = {
  <U extends keyof T>(selector: (state: T) => T[U]): T[U];
  <ReturnType>(selector: (state: T) => ReturnType): ReturnType;
};

export function createUseStoreState<State>(lens: string) {
  return <Selected>(selector: (state: State) => Selected): Selected => {
    const lensSelector = useStoreStateLensFocus(lens);

    return useStoreState((state: Record<string, State>) => {
      return selector(lensSelector(state)) as Selected;
    });
  };
}
