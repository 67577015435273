import { Typography } from '@mui/material';
import { GRID_AGGREGATION_FUNCTIONS } from '@mui/x-data-grid-premium';
import { memoReasons } from 'shared/constants/ReceiptMemos';
import Money from 'shared/utils/Money';

import BoldHeader from 'src/components/atoms/grid/BoldHeader';
import Section from 'src/components/atoms/Section';
import DataGrid, { GridColObj } from 'src/components/containers/grids/DataGrid';
import { totalsLabelAggregation } from 'src/utils/grid/column/columnAggregation';

import { CustomerPortalInvoice } from './useCustomerPortalInvoice';

type Props = {
  invoice: CustomerPortalInvoice;
};

const creditColumns: GridColObj = {
  type: {
    headerName: 'Credit Type',
    flex: 1,
    renderHeader: BoldHeader,
    renderCell: ({ value, rowNode }) => {
      if (rowNode.isAutoGenerated) return <Typography variant="overline">{value}</Typography>;
      return value;
    },
  },
  amount: {
    headerName: 'Amount',
    type: 'number',
    flex: 1,
    renderHeader: BoldHeader,
    valueGetter: (value) => Money.toHuman(value),
  },
  reason: {
    headerName: 'Reason',
    flex: 1,
    valueGetter: (value) => memoReasons.find((reason) => reason.code === value)?.label,
    renderHeader: BoldHeader,
  },
  memo: {
    headerName: 'Memo',
    flex: 1,
    renderHeader: BoldHeader,
  },
};

export default function InvoiceCredits({ invoice }: Props) {
  return (
    <Section title="Credits" sx={{ mt: 3 }}>
      <DataGrid
        rows={invoice.receiptAdjustments}
        columns={creditColumns}
        storageKey="invoice-credits"
        variant="list"
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          totals: totalsLabelAggregation,
        }}
        disableAggregation={invoice.receiptAdjustments.length < 2}
        initialState={{
          aggregation: {
            model: {
              type: 'totals',
              amount: 'sum',
            },
          },
        }}
      />
    </Section>
  );
}
