import { FC, ReactNode, useEffect } from 'react';

import CalendarIcon from '@mui/icons-material/CalendarToday';
import FilterIcon from '@mui/icons-material/FilterList';
import ColumnIcon from '@mui/icons-material/ViewColumn';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box, Button, ClickAwayListener, IconButton, Menu, MenuItem, MenuProps } from '@mui/material';
import {
  GridColumnsPanel,
  GridFilterPanel,
  GridToolbarContainer,
  GridToolbarContainerProps,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';

import LuxonStaticDateRangePicker from 'src/components/atoms/buttons/LuxonStaticDateRangePicker';
import { ChipSelect } from 'src/components/atoms/ChipSelect';
import CountIcon from 'src/components/atoms/CountIcon';
import Hint from 'src/components/atoms/Hint';
import SearchBox from 'src/components/atoms/SearchBox';
import useAnchorEl from 'src/hooks/useAnchorEl';
import { usePersistentSearch } from 'src/providers/SearchBoxProvider';
import { CompareArrows } from '@mui/icons-material';

export type ToolbarProps = {
  searchbarLabel?: string;
  useSearchbar?: boolean;
  children?: ReactNode;
  Buttons?: FC;
  BeforeButtons?: FC;
  handleSearch?: (hits) => void;
  searchBoxSlot?: ReactNode;
  onFilterClickedAway?: () => void;
  dateOptions?: {
    label: string;
    value: string;
  }[];
} & GridToolbarContainerProps;

type MenuDropDownProps = {
  icon: ReactNode;
  children: ReactNode;
  hint?: string;
  label?: string;
} & Omit<MenuProps, 'open'>;

export function MenuDropDown({ icon, children, hint, label, ...menuProps }: MenuDropDownProps) {
  const { anchorEl, open, handleClick, handleClose } = useAnchorEl();

  return (
    <>
      <Hint title={hint}>
        <Button
          onClick={handleClick}
          variant="text"
          color="primary"
          sx={{ my: 'auto', minWidth: '40px', height: '40px' }}
          startIcon={icon}
        >
          {label && <span className="invisible sm:visible">{label}</span>}
        </Button>
      </Hint>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} {...menuProps}>
        {children}
      </Menu>
    </>
  );
}

const defaultDateOptions = [
  {
    label: 'Updated Date',
    value: 'updatedAtTimestamp',
  },
  {
    label: 'Created Date',
    value: 'createdAtTimestamp',
  },
];

const toDateFilter = (dateType: string, start: DateTime, end: DateTime) => {
  if (!start) {
    return '';
  } else if (end) {
    return `${dateType}:${start.toMillis()} TO ${end.toMillis()}`;
  } else {
    return `${dateType}>${start.toMillis()}`;
  }
};

export type DateFilter = {
  start: DateTime | null;
  end: DateTime | null;
  type: string;
  filter: string;
};

const DateFilter = ({ dateOptions }) => {
  const { filters, setFilters } = usePersistentSearch();
  const dateFilter = filters?.date as DateFilter;

  useEffect(() => {
    if (dateFilter?.type === undefined) {
      setFilters({
        filters,
        date: {
          ...(dateFilter || {
            start: null,
            end: null,
          }),
          filter: '',
          type: dateOptions[0].value,
        },
      });
    }
  });

  return (
    <MenuDropDown icon={<CalendarIcon />} hint="Select date range">
      <Box px={2}>
        <ChipSelect fullWidth value={dateFilter?.type || dateOptions[0].value}>
          {dateOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() =>
                setFilters({
                  filters,
                  date: {
                    ...(dateFilter || {
                      start: null,
                      end: null,
                    }),
                    type: option.value,
                  },
                })
              }
            >
              {option.label}
            </MenuItem>
          ))}
        </ChipSelect>
        <LuxonStaticDateRangePicker
          onChange={([start, end]) => {
            setFilters({
              filters,
              date: {
                ...dateFilter,
                start,
                end,
              },
            });
          }}
          value={[dateFilter?.start || null, dateFilter?.end || null]}
          calendars={1}
          renderInput={() => null} // required field, but not needed.
        />
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            sx={{
              width: '50%',
            }}
            onClick={() => {
              setFilters({
                ...filters,
                date: {
                  type: dateFilter?.type,
                },
              });
            }}
          >
            Reset
          </Button>
          <Button
            sx={{
              width: '50%',
            }}
            onClick={() => {
              setFilters({
                ...filters,
                date: {
                  ...dateFilter,
                  filter: toDateFilter(dateFilter?.type, dateFilter?.start, dateFilter?.end),
                },
              });
            }}
            variant="contained"
          >
            Save Date Filter
          </Button>
        </Box>
      </Box>
    </MenuDropDown>
  );
};

export default function Toolbar({
  searchbarLabel,
  useSearchbar, // prop based feature flag, remove when all pages have search
  children,
  Buttons,
  BeforeButtons,
  searchBoxSlot = <Box sx={{ flexGrow: 1 }} />,
  dateOptions,
  onFilterClickedAway = () => {},
  sx = {},
  ...props
}: ToolbarProps) {
  const flags = useFlags();
  const apiRef = useGridApiContext();
  const filters = apiRef.current.state?.filter?.filterModel?.items?.length;

  return (
    <GridToolbarContainer
      {...props}
      sx={{
        gap: 2,
        display: 'flex',
        pb: useSearchbar ? 2.5 : 1.5,
        ...sx,
        '@media print': {
          display: 'none',
        },
        px: 0,
      }}
    >
      {useSearchbar ? <SearchBox label={searchbarLabel} size="small" /> : searchBoxSlot}
      <Box sx={{ display: 'flex', gap: 2.5, height: '40px' }}>
        {BeforeButtons && <BeforeButtons />}

        <MenuDropDown icon={<CountIcon count={filters} Icon={FilterIcon} />} hint="Filter rows">
          <ClickAwayListener onClickAway={onFilterClickedAway}>
            <GridFilterPanel
              filterFormProps={{
                valueInputProps: {
                  onKeyDown: (e) => {
                    e.stopPropagation();
                  },
                },
              }}
            />
          </ClickAwayListener>
        </MenuDropDown>
        {flags.orderFlagging && (
          <MenuDropDown icon={<WarningIcon />} hint="Show flagged orders">
            warning
          </MenuDropDown>
        )}
        {flags.orderDateFilters && useSearchbar && <DateFilter dateOptions={dateOptions ?? defaultDateOptions} />}
        <MenuDropDown icon={<ColumnIcon />} hint="Show/Hide columns">
          <GridColumnsPanel
            sx={{
              '& .MuiDataGrid-columnsPanelRow:has(>.Mui-disabled)': {
                display: 'none',
              },
            }}
          />
        </MenuDropDown>
        {Buttons && <Buttons />}
      </Box>
      {children}
    </GridToolbarContainer>
  );
}

export function makeToolbar({ children, ...props }: ToolbarProps) {
  return () => <Toolbar {...props}>{children}</Toolbar>;
}
