import React, { useEffect } from 'react';

import { ClerkProvider, useUser } from '@clerk/clerk-react';
import Cookies from 'js-cookie';
import { ClerkRole } from 'shared/constants';

import { createAuth } from '@redwoodjs/auth-clerk-web';
import { navigate } from '@redwoodjs/router';

import { CUBE_TOKEN_COOKIE, CUBE_TOKEN_EXPIRY_COOKIE } from './providers/CubeTokenProvider';

export const { AuthProvider: ClerkRwAuthProvider, useAuth } = createAuth();

interface Props {
  children: React.ReactNode;
}

const cookies = [CUBE_TOKEN_COOKIE, CUBE_TOKEN_EXPIRY_COOKIE];

const ClerkStatusUpdater = () => {
  const { isSignedIn, isLoaded } = useUser();
  const { reauthenticate } = useAuth();

  useEffect(() => {
    if (isLoaded) {
      reauthenticate();
    }

    if (!isSignedIn) {
      cookies.forEach((cookie) => Cookies.remove(cookie, { path: '' }));
    }
  }, [isSignedIn, isLoaded, reauthenticate]);

  return null;
};

type ClerkOptions = { publishableKey: string; frontendApi?: never } | { publishableKey?: never; frontendApi: string };

const ClerkProviderWrapper = ({ children, clerkOptions }: Props & { clerkOptions: ClerkOptions }) => {
  const { reauthenticate } = useAuth();

  return (
    <ClerkProvider {...clerkOptions} navigate={(to) => reauthenticate().then(() => navigate(to))}>
      {children}
      <ClerkStatusUpdater />
    </ClerkProvider>
  );
};

export const AuthProvider = ({ children }: Props) => {
  const publishableKey = process.env.CLERK_PUBLISHABLE_KEY;
  const frontendApi = process.env.CLERK_FRONTEND_API_URL || process.env.CLERK_FRONTEND_API;

  type ClerkOptions = { publishableKey: string; frontendApi?: never } | { publishableKey?: never; frontendApi: string };

  const clerkOptions: ClerkOptions = publishableKey ? { publishableKey } : { frontendApi };

  return (
    <ClerkRwAuthProvider>
      <ClerkProviderWrapper clerkOptions={clerkOptions}>
        {children}
        <ClerkStatusUpdater />
      </ClerkProviderWrapper>
    </ClerkRwAuthProvider>
  );
};

export function useIsMember() {
  const { currentUser } = useAuth();

  return currentUser?.roles.includes(ClerkRole.Member) || currentUser?.roles.includes(ClerkRole.Admin);
}

export function useIsCustomer() {
  const { currentUser } = useAuth();
  return currentUser?.roles.includes(ClerkRole.Customer);
}

export function useIsGrower() {
  const { currentUser } = useAuth();
  return currentUser?.roles.includes(ClerkRole.Grower);
}

export function useIsOrgAdmin() {
  const { currentUser } = useAuth();
  return currentUser?.roles.includes(ClerkRole.Admin);
}
