// In this file, all Page components from 'src/pages` are auto-imported. Nested

import { useEffect } from 'react';

import { useOrganization } from '@clerk/clerk-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { PrivateSet, Route, Router, Set } from '@redwoodjs/router';

import { useIdentifyClarityUser } from 'src/lib/clarity';
// import { useIdentifyFaroUser } from 'src/lib/grafana';

import { useAuth, useIsCustomer, useIsMember } from './auth';
import ContentLayoutPopulated from './layouts/ContentLayoutPopulated';
import ViewCustomerPortalInvoicePage from './modules/customer-portal/invoice/ViewCustomerPortalInvoicePage';
import CustomerPortalInvoicesPage from './modules/customer-portal/invoices/CustomerPortalInvoicesPage';
import CustomerPortalProductsPage from './modules/customer-portal/products/CustomerPortalProductsPage';
import EditCustomerPortalPurchaseOrderPage from './modules/customer-portal/purchase-order/EditCustomerPortalPurchaseOrderPage';
import CustomerPortalPurchaseOrdersPage from './modules/customer-portal/purchase-orders/CustomerPortalPurchaseOrdersPage';
import DocumentEditorPage from './modules/document-editor/pages/DocumentEditorPage/DocumentEditorPage';
import OrgLocalizationProvider from './providers/OrgLocalizationProvider';

export default function Routes() {
  const { currentUser } = useAuth();
  const { organization } = useOrganization();
  const ldClient = useLDClient();
  const isMember = useIsMember();
  const isCustomer = useIsCustomer();

  // TODO: Remove when Roles and Permissions are implemented!
  const isDawsonsWarehouseUser = currentUser?.id === 'user_2qpu9ix5xpDXvltlxgmMBmrqs3h';

  useIdentifyClarityUser();
  // useIdentifyFaroUser();

  useEffect(() => {
    // Identify currently logged in user for LaunchDarkly
    if (currentUser && organization) {
      const context = {
        kind: 'multi',
        user: {
          key: currentUser.id as string,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          // TODO: remove fallback after redis cache is updated
          email: currentUser.emailAddress ?? currentUser.emailAddresses?.[0]?.emailAddress,

          // TODO: Add more user context
        },
        organization: {
          key: organization.id as string,
          name: organization.name as string,

          // TODO: Add more organization context
        },
      };

      ldClient?.identify(context, null, (err) => {
        if (err) {
          console.error(`[Launch Darkly] Unable to set up context. Error: ${err}`);
          return;
        }
        console.log('[Launch Darkly] Set up new user context for user', `${currentUser.firstName} ${currentUser.lastName}`, 'and organization', organization.name, 'successfully.');
      });
    }
  }, [ldClient, currentUser, organization]);

  return (
    <Router useAuth={useAuth}>
      <Route path="/login" page={LoginPage} name="login" />
      <PrivateSet unauthenticated="login">
        {/* Global Routes */}
        {currentUser && <Route notfound page={NotFoundPage} />}
        <Set wrap={ContentLayoutPopulated}>
          <Route path="/" page={LandingPage} name="landing" />
          <Route path="/not-a-member" page={NotAMemberPage} name="notAMember" />
          <Route path="/accept-invite" page={AcceptInvitePage} name="acceptInvite" />
        </Set>

        {/* Customer Portal */}
        {isCustomer && (
          <Set wrap={ContentLayoutPopulated}>
            <Route path="/products" page={CustomerPortalProductsPage} name="customerPortalProducts" />
            <Route path="/purchase-orders" page={CustomerPortalPurchaseOrdersPage} name="customerPortalPurchaseOrders" />
            <Route path="/purchase-orders/{slug:String}" page={EditCustomerPortalPurchaseOrderPage} name="editCustomerPortalPurchaseOrder" />
            <Route path="/invoices" page={CustomerPortalInvoicesPage} name="customerPortalInvoices" />
            <Route path="/invoices/{slug:String}" page={ViewCustomerPortalInvoicePage} name="viewCustomerPortalInvoice" />
            <Route path="/settings" page={() => null} name="customerPortalSettings" />
          </Set>
        )}

        {/*  Main App */}
        {isMember && (
          <Set wrap={[ContentLayoutPopulated, OrgLocalizationProvider]}>
            <Route path="/search-results" page={SearchResultsPage} name="searchResults" />
            <Route path="/sales-orders" page={SalesViewSalesOrdersPage} name="salesOrders" />
            <Route path="/sales-orders/{slug:String}" page={SalesViewSalesOrderPage} name="salesOrder" />
            <Route path="/purchase-orders" page={PurchaseViewPurchaseOrdersPage} name="purchaseOrders" />
            <Route path="/purchase-orders/{slug:String}" page={PurchaseViewPurchaseOrderPage} name="purchaseOrder" />

            {/* Uncomment this for debugging PDF */}
            {/* <Route path="/pdf-test/{slug:String}" page={PDFTestPage} name="pdfTestPage" /> */}

            <Route path="/buy-sell-orders/{slug:String}" page={BuySellOrderPage} name="buySellOrder" />
            <Route path="/grower-products" page={GrowerProductsPage} name="growerProducts" />
            <Route path="/grower-products/{slug:String}" page={WorkOrderPage} name="growerProduct" />

            {!isDawsonsWarehouseUser && (
              <Set>
                <Route path="/accounting/reports" page={AccountingPage} name="accountingReports" />
                <Route path="/accounting/accounts" page={ChartOfAccountsPage} name="chartOfAccounts" />
                <Route path="/accounting/accounts/register" page={AccountRegisterPage} name="accountRegister" />
                <Route path="/accounting/invoices" page={ViewInvoicesPage} name="invoices" />
                <Route path="/accounting/invoices/{slug:String}" page={ViewInvoicePage} name="invoice" />
                <Route path="/accounting/payables" page={PayableViewPayablesPage} name="payables" />
                <Route path="/accounting/payables/{slug:String}" page={PayableViewPayablePage} name="payable" />
                <Route path="/accounting/payments" page={PaymentsPage} name="payments" />
                <Route path="/accounting/payments/print-checks" page={PrintChecksPage} name="printChecks" />
                <Route path="/accounting/payments/deposits" page={DepositSlipsPage} name="depositSlips" />
                <Route path="/accounting/payments/{slug:String}" page={PaymentPage} name="payment" />
                <Route path="/accounting/reconciliations" page={AccountReconciliationsPage} name="accountReconciliations" />
                <Route path="/accounting/reconciliations/{id:Int}" page={AccountReconciliationPage} name="accountReconciliation" />
                <Route path="/accounting/aging-reports" page={AgingReportsPage} name="agingReports" />
              </Set>
            )}

            <Route path="/inventory" page={InventoryPage} name="inventory" />
            <Route path="/inventory/production-runs" page={ProductionRunsProductionRunsPage} name="productionRuns" />
            <Route path="/inventory/production-runs/{slug:String}" page={ProductionRunsProductionRunPage} name="productionRun" />
            <Route path="/inventory/pick-tickets" page={PickingViewPickTicketsPage} name="viewPickTickets" />
            <Route path="/inventory/pick-tickets/{slug:String}" page={PickingEditPickTicketPage} name="editPickTicket" />
            <Route path="/inventory/receiving-tickets" page={ReceivingViewReceivingTicketsPage} name="viewReceivingTickets" />
            <Route path="/inventory/receiving-tickets/{slug:String}" page={ReceivingEditReceivingTicketPage} name="editReceivingTicket" />
            <Route path="/inventory/lot-tracking/{warehouseId:Int}/{slug:String}" page={ViewLotTrackingPage} name="viewLotTracking" />
            <Route path="/inventory/transfers/{slug:String}" page={InventoryTransferPage} name="inventoryTransfer" />
            <Route path="/inventory/transfers" page={InventoryTransfersPage} name="inventoryTransfers" />
            <Route path="/system-management" page={SystemManagementPage} name="systemManagement" />
            <Route path="/system-management/documents/editor" page={DocumentEditorPage} name="documentEditor" />
            <Route path="/system-management/products/{id:Int}" page={ProductPage} name="products" />
            <Route path="/system-management/business-entities/{id:Int}" page={BusinessEntityBusinessEntityPage} name="businessEntity" />
            <Route path="/business-entities/new" page={BusinessEntityNewBusinessEntityPage} name="newBusinessEntity" />
            <Route path="/organization-settings" page={OrganizationSettingsPage} name="organizationSettings" />
            <Route path="/user-profile" page={UserProfilePage} name="userProfile" />
            <Route path="/business-intelligence" page={BusinessIntelligencePage} name="businessIntelligence" />

            <Route path="/business-intelligence/{slug:String}" page={BIDashboardsPage} name="biDashboards" />

            <Route path="/inventory/loads" page={LoadsPage} name="loads" />
            <Route path="/inventory/loads/{slug:String}" page={LoadPage} name="load" />
            <Route path="/order-board" page={OrderBoardPage} name="orderBoard" />
            <Route path="/system-management/pallet-tags" page={PalletTagsPage} name="palletTags" />
            <Route path="/system-management/pricing-sheet/{id:Int}" page={PricingSheetPage} name="pricingSheet" />
            <Route path="/scan" page={ScanPage} name="scan" />
            <Route path="/scan/location" page={ScanLocationPage} name="scanLocation" />
          </Set>
        )}
      </PrivateSet>
      <Route path="/scan-debug" page={ScanDebugPage} name="scanDebug" />
    </Router>
  );
}
