import { Getter, Setter } from '@dhmk/zustand-lens';
import { createContext, useContext, useRef } from 'react';

import { StateCreator, StoreMutatorIdentifier, createStore, useStore } from 'zustand';

export type StateCreatorWithImmer<T> = StateCreator<T, [], [['zustand/immer', never]]>;

const StoreStateContext = createContext(null);

export function StoreStateProvider<T, Mos extends [StoreMutatorIdentifier, unknown][] = []>({
  children,
  initializeStore,
}: {
  children: React.ReactNode;
  initializeStore: StateCreator<T, [], Mos>;
}) {
  const storeRef = useRef(createStore(initializeStore));

  return <StoreStateContext.Provider value={storeRef.current}>{children}</StoreStateContext.Provider>;
}

export function useStoreState<T, Selector extends (state: T) => unknown>(selector: Selector): ReturnType<Selector> {
  const store = useContext(StoreStateContext);

  if (!store) {
    throw new Error('Missing StoreStateProvider');
  }

  return useStore(store, selector) as ReturnType<Selector>;
}

export type StoreStateLens<T, InitialState = undefined> = InitialState extends undefined
  ? (set: Setter<T>, get: Getter<T>) => T
  : (set: Setter<T>, get: Getter<T>, initialState: InitialState) => T;
