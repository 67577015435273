import { Home, ReceiptLong, Settings } from '@mui/icons-material';
import { Box } from '@mui/material';

import MaterialSymbol from 'src/components/atoms/MaterialSymbol';
import SidebarLink from 'src/components/containers/sidebar/SidebarLink';

export default function CustomerRouteLinks() {
  return (
    <>
      <SidebarLink text="Home" icon={Home} to="/" />
      <SidebarLink
        text="Products"
        icon={({ sx }) => (
          <Box sx={sx}>
            <MaterialSymbol style={{ color: 'inherit', fontSize: '18px' }} filled name="nutrition" />
          </Box>
        )}
        to="/products"
      />
      <SidebarLink
        text="Purchase Orders"
        icon={({ sx }) => (
          <Box sx={sx}>
            <MaterialSymbol style={{ color: 'inherit', fontSize: '18px' }} filled name="orders" />
          </Box>
        )}
        to="/purchase-orders"
      />
      <SidebarLink text="Invoices" icon={ReceiptLong} to="/invoices" />
      {/* <SidebarLink text="Settings" icon={Settings} to="/settings" /> */}
    </>
  );
}
