import { useCallback } from 'react';

import { Clear } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';

import { useSideDrawerContent } from 'src/components/containers/SideDrawer';

import { ServerEnhancedGridStateWithHelpers } from '../serverEnhanced/useServerEnhancedGridState';

type Props = {
  apiRef: ReturnType<typeof useGridApiRef>;
  enhancedGridState: ServerEnhancedGridStateWithHelpers;
  entityName: string;
  entityNamePlural?: string;
  bulkActions: React.ReactNode;
  renderSelectionAdornment?: (selectedRows) => React.ReactNode;
};

export default function BulkActionToolbar({
  apiRef,
  enhancedGridState,
  entityName,
  bulkActions,
  renderSelectionAdornment,
  entityNamePlural: _entityNamePlural,
}: Props) {
  const sideDrawerOpen = !!useSideDrawerContent();
  const numSelected = Object.values(enhancedGridState.selectedRows).length;

  const entityNamePlural = _entityNamePlural ?? `${entityName}s`;

  const clearSelection = useCallback(() => {
    apiRef.current.setRowSelectionModel([]);
  }, [apiRef]);

  return (
    <Box
      className="flex items-center bg-white shadow-xl rounded-lg mx-auto px-5 gap-4 transition-transform"
      sx={{
        position: 'fixed',
        top: '48px',
        left: 0,
        right: 0,
        maxWidth: '85vw',
        minHeight: '62px',
        zIndex: sideDrawerOpen ? 1200 : 1201,
        transitionDuration: '300ms',
        transform: sideDrawerOpen ? 'translateY(36px)' : 'translateY(0)',
      }}
    >
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body1">
          {numSelected} {numSelected === 1 ? entityName : entityNamePlural} selected
        </Typography>
        {renderSelectionAdornment?.(enhancedGridState.selectedRows)}
      </Box>
      <Button color="error" startIcon={<Clear />} onClick={clearSelection}>
        Deselect All
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      {bulkActions}
    </Box>
  );
}
